@import '../../../../assets/scss/vars';
.book-service-form {
    background-color: $White;
    padding: 20px;
    margin-top: 60px;
    border-radius: 12px;
    h1 {
        color: $Dark;
        text-align: center;
        line-height: 34px;
        margin-bottom: 10px
    }
    > div {
        margin-bottom: 10px
    }
    input[id$=-input] {
        box-shadow: none !important;
    }
    [class$=control] {
        border: 0 !important;
        background-color: transparent !important;
        border-bottom: 1px solid $BorderColor !important;
        border-radius: 0 !important;
        &[aria-disabled="true"] {
            cursor: not-allowed !important;
            background-color: $DarkWhite !important;
        }
    }
}
@import '../../../../assets/scss/vars';
.bottom-nav {
    display: flex;
    margin: auto auto auto 0;
    @media (max-width: 991px) {
        display: none
    }
    li {
        line-height: 1;
        padding: 0;
        a {
            color: $NavTextColor;
            font-size: 12px;
            &:hover {
                color: $Primary
            }
        }
        &:last-child {
            border-left: 1px solid $BorderColor;
            padding-left: 10px;
            margin-left: 10px;
        }
    }
}
.copyright-area {
    color: $NavTextColor;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    .copyright-area-inner {
        padding: 20px 0;
        text-align: center;
        font-size: 12px;
        background-color: $FooterColor
    }
}
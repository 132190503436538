@import '../../../../assets/scss/vars';
.checkout-page {
    .coupon {
        display: flex;
        input {
            border: none;
            padding: 10px 0
        }
    }
    .left-checkout,
    .right-checkout {
        flex: 1;
        width: 50%;
        padding: 0 20px;
        position: sticky;
        top: 140px;
        margin-bottom: auto;
        h2 {
            margin-bottom: 10px
        }
    }
    input,
    select {
        height: 40px;
        padding: 10px;
        border: 1px solid $BorderColor;
        border-radius: 4px;
        width: 100%
    }
    .billing-name {
        .form-group {
            flex: 1;
            margin-left: 10px;
            &:first-child {
                margin: 0
            }
        }
    }
    .form-group {
        margin-bottom: 30px;
        position: relative
    }
    label {
        display: block;
        margin-bottom: 3px
    }
    .checkout-review-order-table {
        .product-name {
            width: 45%;
            overflow-wrap: anywhere;
            word-break: break-word;
            -ms-word-break: break-all;
            padding: 15px
        }
    }
    .shop-table {
        width: 100%
    }
    @media (max-width: 991px) {
        padding: 40px 20px;
        .billing-name {
            .form-group {
                margin-left: 0;
                margin-top: 20px
            }
        }
        .left-checkout, .right-checkout {
            padding: 0;
            position: static;
            width: 100%
        }
    }
}
@-webkit-keyframes pauseanimate {
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}
@keyframes pauseanimate {
    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}
@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}
@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}
@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1)
    }
}
@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}
@-webkit-keyframes round {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@keyframes round {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@-webkit-keyframes round-reverse {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}
@keyframes round-reverse {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}
@-webkit-keyframes round {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@keyframes round {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@-webkit-keyframes round-reverse {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}
@keyframes round-reverse {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}
.subscription-bar {
    .logo {
        max-width: 120px
    }
    .subscription-form.form-inline {
        justify-content: center;
        max-width: 520px;
        margin: auto;
        .input {
            margin: 0;
            max-width: 100%;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important
        }
        button {
            width: 330px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0
        }
    }
}

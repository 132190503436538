@import '../../../assets/scss/vars';
.service-banner {
    box-shadow: inset 50vw 0 50px rgba(0,0,0,0.5);
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    background-size: cover;
    background-position: center;
    display: flex;
    .jumbo-container {
        min-height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 60px 0;
        h1 {
            font-size: 64px;
            color: $White;
            max-width: 600px;
            line-height: 1.2;
            @media (max-width: 768px) {
                font-size: 24px
            }
        }
        .hrsTaken {
            color: $White;
            font-size: 30px;
            @media (max-width: 768px) {
                font-size: 16px
            }
        }
    }
}
.servicedetail-page {
    .gallery-items {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
    .summary-card {
        display: flex;
        overflow: hidden;
        box-shadow: 0 0 3px $ShadowColor;
        border-radius: 20px;
        .summary-content {
            padding: 40px;
            .points ul li {
                padding-right: 0;
                text-align: left;
                @media (max-width: 991px) {
                    width: 100%;
                }
            }
        }
        .summary-image {
            min-width: 450px;
            min-height: 100%;
            background-size: cover;
            background-position: center;
            @media (max-width: 768px) {
                display: none
            }
        }
    }
    .content-detail {
        li p strong {
            position: relative;
            &::before {
                content: "\f110";
                position: absolute;
                left: -20px;
                top: 4px;
                font-family: "Flaticon";
                width: 12px;
                height: 12px;
                border-radius: 20px;
                text-align: center;
                background: $Primary;
                color: $White;
                font-size: 6px;
                line-height: 13px;
            }
        }
    }
}
.featured-product-section {
    position: relative;
    .featured-product-slider {
        width: 100%
    }
    .product-slider-active {
        display: flex;
        .slick-slider {
            min-width: 100%
        }
    }
    .slick-slide {
        margin: 0 10px
    }
}
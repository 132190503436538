.points {
    @media (max-width: 768px) {
        float: left;
        margin-top: 20px
    }
    ul li {
        width: 50%;
        float: left;
        line-height: 22px;
        list-style: none;
        padding: 0 20px;
        position: relative;
        &::before {
            content: '\f110';
            position: absolute;
            left: 0;
            top: 6px;
            font-family: "Flaticon";
            width: 12px;
            height: 12px;
            border-radius: 20px;
            text-align: center;
            background: $Primary;
            color: $White;
            font-size: 6px;
            line-height: 13px
        }
        @media (max-width: 768px) {
            width: 100%
        }
        i {
            width: 17px;
            height: 17px;
            color: $Success;
            vertical-align: sub
        }
    }
}
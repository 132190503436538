.data-box {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    text-align: center;
    .order-box {
        display: flex;
        flex-direction: column;
        border: 1px solid $BorderColor;
        > p {
            font-size: 20px
        }
        .order-label {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            line-height: 1;
            background-color: $PrimaryLight;
            color: $White;
            padding: 5px 10px;
            p {
                line-height: 20px;
                margin: 0
            }
            i {
                font-size: 20px;
                margin-right: 10px
            }
        }
        .order-label {
            &.paid, &.paidAtFranchise {
                background-color: $Success
            }
            &.payment-fail {
                background-color: $Error
            }
            &.settled {
                background-color: $Primary
            }
            &.initialised {
                background-color: $Yellow
            }
        }
    }
}
@import '../../../../assets/scss/vars';
.premium-service-box {
    margin-top: 80px;
    height: 160px;
    .slick-slide > div, .slick-slide {
        min-width: 134px !important;
        width: 134px !important
    }
    a {
        justify-self: center;
        display: flex;
        width: 114px;
        flex-direction: column;
        align-items: center;
        .premium-cards {
            border: 1px solid $Primary;
            overflow: hidden;
            width: 114px;
            height: 114px;
            border-radius: 50%;
            box-shadow: 0 1px 2px -2px $ShadowColor;
            background-color: $OffWhite;
            display: flex;
            transition: transform .5s;
            img {
                justify-self: center;
                align-items: center;
                margin: auto;
                &.icon {
                    width: 64px;
                    height: 64px
                }
                &.image {
                    width: 112px;
                    display: none;
                    height: 112px
                }
            }
            &:hover {
                transform: scale(1.2);
                background-color: $Cream;
                img {
                    &.icon {
                        display: none
                    }
                    &.image {
                        display: block
                    }
                }
            }
        }
        .title {
            font-size: 13px;
            text-align: center;
            color: $Dark;
            font-weight: bold;
            line-height: 20px;
            padding: 10px 5px;
            max-width: 140px
        }
    }
}
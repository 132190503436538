@import '../../../../assets/scss/vars';
.franchise-with-us {
    img {
        @media (max-width: 991px) {
            margin: 20px 20px 0;
            width: calc(100% - 40px)
        }
        @media (min-width: 992px) {
            margin-left: auto
        }
    }
    .section-title-wrap {
        p {
            line-height: 1.5
        }
        ul.franchise-benefits {
            padding-left: 30px;
            li {
                padding-left: 10px;
                list-style: circle
            }
        }
    }
    .about-franchise {
        grid-gap: 40px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        h4 {
            font-size: 16px;
            margin-bottom: 10px
        }
        p {
            line-height: 1.5
        }
        img {
            min-width: 120px;
            max-width: 120px;
            margin-right: 20px;
            border-radius: 8px;
            margin-bottom: auto
        }
    }
}
.franchise-display-media {
    .grid-box {
        max-width: 652px;
        width: 100%;
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        text-align: center;
        picture {
            border-radius: 8px;
            overflow: hidden;
        }
    }
    .video-box {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        @media (min-width: 769px) {
            margin-right: 40px
        }
        i {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            color: $White;
            opacity: 0.75;
            font-size: 56px;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.75);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.4);
            }
        }
    }
}
.apply-for-franchise {
    img {
        border-radius: 8px;
        max-width: 420px !important;
        width: 100%;
        @media (min-width: 769px) {
            margin-left: 40px
        }
    }
}
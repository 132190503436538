@import '../../../assets/scss/vars';
picture.picture {
    position: relative;
    display: inline-flex;
    width: auto;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    span.preview-icon {
        position: absolute;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        opacity: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        color: $White;
        bottom: 0;
        background-color: $OverlayColor;
        icon-icon {
            width: 24px;
            height: 24px
        }
        &:hover {
            cursor: pointer;
            opacity: 1
        }
    }
    img {
        width: 100%;
        height: auto;
        margin: 0;
        display: block
    }
}
div.zoomed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $OverlayColor;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    img {
        max-width: 100vw !important;
        max-height: 100vh !important;
        border: 10px solid $White;
        z-index: 0
    }
    .close-icon {
        z-index: 1;
        position: absolute;
        cursor: pointer;
        background-color: $Error;
        right: 80px;
        top: 80px;
        width: 40px;
        height: 40px;
        color: $White;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            line-height: 40px;
            width: 40px;
            text-align: center;
            margin: 0;
            font-size: 32px
        }
    }
}

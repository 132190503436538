* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none
    }
}
::after, ::before {
    box-sizing: border-box
}
form {
    width: 100%
}
sup {
    color: $Primary;
    font-size: 22px;
    line-height: 0;
    vertical-align: middle;
    margin-left: 0
}
.main-section {
    @media (min-width: 992px) {
        margin-top: 116px
    }
    @media (max-width: 991px) {
        margin-top: 60px
    }
}
.heading-02 {
    font-size: 35px;
    line-height: 60px;
    font-weight: 700;
    margin: 0;
    color: $Dark;
    .white {
        color: $White
    }
}
.cartCount {
    position: absolute;
    height: 26px;
    top: -7px;
    right: -17px;
    span {
        border-radius: 20px;
        min-width: 20px;
        min-height: 20px;
        position: relative;
        display: block;
        padding: 0px 4px !important;
        line-height: 20px;
        color: $White !important;
        text-align: center;
        background: $Primary;
        margin-top: 1px;
        font-size: 12px !important
    }
}
body.locationbar-open, body.nav-open {
    overflow: hidden
}
.cart-open .backgroundFixed, .locationbar-open .backgroundFixedLocation {
    top: 0;
    position: fixed;
    transition: all 0.3s ease-in-out;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $OverlayColor;
    opacity: 0.7;
    display: flex
}
.nav-open .backgroundFixedNavBar {
    top: 0;
    position: fixed;
    transition: all 0.3s ease-in-out;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $OverlayColor;
    opacity: 0.7;
    display: flex
}
.search-result-open .background-fixed-search-result {
    top: 0;
    position: fixed;
    transition: all 0.3s ease-in-out;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $OverlayColor;
    opacity: 0.7;
    display: flex
}
.nav-open-filter .background-fixed-shop-filter {
    top: 0;
    position: fixed;
    transition: all 0.3s ease-in-out;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $OverlayColor;
    opacity: 0.7;
    z-index: 9;
    display: flex
}
.search-result-blog {
    position: absolute;
    background: $White;
    z-index: 99;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%
}
.search-result-blog li a {
    padding: 10px;
    width: 100%
}
html {
    height: 100vh;
    width: 100vw;
    line-height: 2;
    overflow: unset;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth
}
    img {
    display: block;
    max-width: 100%;
    height: auto
}
body {
    margin: 0;
    padding: 0;
    @extend html, %regular_font;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify
}
label {
    display: inline-block;
    vertical-align: top;
    cursor: pointer
}
button {
    font-family: $generic_font;
    cursor: pointer;
    white-space: nowrap
}
p {
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 400
}
hr {
    background-color: transparent;
    display: block;
    height: 1px;
    border-width: 0;
    border-top: 1px solid $BorderColor;
    margin: 20px -20px
}
abbr {
    text-decoration: none;
    color: $Error
}
a, .link {
    cursor: pointer;
    display: inline-block;
    color: $Primary;
    &:hover {
        color: $Primary
    }
    &:focus {
        color: $Primary
    }
    &:visited {
        color: $Primary
    }
}
.form-box-setup {
    justify-content: center;
    padding: 60px 20px !important;
    align-items: center
}
.logo-box {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 150px;
    width: 100%;
    opacity: 0.75;
    padding: 20px
}
[disabled], [readonly] {
    cursor: not-allowed
}
#root.front {
    height: 100%;
    width: 100%;
    display: block;
    .search-bar-mobile-hide {
        display: block
    }
    .service-package-icon {
        font-size: 40px;
        color: $BorderColor;
        padding: 10px;
        display: inline-block;
        margin: 10px;
        background-color: $OffWhite;
        padding: 10px;
        border: 5px solid $BorderColor
    }
    .logo-box {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 150px;
        width: 100%;
        padding: 15px 20px 6px;
        background-color: $White;
        border-top-left-radius: 20px
    }
    .scroll-field {
        max-height: 200px;
        overflow: scroll
    }
    .form-box {
        flex: 1;
        margin: auto auto 20px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 20px;
        padding: 0;
        max-width: 300px;
        box-shadow: 0 1px 4px $AshGray;
        &.full {
            max-width: 100%
        }
    }
    .container {
        max-height: calc(100vh - 40px);
        max-width: 100vw;
        display: block;
        width: 100%;
        height: 100%;
        margin: auto;
        padding: 0
    }
    .form-group {
        margin-bottom: 30px;
        position: relative;
        label {
            font-weight: 500;
            color: $Gray;
            &.initial {
                flex: initial
            }
        }
        p {
            flex: 1;
            margin-bottom: 0;
            margin-top: 3px
        }
    }
    .flex-box {
        flex: 1
    }
    input, .input, .date-picker {
        @extend %input
    }
    textarea {
        resize: none
    }
    ::placeholder {
        color: $AshGray;
        font-family: $generic_font;
        font-weight: 400
    }
    .trial {
        width: 100px;
        height: 1000px;
        background-color: $White
    }
    .m-l-8 {
        margin-left: 8.333%
    }
    img.form-logo {
        max-width: 150px;
        display: flex;
        margin: 0 auto;
        text-align: center
    }
    .form-title {
        margin-bottom: 20px;
        font-weight: 400
    }
    .offset-4 {
        margin-left: 33.333333%
    }
    .header-container {
        position: fixed;
        width: 100vw;
        z-index: 9999;
        top: 0px;
        transition: 0.3s all ease-in-out
    }
    .header-container-active-top-bar {
        top: 80px;
        position: fixed;
        width: 100vw;
        z-index: 9999;
        transition: 0.5s all ease-in-out
    }
    a {
        text-decoration: none
    }
    .uppercase {
        text-transform: uppercase
    }
    .capitalize {
        text-transform: capitalize
    }
    .menu-cart {
        position: fixed;
        right: -300px;
        background-color: $White;
        border-left: 1px solid $BorderColor;
        top: 0px;
        bottom: 0;
        width: 300px;
        z-index: 9;
        transition: all 0.3s ease-in-out;
        .cart-title-wrap {
            background-color: $Dark
        }
        .btn-menu {
            left: 0;
            right: auto;
            top: 0;
            margin: 20px
        }
    }
    .open-nav-searchbar {
        background-color: rgba(0, 0, 0, 0.8);
        width: 240px;
        @media (max-width: 1024px) {
            display: none
        }
        .search-bar {
            input {
                color: $White;
                border: 2px solid $BorderColor;
                padding: 10px 15px;
                height: 40px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                width: 60%;
                background: transparent;
                &:focus {
                    outline: none
                }
            }
            button {
                border: 2px solid $BorderColor;
                vertical-align: top;
                border-left: 0;
                padding: 10px 15px;
                background: transparent;
                color: $White;
                height: 40px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                &:focus {
                    outline: none
                }
            }
            ul {
                margin: 50px;
                padding: 0;
                list-style: none;
                max-width: 55%;
                width: 100%;
                text-align: left;
                margin: 0 auto 50px auto;
                overflow: auto;
                max-height: 288px;
                background: $White;
                @media (max-width: 600px) {
                    max-width: 100%
                }
                li {
                    margin: 15px 0;
                    a {
                        border: none;
                        position: relative;
                        padding-left: 20px;
                        font-size: 14px;
                        line-height: 24px;
                        color: $Primary;
                        font-weight: 400
                    }
                }
            }
        }
        .title {
            font-size: 24px;
            padding: 20px;
            color: $White
        }
        .closebtn {
            font-size: 24px;
            padding: 20px;
            color: $White;
            float: right;
            cursor: pointer;
            position: relative;
            top: -90px
        }
    }
    .btn-menu {
        font-size: 32px;
        display: none;
        position: absolute;
        right: 0;
        cursor: pointer;
        color: $NavTextColor;
        margin: 12px 20px 12px 12px;
        @media (max-width: 991px) {
            display: block;
            margin: 5px 14px
        }
    }
    .header-background {
        background: $HeaderColor;
        width: 100%;
        box-shadow: 0 0 5px $ShadowColor;
        img.logo {
            display: block
        }
        img.logo-black {
            display: none
        }
        &.white {
            background-color: $White;
            img {
                &.logo {
                    display: none
                }
                &.logo-black {
                    display: block
                }
            }
        }
        .main-header {
            padding: 10px 15px;
            display: flex;
            margin: 0 auto;
            .left-header {
                max-width: 230px;
                display: flex;
                margin: auto auto auto 0;
                a {
                    display: block;
                    max-width: 110px;
                    img {
                        width: 100%;
                        height: auto
                    }
                }
                .city-name {
                    max-width: 150px;
                    color: $NavTextColor;
                    display: flex;
                    font-size: 12px;
                    margin: 0;
                    align-self: center;
                    padding-left: 10px;
                    cursor: pointer;
                    @media (max-width: 768px) {
                        display: none
                    }
                    i {
                        align-self: center;
                        font-size: 20px;
                        margin-top: -3px
                    }
                }
            }
            .right-header {
                margin-left: auto;
                z-index: 9;
                ul {
                    display: flex;
                    margin: 5px 0 10px;
                    max-height: calc(100vh - 108px);
                    @media (max-width: 991px) {
                        overflow-y: visible
                    }
                    li {
                        position: relative;
                        list-style: none;
                        align-self: center;
                        @media (max-width: 991px) {
                            align-self: flex-start
                        }
                        a, span {
                            display: block;
                            margin: 0 15px;
                            text-decoration: none;
                            color: $NavTextColor;
                            i {
                                width: 20px;
                                height: 20px;
                                margin-top: 8px;
                                cursor: pointer
                            }
                        }
                    }
                }
                .btn-menu {
                    left: 0;
                    right: auto;
                    color: $NavTextColor;
                    top: 0;
                    margin: 20px
                }
                @media (max-width: 991px) {
                    position: fixed;
                    padding-top: 48px;
                    right: -300px;
                    background-color: $Dark;
                    top: 0px;
                    bottom: 0;
                    width: 300px;
                    transition: all 0.3s ease-in-out;
                    ul {
                        flex-direction: column;
                        li {
                            display: block;
                            margin: 10px 20px;
                            a, span {
                                color: $White;
                                margin: 0 10px
                            }
                        }
                    }
                }
            }
        }
        .sub-header {
            background-color: $HeaderColor;
            border-top: 1px solid $BorderColor;
            .jumbo-container > ul {
                display: flex;
                list-style: none;
                margin-right: auto;
                padding: 0;
                li {
                    a {
                        font-weight: 600;
                        font-size: 13px;
                        white-space: nowrap;
                        text-transform: uppercase;
                        color: $NavTextColor;
                        padding: 0 20px;
                        line-height: 40px
                    }
                }
            }
        }
    }
    .jumbo-container {
        margin: 0 auto;
        padding: 0 20px;
        width: 100%
    }
    .banner {
        .slider-items {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 100%;
            height: 100vh;
            min-height: 600px;
            z-index: 0;
            position: relative;
            background-image: url('../images/background-home.png');
            width: 100%;
            display: inline-block;
            .jumbo-container {
                padding: 0;
                height: 100%
            }
            .slider-items-wrap {
                width: 97%;
                min-height: 100vh;
                padding-top: 110px
            }
            .slider-inner {
                width: 100%;
                padding: 0 20px;
                min-height: calc(100vh - 180px);
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    .slider-img {
                        position: absolute;
                        width: 88vw;
                        z-index: 0
                    }
                }
            }
            .slider-img {
                width: 100%;
                img {
                    width: 60%;
                    margin-left: auto;
                    max-height: 290px;
                    object-fit: cover
                }
            }
            .slider-content {
                width: 40%;
                position: relative;
                z-index: 1;
                color: $White;
                @media (max-width: 768px) {
                    width: 100%
                }
                .auto-typing-header {
                    margin: 0 auto 15px;
                    .typewrite {
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 700;
                        letter-spacing: 10px;
                        margin: 0;
                        padding: 0;
                        display: inline;
                        color: transparent;
                        position: relative;
                        &::before {
                            content: attr(data-text);
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            white-space: nowrap;
                            color: $White;
                            overflow: hidden;
                            border-right: 2px solid $Primary;
                            display: flex;
                            align-items: center;
                            animation: type 10s infinite
                        }
                    }
                }
                .slider-title {
                    .heading-01 {
                        color: $Dark;
                        font-size: 65px;
                        line-height: 70px;
                        margin-bottom: 0;
                        font-weight: 300;
                        @media (max-width: 768px) {
                            font-size: 44px;
                            line-height: 56px
                        }
                        .bold {
                            display: block;
                            font-weight: 700;
                            color: $White
                        }
                    }
                }
                .slider-title-bold {
                    .heading-01 {
                        font-size: 65px;
                        line-height: 70px
                    }
                }
                .section-title {
                    .title {
                        color: $Dark;
                        font-size: 16px;
                        position: relative
                    }
                }
                .slider-paragraph {
                    font-size: 14px;
                    line-height: 30px;
                    color: $White;
                    font-weight: 400;
                    width: 75%
                }
                .main-btn-wrap {
                    .main-btn {
                        text-transform: uppercase;
                        max-width: 180px
                    }
                }
                .slider-content-inner {
                    width: 50vw;
                    @media screen and (max-width: 767px) {
                        width: 70vw;
                        position: relative;
                        z-index: 9;
                        margin: auto
                    }
                }
            }
        }
    }
    .how-we-are {
        position: relative;
        .col-6 {
            padding: 0 15px
        }
        .side-form-icons {
            float: right;
            position: sticky;
            top: 6vh;
            display: flex;
            flex-direction: column;
            background: $White;
            box-shadow: -2px 0px 8px 0px rgba(24, 24, 24, 0.16);
            padding: 6px 0;
            z-index: 999;
            right: 50px;
            width: 42px;
            text-align: center;
            transition: all 0.3s ease-in
        }
        .side-form-icons .icon {
            color: $Dark;
            padding: 4px 7px;
            display: block;
            font-size: 22px;
            cursor: pointer;
            transition: .5s;
            line-height: 1.5
        }
        .side-form-icons .icon:hover {
            color: var(--main-color-one)
        }
    }
    .common-section-content {
        display: grid;
        -ms-flex-line-pack: center;
        align-content: center;
        height: 100%;
        .paragraph p {
            color: $Dark;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400
        }
        .read-more-wrap {
            .read-more {
                text-transform: uppercase
            }
        }
    }
    .style-02 {
        .paragraph p {
            color: $White
        }
    }
    .section-title {
        .title {
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
            color: $Dark;
            letter-spacing: 4px;
            text-transform: uppercase;
            position: relative;
            z-index: 0;
            padding-left: 56px;
            text-transform: capitalize;
            margin: 0
        }
    }
    .section-border-image {
        &.margin-left {
            margin-left: 40px
        }
        .border-design {
            border: 1px solid $BorderColor;
            width: 100%;
            position: relative;
            z-index: 0;
            .border-bg {
                background-image: url('../images/back-about.jpg');
                background-size: cover;
                top: -1px;
                right: -1px;
                bottom: -1px;
                left: -1px;
                position: absolute;
                background-color: $Primary;
                z-index: -1;
                transition: all 0.8s
            }
            .we-are-img {
                margin: 60px 60px 60px -60px;
                overflow: hidden;
                position: relative;
                z-index: 0;
                max-height: 500px;
                img {
                    max-width: 500px;
                    max-height: 500px;
                    width: 100%;
                    height: auto
                }
                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    z-index: 1;
                    background-color: rgba(251, 9, 55, 0.1);
                    transition: all 0.8s;
                    background-color: rgba(224, 229, 233, 0.1)
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    background-color: rgba(251, 9, 55, 0.1);
                    transition: all 0.8s;
                    background-color: rgba(224, 229, 233, 0.1)
                }
            }
        }
    }
    .franchise-page, .contact-page {
        background: $White;
        display: flex;
        margin: 30px auto;
        .left-image-section {
            width: 40%;
            img {
                margin: auto;
                display: flex;
                height: 500px
            }
        }
        .right-form-section {
            width: 50%;
            .title-section {
                p {
                    color: $DarkGray
                }
            }
            .input {
                border: none;
                background-color: $Cream;
                padding: 15px;
                height: 40px
            }
            .textarea {
                height: 130px
            }
            .contact-form-section {
                button {
                    width: 150px
                }
            }
        }
    }
    .notification {
        .form-box {
            margin-bottom: 30px;
            padding: 20px 30px !important;
            box-shadow: 0 1px 4px $ShadowColor;
            p {
                margin-bottom: 0
            }
            .cancel-icon {
                float: right;
                i {
                    font-size: 20px;
                    cursor: pointer
                }
            }
        }
    }
    .offerHeading {
        text-align: center;
        margin: 20px 0;
        font-weight: 400
    }
    .offerBox {
        display: flex;
        max-width: 360px;
        border-radius: 8px;
        margin: 20px 0;
        position: relative;
        padding: 20px;
        background-color: $Primary;
        .imageSection {
            width: 50%;
            img {
                width: 180px;
                margin: auto
            }
        }
        .textSection {
            h4 {
                color: $White
            }
            p {
                color: $White
            }
        }
        @media (max-width: 991px) {
            padding: 20px 20px 70px
        }
        .imageSection {
            margin: auto;
            padding: 10px;
            img {
                width: 100%;
                max-width: 100px;
                margin-top: auto
            }
        }
        .textSection {
            width: calc(100% - 100px);
            align-self: center;
            h4 {
                color: $White;
                font-size: 16px;
                font-weight: 400;
                padding: 0 10px
            }
            p {
                color: $White;
                padding: 0 10px;
                margin-top: 20px;
                font-size: 12px
            }
            .cpnbtn {
                font-size: 16px;
                font-weight: bold;
                line-height: 45px;
                position: relative;
                width: 180px;
                height: 45px;
                padding: 0 10px 0 0;
                cursor: pointer;
                text-align: right;
                border-radius: 3px;
                background-color: #ffeeee;
                @media (max-width: 991px) {
                    position: absolute;
                    left: 28%;
                    margin-top: 25px
                }
                @media (max-width: 400px) {
                    position: absolute;
                    left: 28%;
                    margin-top: 0px
                }
                .p1 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 136px;
                    height: 45px;
                    margin: -1px 0 0 -1px;
                    padding-left: 10px;
                    white-space: nowrap;
                    color: $White;
                    border-radius: 3px 0 0 3px
                }
                .p2 {
                    position: absolute;
                    top: 0;
                    right: 15px;
                    overflow: hidden;
                    width: 45px;
                    height: 100%;
                    .t1 {
                        position: absolute;
                        top: 0;
                        overflow: hidden;
                        width: 63.64px;
                        height: 63.64px;
                        transform: translate(-17px, -2px) rotate(-45deg);
                        transition: transform .3s ease-out;
                        backface-visibility: hidden;
                        &:last-of-type {
                            background: none;
                            z-index: 3
                        }
                    }
                    .t2 {
                        position: absolute;
                        top: 17.5px;
                        right: -25px;
                        width: 30px;
                        height: 30px;
                        transform: rotate(45deg)
                    }
                }
                span {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 162px;
                    height: 100%;
                    color: $White;
                    padding-left: 20px;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 400;
                    user-select: none
                }
                &:hover {
                    .t1 {
                        transform: translate(-24px, 6px) rotate(-45deg)
                    }
                    .t2 {
                        top: 17px;
                        right: -14px
                    }
                }
            }
        }
        .youget {
            position: absolute;
            right: 50px;
            top: 5px;
            p {
                color: $White
            }
            .percentoff {
                min-width: 40px;
                height: 40px;
                background: $Dark;
                border-radius: 20px;
                position: absolute;
                text-align: center;
                padding-top: 5px;
                color: $White;
                top: -6px;
                white-space: nowrap;
                left: 60px;
                padding: 5px 10px
            }
        }
        .get-codebtn-holder {
            margin-left: 10px;
            margin-top: 20px
        }
        &.offerBoxDark {
            background-color: $Dark;
            .textSection {
                .cpnbtn {
                    color: $Primary;
                    border: 1px dashed $Primary;
                    &::after, &::before {
                        background-color: $Primary
                    }
                    .p1 {
                        background: $Primary
                    }
                    .p2 {
                        .t1 {
                            background: $Primary
                        }
                        .t2 {
                            background: #a9330c
                        }
                    }
                    &:hover {
                        .t1 {
                            transform: translate(-24px, 6px) rotate(-45deg);
                            &:first-of-type {
                                background-color: rgb(241, 110, 66)
                            }
                        }
                        .t2 {
                            top: 17px;
                            right: -14px
                        }
                        &::after {
                            background-color: rgb(241, 110, 66)
                        }
                        .p1, .t1 {
                            background-color: rgb(241, 110, 66)
                        }
                    }
                }
            }
            .youget {
                .percentoff {
                    background: $Primary
                }
            }
        }
        &.offerBoxOrange {
            background-color: $Primary;
            .textSection {
                .cpnbtn {
                    color: $Dark;
                    border: 1px dashed $Dark;
                    &::after,
                    &::before {
                        background-color: $Dark
                    }
                    .p1 {
                        background: $Dark
                    }
                    .p2 {
                        .t1 {
                            background: $Dark
                        }
                        .t2 {
                            background: #a9330c
                        }
                    }
                    &:hover {
                        .t1 {
                            transform: translate(-24px, 6px) rotate(-45deg);
                            &:first-of-type {
                                background-color: $AshGray
                            }
                        }
                        .t2 {
                            top: 17px;
                            right: -14px
                        }
                        &::after {
                            background-color: $AshGray
                        }
                        .p1, .t1 {
                            background-color: $Gray
                        }
                    }
                }
            }
        }
    }
    .offer-header {
        .modal-header {
            display: flex;
            padding: 10px 20px;
            border-top-left-radius: 9px;
            background-color: $Primary;
            .offer-logo {
                width: 100px
            }
            .offer-description {
                color: $White;
                padding: 0 20px;
                justify-self: center;
                margin: auto 0
            }
        }
        .down-arrow {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $Primary;
            margin: auto
        }
    }
    .showcoupon {
        text-align: center;
        .popup-code-block {
            max-width: 400px;
            min-width: 340px;
            height: 48px;
            display: inline-block;
            background-color: rgba(216, 216, 216, 0.2);
            margin-top: 21px;
            border-radius: 4px;
            margin-bottom: 10px;
            .code-txt {
                height: 48px;
                line-height: 48px;
                max-width: 298px;
                min-width: 242px;
                display: inline-block;
                text-align: center;
                font-size: 20px;
                color: #cd3232;
                transition: all .3s;
                background-color: $White;
                font-weight: bold;
                border-radius: 4px 0 0 4px;
                padding: 0 5px;
                border: 1px dashed #cd3232;
                border-right: none
            }
            .copy-btn {
                width: 108px;
                height: 48px;
                line-height: 48px;
                background-color: #cd3232;
                border-radius: 0 4px 4px 0;
                font-size: 14px;
                font-weight: 400;
                display: inline-block;
                color: $White;
                cursor: pointer;
                vertical-align: top;
                transition: all .3s;
                margin-left: -1px;
                text-align: center
            }
        }
    }
    .coupon-bottom-text {
        span {
            text-align: center;
            margin: 20px;
            display: block
        }
    }
    .add-to-cart-modal {
        .modal-body {
            padding: 20px
        }
        .add-to-cart-modal-header {
            padding-bottom: 20px;
            .modal-header {
                .heading {
                    font-size: 26px
                }
            }
        }
        .car-list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            cursor: pointer;
            .orange {
                color: $Primary
            }
        }
        .car-name {
            padding-left: 10px
        }
        .select-date {
            .date-label {
                padding-left: 0px !important;
                padding: 10px 20px
            }
            .react-datepicker__input-container input {
                width: 100%;
                height: 40px;
                border: 0;
                line-height: 20px;
                background-color: $Cream;
                font-family: $generic_font;
                font-weight: 400;
                color: $Dark;
                border-radius: 8px;
                font-size: 14px;
                padding: 0 20px;
                box-shadow: 1px 2px $BorderColor;
                margin-bottom: 10px
            }
        }
    }
    .big-icon {
        font-size: 48px;
        background-color: $Success;
        padding: 16px;
        color: $White;
        border-radius: 50%;
        display: block;
        margin: auto;
        width: 80px
    }
    .btn-box {
        margin-bottom: 20px;
        .btn-black {
            color: $White;
            background-color: $Dark;
            border: 1px solid $Dark
        }
        .btn-border {
            background-color: transparent;
            border: 2px solid $Primary;
            color: $Primary !important;
            line-height: 36px
        }
    }
    .form-editable-section {
        .service {
            section {
                border-bottom: 1px solid $LightGray;
                padding: 20px 0
            }
        }
    }
    .right-header {
        nav {
            position: relative;
            margin-left: auto;
            @media (min-width: 1025px) {
                width: 160px
            }
            &.profile-setup {
                .profile-box {
                    user-select: none;
                    cursor: pointer;
                    display: flex;   
                    align-items: center;
                    img {
                        width: 40px;
                        height: 40px
                    }
                    i {
                        font-size: 34px;   
                        padding-right: 10px
                    }
                    span {
                        margin: 0 !important;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis
                    }
                    &:hover {
                        i,
                        span {
                            color: $Primary
                        }
                    }
                }
                ul {
                    background-color: $White;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    overflow: hidden;
                    position: absolute;
                    box-shadow: 0 0 5px $ShadowColor;
                    flex-direction: column;
                    margin: 0 !important;
                    width: 100%;
                    z-index: 1;
                    top: 60px;
                    li {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        @media (max-width: 991px) {
                            margin: 10px 0 !important
                        }
                        i, a {
                            font-style: normal;
                            cursor: pointer;
                            display: flex !important;
                            padding: 0 10px !important;
                            margin: 0 !important;               
                            &:hover {
                                background-color: rgba(0, 0, 0, 0.25)
                            }
                            span {
                                color: $TextColor !important;
                                display: block;
                                font-size: 14px;
                                white-space: nowrap;
                                margin: 0 !important
                            }
                            i {
                                color: $TextColor !important;
                                font-size: 14px;
                                width: 14px !important;
                                height: 14px !important;
                                margin: auto 10px auto 0 !important;
                                display: block
                            }
                        }
                    }
                }
            }
        }
    }
    .carlistdetail {
        .form-group {
            margin-bottom: 20px;
            display: inline-block;
            width: 50%;
            &.col {
                width: 100%
            }
            @media (max-width: 768px) {
                width: 100%
            }
            .controls {
                width: 50%;
                padding: 0 15px;
                display: inline-block;
                @media (max-width: 600px) {
                    width: 100%;
                    padding: 0
                }
            }
            .control-label {
                width: 30%;
                font-weight: 500;
                color: $DarkGray
            }
        }
        .flex {
            @media (max-width: 600px) {
                flex-direction: column
            }
            button {
                @media (max-width: 600px) {
                    margin-left: 0 !important
                }
            }
        }
        .title-wrap {
            .image-box {
                position: relative;
                width: 120px;
                height: 80px;
                display: flex;
                margin-right: 20px;
                .car-image {
                    margin: auto
                }
                .shoot-icon {
                    position: absolute;
                    right: -23px;
                    bottom: -9px
                }
            }
        }
        .title {
            left: 100px
        }
    }
    .addcarbtn {
        max-width: 180px;
        display: inline-block;
        margin-left: auto
    }
}
.main-section {
    .section-head {
        max-width: 570px;
        margin: auto;
        text-align: center;
        padding: 30px 0;
        h1.title {
            font-size: 40px;
            text-transform: uppercase;
            line-height: 1em;
            letter-spacing: -1px;
            color: $Dark;
            margin: 0;
            padding: 0 0 15px
        }
    }
    .our-process-section {
        .section-title {
            text-align: center;
            .title {
                color: $Dark;
                &::before, &::after {
                    background-color: $Dark
                }
            }
        }
        .col-2 {
            width: 20%;
            padding: 0 10px;
            @media (max-width: 991px) {
                width: 50%;
                display: inline-block
            }
            @media (max-width: 768px) {
                width: 100%
            }
        }
        .our-process-section__work-items {
            z-index: 2;
            text-align: center;
            position: relative;
            &:hover {
                .items-number {
                    transform: translateY(0)
                }
            }
            .items-number {
                color: #E0E5E9;
                text-shadow: 0 2px 6px rgba(10, 14, 20, 0.2)
            }
            .work-times-inner {
                min-height: 360px;
                margin-top: 20px;
                .items-title {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                    color: $PrimaryLight;
                    line-height: 1.2;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: bold
                }
                p {
                    color: $Dark;
                    line-height: 24px;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                    margin-bottom: 0;
                    text-align: center
                }
            }
            .items-number {
                color: $Primary;
                text-shadow: 0 2px 6px rgba(10, 14, 20, 0.2);
                font-size: 100px;
                line-height: 80px;
                font-weight: 700;
                transform: translateY(32%);
                position: relative;
                z-index: -1;
                transition: all 0.3s ease-in
            }
        }
    }
    .section-title-wrap {
        width: 100%
    }
    .car-adds-section {
        .home-2-car-adds-items {
            margin-bottom: 60px;
            .items-head {
                .border-one {
                    display: flex;
                    width: 150px;
                    height: 150px;
                    line-height: 150px;
                    margin: auto;
                    border-radius: 50%;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 50%;
                        border-top: 1px solid $BorderColor;
                        border-right: 1px solid $BorderColor;
                        border-bottom: 1px solid $BorderColor;
                        animation: round 3s linear infinite
                    }
                    .border-two-wrap {
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        border-radius: 50%;
                        border: 1px dashed $BorderColor;
                        margin: auto
                    }
                    .border-two {
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        border-radius: 50%;
                        margin: auto;
                        position: relative;
                        animation: round-reverse 3s linear infinite;
                        &::before {
                            content: '';
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            width: 12px;
                            height: 12px;
                            line-height: 12px;
                            border-radius: 50%;
                            border: 1px solid #F7B440
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 8px;
                            left: 8px;
                            width: 15px;
                            height: 15px;
                            line-height: 15px;
                            border-radius: 50%;
                            border: 1px solid #2FA1F9
                        }
                    }
                    .icon {
                        font-size: 50px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: $OffWhite;
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                        border-radius: 50%
                    }
                }
            }
            .items-head {
                .icon {
                    img {
                        width: 40px;
                        margin: 15px
                    }
                }
                &.one {
                    .border-one {
                        &::after {
                            border-left: 1px solid #FB0937
                        }
                    }
                    .icon {
                        color: $Primary
                    }
                }
            }
            .items-head {
                &.two {
                    .border-one {
                        &::after {
                            border-left: 1px solid #4CABFF
                        }
                    }
                    .icon {
                        color: #4CABFF
                    }
                }
            }
            .items-head {
                &.three {
                    .border-one {
                        &::after {
                            border-left: 1px solid #35CE9F
                        }
                    }
                    .icon {
                        color: #35CE9F
                    }
                }
            }
            .items-body {
                .title {
                    color: $Dark;
                    font-size: 22px;
                    line-height: 32px;
                    font-weight: 400;
                    margin-bottom: 0
                }
                .padding-around {
                    padding: 16px 40px 25px 40px
                }
                p {
                    color: $Dark;
                    font-size: 14px;
                    line-height: 30px;
                    font-weight: 400;
                    margin-bottom: 0
                }
                .main-btn-wrap {
                    .main-btn {
                        min-width: 180px;
                        background-color: $Primary;
                        color: $White;
                        padding: 10px 16px;
                        text-align: center;
                        border-radius: 30px;
                        font-size: 13px;
                        display: inline-block;
                        text-transform: capitalize;
                        font-weight: 400;
                        transition: all 0.3s ease-in;
                        color: $Dark;
                        text-transform: uppercase;
                        &.gray-border {
                            border: 1px solid #868686;
                            background-color: transparent;
                            color: #868686;
                            box-shadow: none
                        }
                        &:hover {
                            color: $White;
                            background-color: $Primary
                        }
                    }
                }
            }
        }
        .mail-design {
            @media (max-width: 991px) {
                flex-direction: column
            }
        }
    }
    .home-02_tab-section {
        .tab-wrapper {
            width: 50%;
            margin: auto;
            .nav-tabs {
                justify-content: center;
                border: none;
                margin-top: -105px;
                margin-bottom: 1px;
                z-index: 9;
                position: relative;
                display: flex;
                padding: 0;
                .nav-item {
                    text-align: center;
                    border-bottom: 1px solid rgba(63, 64, 69, 0.2);
                    list-style: none;
                    position: relative;
                    cursor: pointer;
                    max-width: 300px;
                    width: 100%;
                    .nav-link {
                        border: none;
                        padding: 8px 0;
                        min-width: 240px;
                        border-radius: 0;
                        color: $Dark;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                        text-decoration: none
                    }
                    .nav-link.active {
                        background-color: $Primary;
                        color: $White
                    }
                }
            }
            .tab-content {
                .select-box-wrap {
                    display: flex;
                    box-shadow: 0 11px 18px rgba(24, 24, 24, 0.2);
                    .items {
                        width: 33.33%;
                        padding: 20px;
                        border-right: 1px solid rgba(63, 64, 69, 0.2);
                        .custom-select-box {
                            min-height: 0;
                            padding: 0 !important;
                            display: flex;
                            align-items: center;
                            >div {
                                width: 100%
                            }
                            select {
                                border: none;
                                margin: auto;
                                padding: 30px;
                                text-transform: uppercase;
                                cursor: pointer;
                                &:focus {
                                    border: none;
                                    outline: none
                                }
                            }
                        }
                        .main-btn-wrap {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            text-transform: uppercase;
                            .main-btn {
                                width: 100%;
                                background-color: $Primary;
                                color: $White;
                                padding: 10px 16px;
                                text-align: center;
                                border-radius: 30px;
                                font-size: 13px;
                                display: inline-block;
                                text-transform: capitalize;
                                font-weight: 400;
                                cursor: pointer;
                                transition: all 0.3s ease-in;
                                text-decoration: none
                            }
                        }
                    }
                }
            }
        }
    }
    .our-service {
        .main-wrapper {
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            .left-image-section {
                flex: 0 0 50%;
                max-width: 48%;
                padding: 0 1%;
                .section-border-image.margin-right {
                    margin-right: 40px;
                    .border-design {
                        border: 1px solid $BorderColor;
                        width: 100%;
                        position: relative;
                        z-index: 0;
                        .border-bg {
                            background-color: $Primary;
                            transition: all 0.8s;
                            left: -1px;
                            position: absolute;
                            z-index: -1;
                            transition: all 0.8s;
                            right: -1px;
                            bottom: 60%;
                            top: -1px
                        }
                        .we-are-img {
                            margin: 40px -40px 40px 40px;
                            overflow: hidden;
                            position: relative;
                            z-index: 0;
                            max-height: 500px;
                            img {
                                max-width: 500px;
                                max-height: 500px;
                                width: 100%;
                                height: auto
                            }
                        }
                    }
                }
            }
            .right-content-section {
                flex: 0 0 50%;
                max-width: 48%;
                padding: 20px;
                .service-section-bottom {
                    .items-wrap {
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        padding-left: 5px;
                        margin: 0;
                        .home-02-our-service-items {
                            list-style: none;
                            width: 50%;
                            display: flex;
                            -ms-flex-wrap: nowrap;
                            flex-wrap: nowrap;
                            padding-bottom: 20px;
                            .item-left {
                                width: 70px;
                                .item-border {
                                    border: 1px solid $BorderColor;
                                    width: 60px;
                                    height: 65px;
                                    position: relative;
                                    z-index: 0;
                                    .border-bg {
                                        position: absolute;
                                        z-index: -1;
                                        left: -1px;
                                        right: -1px;
                                        bottom: -1px;
                                        top: 60%;
                                        transition: all 1s;
                                        background-image: linear-gradient(180deg, #FF9444 0%, #FB0937 100%)
                                    }
                                    .icon {
                                        width: 105%;
                                        height: 90%;
                                        background-color: $Dark;
                                        color: $White;
                                        margin: 5% 0 0 -10%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        font-size: 30px;
                                        transition: all 1s
                                    }
                                }
                            }
                            .item-right {
                                width: 300px;
                                margin: 0 20px 0 10px;
                                .title {
                                    color: $Dark;
                                    font-size: 18px;
                                    line-height: 32px;
                                    margin: 0 0 8px 0;
                                    font-weight: 600
                                }
                                p {
                                    color: $Dark;
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-weight: 400
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.our-working-process {
    background: $BackGray;
    padding: 50px 0;
    margin-bottom: 50px;
    margin-top: 50px;
    .section-head {
        text-align: center;
        padding-bottom: 40px;
        h1.title {
            font-size: 34px;
            padding: 0 0 15px;
            line-height: 46px
        }
        p {
            font-size: 15px
        }
    }
    .page-division-flex {
        display: flex;
        .left-working,
        .right-working {
            padding: 75px 20px 0 45px
        }
        .middle-working {
            padding: 0 20px
        }
        .right-working {
            .icon-box-wrapper {
                align-items: flex-start;
                text-align: left;
                display: flex
            }
        }
    }
    .working-sec {
        width: 33.33%;
        .icon-box-wrapper {
            align-items: flex-start;
            text-align: right;
            display: flex;
            .icon-box-icon {
                display: inline-flex;
                flex: 0 0 auto;
                span {
                    background-image: linear-gradient(to right, $Primary 0, $PrimaryLight 100%);
                    background-repeat: repeat-x;
                    background-color: $Primary
                }
            }
        }
    }
}

.property-requirements {
    .main-secure {
        justify-content: center;
        align-items: center;
        background-image: url(https://cdn.programmerindia.org/upload/property-banner.jpg);
        background-size: cover;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;
        padding: 40px 20px;
        .item {
            transition: 0.3s ease-in-out;
            width: 100%;
            margin-bottom: 20px;
            box-shadow: 0 1px 2px $ShadowColor;
            border-radius: 8px;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;
            background-color: $White;
            .icon {
                background-color: $White;
                position: relative;
                z-index: 1;
                img {
                    display: block;
                    margin: auto;
                    width: 40px;
                    height: 40px;
                }
            }
            .icon-title {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 0;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                color: $White;
                text-align: center;
                padding: 10px;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.5);
                .icon {
                    opacity: 0;
                }
                .icon-title {
                    opacity: 1;
                }
            }
        }
    }
}
section.full-screen-content-sec, .half-part-img-content-sec {
    img {
        margin: auto
    }
}
.content-box h2 {
    margin-bottom: 30px
}
.add-to-cart-btn, .subscribe-btn {
    max-width: 180px;
    flex: 1
}
.main-btn {
    &:hover {
        background-color: $Primary !important;
        color: $White !important;
        border: 1px solid $Primary !important
    }
}
.MuiSlider-root {
    color: $Primary !important
}
.priceProductPage, .service-box-new {
    .sale-price {
        display: inline-block;
        .black {
            color: $DarkGray !important;
            text-decoration: line-through;
            font-size: 12px!important;
            line-height: 1.465!important
        }
    }
    .per-off-product {
        display: inline-block;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 500
    }
    .mrp {
        display: inline-block;
        padding-right: 5px;
        font-size: 18px
    }
}
.per-off {
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    color: $White;
    padding: 0 15px;
    background-color: $Primary;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.grid-list-column-item {
    overflow: hidden;
    width: 100%;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 0 5px $ShadowColor;
    margin: 10px 0;
    position: relative;
    z-index: 0;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    @media (min-width: 992px) {
        max-width: 300px
    }
    img.assuredImg {
        width: 50px !important;
        position: absolute;
        left: 10px;
        z-index: 9;
        top: 10px;
        height: 40px !important
    }
    &:hover {
        .thumb {
            transition: all 0.3s ease-in-out;
            img {
                transform: scale(1.1);
                transition: all 0.3s ease-in-out
            }
        }
    }
    .price-drop-tag {
        position: absolute;
        left: 20px;
        background-color: $Primary;
        border-radius: 4px;
        padding: 2px 10px;
        color: $White;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
        &::before {
            content: '';
            position: absolute;
            left: -6px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 10px solid $Primary;
            border-bottom: 8px solid transparent
        }
    }
    .title {
        color: $Dark;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 8px 0;
        font-weight: 600;
        a {
            width: 100%;
            height: 60px;
            overflow: hidden;
            line-height: 30px
        }
    }
    .thumb {
        margin-bottom: 15px;
        height: 150px;
        position: relative;
        img {
            width: 100%;
            object-fit: contain;
            height: 100%
        }
    }
    .common-rating-style {
        display: flex;
        position: relative;
        justify-content: center;
        input {
            display: none;
            justify-content: center
        }
        label {
            display: block;
            cursor: pointer;
            width: 15px;
            margin: 0 3px
        }
    }
    .sale-price {
        display: inline-block;
        .black {
            color: $DarkGray !important;
            text-decoration: line-through;
            font-size: 12px !important;
            line-height: 1.465 !important
        }
    }
    .per-off-product {
        display: inline-block;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 500
    }
    .mrp {
        display: inline-block;
        padding-right: 5px;
        font-size: 18px
    }
    .main-btn-wrap {
        .main-btn.black-border {
            border: 1px solid $Dark;
            background-color: transparent;
            color: $Dark;
            box-shadow: none;
            min-width: 180px;
            padding: 10px 16px;
            text-align: center;
            border-radius: 30px;
            font-size: 13px;
            display: inline-block;
            text-transform: capitalize;
            font-weight: 400;
            cursor: pointer;
            text-decoration: none;
            transition: all 0.3s ease-in
        }
    }
}
.common-rating-style {
    i {
        font-size: 22px !important;
        z-index: 9;
        cursor: pointer
    }
}
.similar-product {
    h2 {
        text-align: center;
        font-size: 2rem
    }
    .product-page-wrapper {
        max-width: 100%;
        padding: 0 15px;
        .grid-list-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
.WithStyles\(ForwardRef\(LinearProgress\)\)-colorPrimary-2 {
    background-color: #bbbaba !important
}
.rating-section {
    background-color: $BackGray;
    padding: 50px 20px;
    .rating-wrapper {
        margin-bottom: 35px !important;
        .customer-review {
            width: 100%;
            max-width: 600px;
            .rating-section-with-out-of {
                margin-bottom: 10px;
                .out-of {
                    vertical-align: middle;
                    padding-left: 10px
                }
            }
            .rating-box {
                width: 80%;
                .customer-review-progress-bar {
                    margin-bottom: 20px;
                    .rightside-percent {
                        float: right
                    }
                }
            }
        }
    }
}
.write-review {
    padding: 50px 0;
    .review-textarea {
        min-height: 150px;
        width: 100%;
        margin: 0 0 20px;
        border: 1px solid $BorderColor;
        border-radius: 4px;
        padding: 20px
    }
    .main-btn {
        max-width: 180px
    }
}
.thankyou-page {
    .form-box {
        text-align: center
    }
}
.wrapper {
    display: flex
}
.service-offer-section {
    .wrapper {
        display: flex;
        .middle-setup {
            flex: 0 0 50%;
            max-width: 50%;
            margin: auto !important;
            .tac {
                text-align: center !important
            }
        }
    }
}
.service-single-item {
    .thumb-wrap {
        overflow: hidden;
        max-width: 500px;
        .thumb {
            width: 100%;
            position: relative;
            transition: all 0.8s;
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                height: 0;
                z-index: 1;
                background-color: rgba(251, 9, 55, 0.1);
                transition: all 0.8s;
                display: block
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 0;
                background-color: rgba(251, 9, 55, 0.1);
                transition: all 0.8s;
                display: block
            }
        }
    }
    .service-content {
        position: relative;
        @media (max-width: 991px) {
            padding: 15px
        }
        .item-number {
            color: $PrimaryLight;
            font-size: 70px;
            line-height: 60px;
            font-weight: 800;
            text-align: right;
            @media (max-width: 768px) {
                font-size: 50px;
                line-height: 50px
            }
        }
        p {
            margin-top: 20px
        }
        .title {
            color: $Dark;
            font-size: 34px;
            line-height: 44px;
            font-weight: 600;
            padding: 10px 0 10px 25px;
            position: absolute;
            top: 20%;
            left: -20%;
            z-index: 0;
            &::after {
                content: '';
                background-color: $White;
                display: block;
                width: calc(100% + 20px);
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1
            }
        }
        .excerpt {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400
        }
        .more-details {
            color: $Primary;
            font-size: 14px;
            border: 2px solid $Primary;
            font-weight: 400;
            line-height: 36px;
            width: 200px;
            overflow: hidden;
            text-align: center;
            position: relative;
            padding-left: 20px;
            transition: all 0.3s ease-in;
            &::before {
                content: '';
                display: block;
                width: 0;
                border: 30px solid transparent;
                height: 0;
                position: absolute;
                top: 0;
                border-left-color: $Primary;
                border-top-color: $Primary;
                left: 0;
                transition: all 0.3s ease-in-out
            }
            &:hover {
                padding-left: 40px;
                background-color: $Primary;
                color: $White;
                &::before {
                    border-left-color: $White;
                    border-top-color: $White;
                    border-width: 35px
                }
            }
        }
    }
}
.carlistdetail {
    &.account-detail {
        .service-content {
            position: relative;
            display: flex;
            justify-content: space-between;
            @media (max-width: 991px) {
                display: block
            }
            .item-number {
                color: $PrimaryLight;
                line-height: 100px;
                font-weight: 800;
                text-align: right;
                position: relative;
                width: 100px;
                height: 100px;
                &:hover {
                    .print-btn {
                        display: block
                    }
                }
                svg {
                    position: relative;
                    cursor: pointer
                }
            }
            .title-wrap {
                display: flex;
                @media (max-width: 991px) {
                    display: block
                }
            }
            .title {
                color: $Dark;
                font-size: 34px;
                line-height: 44px;
                font-weight: 600;
                padding: 10px 0 10px 25px;
                z-index: 0;
                position: relative;
                top: 0;
                left: 0 !important;
                @media (max-width: 991px) {
                    top: 0 !important;
                    position: relative !important;
                    font-size: 20px !important
                }
                &::after {
                    display: none
                }
            }
        }
        .form-group .control-label {
            @media (max-width: 991px) {
                width: 50% !important
            }
        }
    }
}
#root.front {
    .product-details {
        margin: 30px 0;
        .subscribe-btn {
            margin-left: 10px;
            max-width: 180px;
            background-color: transparent;
            color: $Primary
        }
    }
    .my-order-detail {
        .heading {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            .order-id {
                align-self: center;
                font-size: 20px
            }
        }
        .invoiceBtn {
            padding: 0 20px;
            i {
                padding-right: 10px
            }
        }
    }
    .faq-page {
        margin: 40px 0;
        .search-bar {
            width: 100%;
            margin: 20px 0;
            display: flex;
            height: 40px;
            input {
                width: calc(100% - 80px);
                border: 1px solid $BorderColor;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                padding: 10px 20px
            }
            .search-icon {
                width: 80px;
                text-align: center;
                border-top-right-radius: 4px;
                background-color: $Primary;
                border: 1px solid $Primary;
                cursor: pointer;
                height: 40px;
                border-bottom-right-radius: 4px;
                i {
                    font-size: 20px !important;
                    padding: 10px 0;
                    color: $White;
                    font-weight: 700
                }
            }
        }
        .faq-category {
            flex: 1;
            ul {
                display: flex;
                list-style: none;
                li {
                    flex: 1;
                    padding: 20px 0;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 20px 10px ;
                    overflow: hidden;
                    position: relative;
                    border: .1rem solid transparent;
                    text-align: center;   
                    box-shadow: 0 1px 4px $ShadowColor;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        background-color: rgba(24, 24, 24, 0.3);
                        transition: all 0.3s ease-in
                    }
                    &:hover {
                        &::before {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%
                        }
                    }
                    &.active {
                        background: $PrimaryLight;
                        i {
                            color: $White
                        }
                    }
                    i {
                        display: block;
                        overflow: hidden;
                        margin: 0 auto;   
                        color: $PrimaryLight;
                        font-size: 40px;
                        @media (max-width: 768px) {
                            font-size: 25px
                        }
                    }
                    span {
                        margin-top: 5px;
                        font-size: 16px;
                        display: block;   
                        color: $Dark;
                        text-transform: uppercase;
                        text-align: center;
                        width: 100%;
                        position: relative;
                        overflow: hidden;
                        clear: both;
                        @media (max-width: 768px) {
                           display: none
                        }
                    }
                }
            }
        }
        .faq-details {
            flex: 4;
            margin: 16px;
            .accordion {
                margin-bottom: 0;
                border-top: 1px solid #666;
                transition: 0.3s all ease-in-out;
                border-bottom: none;
                cursor: pointer;
                &.active {
                    .accordion-title {
                        i {
                            transform: rotate(305deg);
                            transition: 0.3s all ease-in-out
                        }
                    }
                }
                &:last-child {
                    border-bottom: 1px solid #666;
                    transition: 0.3s all ease-in-out
                }
                .accordion-title {
                    justify-content: space-between;
                    display: flex;
                    padding: 0 10px;
                    min-height: 60px;
                    font-weight: 500;
                    align-items: center;
                    i {
                        color: $Primary;
                        transition: 0.3s all ease-in-out;
                        font-size: 32px
                    }
                }
                .accordion-text {
                    padding: 0 10px;
                    max-height: 0;
                    overflow: hidden;
                    transition: 0.3s all ease-in-out;
                    &.active {
                        max-height: 100vh;
                        overflow: visible;
                        transition: 0.3s all ease-in-out
                    }
                }
            }
        }
    }
}
.may-be-interested {
    h2 {
        margin: 0 0 8px 0;
        padding: 0 20px
    }
}
.calculate-cart-feild {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .box-header {
        font-size: 22px;
        line-height: 34px;
        font-weight: 400;
        text-transform: uppercase;
        &::after {
            display: block;
            content: '';
            background: $Primary;
            width: 40px;
            height: 2px;
            margin-top: 13px
        }
    }
    .cart-total {
        table {
            td,
            th {
                padding: 12px;
                vertical-align: middle;
                font-size: 16px;
                font-weight: normal;
                background-color: $OffWhite;
                border-left: 0;
                border-right: 0;
                border: 1px solid $BorderColor;
                line-height: 1.5em;
                width: 35%
            }
        }
        .page-margin-top {
            margin-top: 50px
        }
        a.checkout-button {
            float: right;
            text-transform: uppercase;
            margin: 0;
            position: relative;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            line-height: normal;
            letter-spacing: 1px;
            background: transparent;
            color: $Primary;
            border: 1px solid $BorderColor;
            padding: 18px 24px 19px;
            transition: all 0.3s ease 0s;
            border-radius: 0
        }
    }
    .shop-table {
        border-collapse: collapse;
        color: $Gray;
        border: 1px solid $BorderColor;
        margin: 0 -1px 24px 0;
        text-align: left;
        width: 100%;
        border-radius: 5px;
        .shipping-totals {
            p {
                line-height: 26px;
                margin-top: 24px;
                padding: 0;
                text-align: right
            }
            .shipping-methods {
                text-align: left;
                list-style: none outside;
                margin: 0;
                padding: 0;
                li {
                    margin: 0 0 .5em;
                    line-height: 1.5em;
                    list-style: none outside;
                    input {
                        margin: 3px .4375em 0 0;
                        vertical-align: top
                    }
                    label {
                        display: inline;
                        color: $Dark;
                        .amount {
                            font-weight: 700
                        }
                    }
                }
            }
        }
        td {
            padding: 12px;
            border: 1px solid $BorderColor
        }
    }
    .cart-total {
        width: 50%
    }
}
.my-order {
    padding: 50px 0;
    .middle-deail-btn {
        padding: 10px;
        text-align: center;
        margin: 0 auto;
        width: max-content
    }
}
.free-inspection-section {
    .banner-auto-car {
        margin-top: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-image: url('../images/carinspection.jpg');
        height: calc(100vh - 80px);
        @media (max-width: 768px) {
            padding: 0 !important
        }
        .section-title {
            @media (max-width: 768px) {
                margin: 20px 0 0 0
            }
            h2{
                @media (max-width: 768px) {
                    font-size: 24px !important
                }
            }
        }
        .wrapper {
            margin: 0;
            .banner-right-content {
                display: grid;
                -ms-flex-line-pack: center;
                align-content: center;
                height: 100%;
                width: 100%;
                .m-auto {
                    margin: auto !important
                }
            }
        }
    }
    .section-title {
        text-align: center;
        .title.both-line {
            color: $Dark;
            text-transform: uppercase;
            padding-left: 30px;
            padding-right: 30px;
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
            letter-spacing: 4px;
            position: relative;
            z-index: 0;
            padding-left: 30px;
            text-transform: capitalize;
            margin: 0;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transform: translateX(-150%);
                z-index: 1;
                background-color: $Dark;
                width: 20px;
                height: 1px
            }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: $Dark;
                transform: translateX(50%);
                z-index: 1;
                width: 20px;
                height: 1px
            }
        }
    }
    .info-area {
        display: table;
        .info-area-left {
            width: 210px;
            display: table-cell;
            vertical-align: middle;
            .main-btn {
                color: $White;
                max-width: 180px
            }
        }
    }
    .info-area-right {
        .service-time {
            color: $Dark;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400
        }
        .contact-number {
            color: $Dark;
            font-size: 22px;
            line-height: 32px;
            font-weight: 600
        }
    }
}
ul.list-wrap li>a {
    padding-left: 10px
}
.blog-details-wrap {
    .blog-details-items {
        .content {
            background-color: transparent;
            padding: 0;
            height: 100%;
            display: grid;
            .title {
                color: $Dark;
                font-size: 40px;
                line-height: 50px;
                margin: 0 0 8px 0;
                font-weight: 600
            }
            .post-meta {
                display: flex;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                border-bottom: 1px solid rgba(24, 24, 24, 0.3);
                .list-wrap {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    .author {
                        position: relative;
                        padding-left: 20px
                    }
                    i {
                        vertical-align: text-top
                    }
                }
                .list-wrap.two {
                    -ms-flex-pack: distribute;
                    justify-content: space-around;
                    color: $Dark;
                    text-align: center;
                    li {
                        padding-left: 5px
                    }
                }
            }
            .event-meta {
                .list-wrap {
                    width: 100%
                }
            }
            li:first-child .author {
                padding-left: 0 !important
            }
        }
        p {
            color: $Dark;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 0
        }
        .thumb {
            img {
                width: 100%
            }
        }
        .blog-details-inner {
            width: 100%;
            margin-top: 30px;
            display: flex;
            p {
                margin: 0 0 20px;
                line-height: 2
            }
            .thumb {
                width: 50%
            }
            .content {
                width: 50%;
                padding-left: 40px;
                background-color: transparent;
                height: 100%;
                display: grid;
                .title {
                    color: $Dark;
                    font-size: 22px;
                    line-height: 32px;
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin: 0 0 10px 0;
                    border-bottom: 1px solid rgba(90, 16, 16, 0.5)
                }
                .list-items {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    .item {
                        color: $Dark;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 400;
                        padding: 5px 0;
                        list-style: none;
                        .icon {
                            color: $Primary;
                            font-size: 10px;
                            padding-right: 5px
                        }
                    }
                }
            }
        }
        blockquote {
            background-color: $OffWhite;
            margin: 30px 0;
            color: $Dark;
            padding: 20px;
            display: flex;
            .quote-icon {
                width: 7%;
                font-size: 40px;
                color: rgba(251, 9, 55, 0.3)
            }
            p {
                width: 93%
            }
        }
    }
    .blog-details-footer {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .left {
            width: 50%;
            .icon-wrap {
                display: flex;
                justify-content: space-between;
                background-color: $White;
                box-shadow: 0 3px 14px $ShadowColor;
                width: 200px;
                padding: 20px;
                list-style: none;
                margin: 0;
                .share-text {
                    color: $Dark;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400
                }
            }
        }
        .right {
            width: 50%;
            .list-wrap {
                list-style: none;
                display: flex;
                color: $Dark;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                width: 190px;
                float: right;
                padding: 0;
                margin: 0
            }
        }
    }
}
.for-mobile {
    display: none !important
}
.for-desktop {
    display: block !important
}
.product-filtering-area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;
    border-bottom: 1px solid $BorderColor;
    align-items: center;
    .filter-right {
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        align-items: center;
        .filter-icon {
            width: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: $Primary;
            @media (min-width: 769px) {
                display: none
            }
        }
        .sorting-text {
            color: $Dark;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            padding-right: 10px
        }
        select {
            background-color: $White;
            border-radius: 5px;
            border: 1px solid  $BorderColor;
            clear: both;
            cursor: pointer;
            display: block;
            float: left;
            font-family: inherit;
            font-size: 14px;
            font-weight: normal;
            height: 42px;
            line-height: 40px;
            outline: none;
            padding-left: 18px;
            padding-right: 30px;
            position: relative;
            text-align: left !important;
            transition: all 0.2s ease-in-out;
            user-select: none;
            white-space: nowrap;
            width: auto
        }
    }
}
.wrapper1 {
    display: flex
}
body {
    &.nav-open {
        overflow: hidden;
        #root.front .header-background .main-header .right-header {
            right: 0
        }
    }
    &.search-result-open {
        overflow: hidden
    }
    &.cart-open {
        overflow: hidden;
        #root.front .menu-cart {
            right: 0
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}
input[type="number"] {
    appearance: textfield;
    -webkit-appearance: textfield;
    -moz-appearance: textfield
}
.right-content-section {
    table {
        width: 100%;
        border: 0;
        border-collapse: collapse;
        background-color: $White;
        margin-bottom: 20px;
        border-radius: 4px;
        overflow: hidden;
        thead {
            text-align: left;
            tr th {
                background-color: #d0d0ce;
                padding: 10px 15px;
                font-weight: 700
            }
        }
        tbody {
            tr {
                td {
                    padding: 10px 15px;
                    font-weight: 400;
                    &.small-col {
                        height: 40px
                    }
                }
                &:nth-child(odd) {
                    td, th {
                        background-color: $White
                    }
                }
                &:nth-child(even) {
                    td, th {
                        background-color: #d0d0ce
                    }
                }
            }
        }
        tbody tr td,
        tbody tr th,
        thead tr td,
        thead tr th {
            padding: 10px 15px;
            border: 0;
            vertical-align: top;
            text-align: left;
            min-width: 121px;
            position: relative
        }
    }
}
.backgroud-change {
    background-color: $White !important
}
.loginIcon {
    width: 100px;
    height: 100px;
    color: $AshGray;
    font-size: 40px;
    border: 12px solid $PrimaryLight;
    text-align: center;
    background-color: $White;
    border-radius: 50px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 30px;
    i {
        font-size: 28px !important;
        color: $AshGray
    }
}
.carbtn {
    max-width: 180px;
    display: inline-block !important;
    margin-left: 20px
}
.btn-box-header {
    display: flex
}
.btn-submit-profile {
    max-width: 180px;
    margin-bottom: 20px;
    margin-left: auto;
    user-select: none
}
.selectCar {
    display: flex;
    justify-content: space-evenly;
    i {
        align-self: center;
        font-size: 30px !important
    }
    span {
        align-self: center;
        line-height: 40px;
    }
    img {
        width: 80px;
        object-fit: cover
    }
}
.account-detail {
    .selectCar {
        background: $Primary;
        color: $White;
        cursor: pointer
    }
    .nav-item {
        list-style: none;
        max-width: 300px;
        width: 100%;
        position: relative
    }
    .selectedCar {
        border: 1px solid $BorderColor;
        cursor: pointer
    }
}
.selectedCar {
    max-width: 300px !important;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: $White !important;
    color: $Dark !important;
    padding: 0 !important;
    i {
        align-self: center;
        font-size: 30px !important
    }
    span {
        align-self: center;
        font-size: 12px;
        width: 150px;
        line-height: 1.2
    }
    img {
        width: 60px;
        height: 40px;
        object-fit: cover
    }
}
.car-select-header {
    background-color: $Primary;
    .car-select-manufacture {
        display: flex;
        padding: 10px;
        color: $White;
        align-self: center;
        i {
            margin-right: 10px;
            font-size: 26px !important
        }
        span {
            font-size: 18px
        }
    }
}
.notes-box {
    max-width: 320px;
    margin: 100px auto 0
}
.select-manufacturer {
    max-height: 400px;
    overflow: auto;
    height: 100%;
    padding: 20px;
    li.car-manufacturer-list {
        display: flex;
        align-self: center;
        align-items: center;
        padding: 0 20px;
        background: $White;
        cursor: pointer;
        margin-bottom: 10px;
        span {
            font-size: 14px;
            margin-left: 20px;
            color: $Dark
        }
        img {
            width: 88px;
            height: 60px;
            -o-object-fit: contain;
            object-fit: contain
        }
    }
}
#car-select-modal,
#car-list-model {
    .modal-body {
        background-color: #dddddd80
    }
}
.footer-back-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    height: 50px;
    background: $White;
    padding: 10px 20px
}
.read-review {
    .review-user-setion, .review-rating-area-title {
        display: flex
    }
    .purchase-type {
        color: $Primary
    }
    .read-review-rating {
        .star {
            color: $Primary
        }
    }
}
.user-profile-picture {
    max-width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px
}
li {
    list-style: none
}
.service-box-banner {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
    width: 60%;
    margin: 80px 20px 0;
    max-width: 572px;
    @media (max-width: 991px) {
        width: 90%
    }
    @media (max-width: 500px) {
        margin: 20px 20px 0;
        grid-template-columns: repeat(auto-fit,minmax(65px,1fr))
    }
    .data {
        margin-bottom: 20px;
        @media (max-width: 768px) {
            margin-bottom: 0px
        }
        img {
            width: 100px !important;
            height: 100px;
            border-radius: 50%;
            padding: 20px;
            margin: auto;
            background-color: $PrimaryLight;
            @media (max-width: 768px) {
                width: 50px !important;
                height: 50px;
                padding: 5px
            }
        }
        p {
            text-align: center;
            margin: 10px 0;
            font-size: 18px;
            color: $Dark;
            @media (max-width: 768px) {
                margin: 5px 0;
                font-size: 14px
            }
        }
    }
}
#myTab {
    li {
        list-style: none
    }
}
.banner-box-home > div {
    display: flex !important;
    padding: 80px 0 0;
    min-height: calc(100vh - 80px);
    background-size: cover;
    .left-banner {
        flex: 1;
        height: 100%;
        display: flex;
        min-height: calc(100vh - 80px);
        @media (max-width: 768px) {
            flex: unset;
            width: 100%
        }
        .service-details {
            max-width: 500px;
            margin: auto;
            padding: 20px;
            position: relative;
            @media (max-width: 768px) {
                margin: auto 0 0 0
            }
            .wrapper1 {
                margin-top: 40px
            }
            .a-section.a-spacing-small {
                margin-top: 40px
            }
            p {
                font-size: 20px;
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 26px
                }
            }
        }
    }
    table tr {
        background: transparent !important
    }
    .service-tilt-box {
        position: absolute;
        left: 0;
        top: -90px;
        width: auto;
        height: 115px;
        &::after {
            content: '';
            position: absolute;
            height: 70px;
            transform: rotate(22deg);
            width: 1px;
            right: 17px;
            background-color: $White;
            bottom: 24px;
            @media (max-width: 768px) {
                height: 46px;
                transform: rotate(31deg);
                bottom: 48px
            }
        }
        &::before {
            content: '';
            position: absolute;
            width: 18px;
            top: 23px;
            height: 1px;
            background-color: $White;
            right: 4px
        }
        h1.title {
            margin: -15px 0 0;
            white-space: nowrap;
            font-size: 40px;
            @media (max-width: 991px) {
                font-size: 26px;
                margin-top: -2px
            }
            @media (max-width: 768px) {
                font-size: 20px
            }
        }
        .rating-plate {
            margin-right: 40px;
            position: relative;
            background-color: $Primary;
            padding: 10px 40px;
            height: 74px;
            color: $White;
            @media (max-width: 768px) {
                padding: 10px 20px;
                height: 50px
            }
            i {
                color: $White !important
            }
            &::after {
                content: '';
                border: 30px solid transparent;
                border-top: 50px solid $Primary;
                position: absolute;
                right: -30px;
                top: 0;
                bottom: 0
            }
            &::before {
                content: '';
                position: absolute;
                right: -10px;
                bottom: -14px;
                left: 0;
                height: 1px;
                background-color: $White
            }
        }
    }
    .right-banner-inner {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: calc(100vh - 80px)
    }
    .right-banner {
        position: relative;
        flex: 1
    }
}
section.car-select-form {
    width: 240px;
    text-align: revert;
    background: $Primary;
    user-select: none;
    margin-right: 10px;
    position: relative;
    color: $White;
    cursor: pointer;
    box-shadow: 0 1px 4px $ShadowColor;
    @media (max-width: 991px) {
        .nav-tabs {
            display: none
        }
    }
}
.select-car-box {
    width: 300px;
    margin: 0 auto 50px auto;
    background: $Primary;
    user-select: none;
    border-radius: 20px;
    color: $White;
    cursor: pointer;
    box-shadow: 0 1px 4px $ShadowColor
}
i {
    &.refresh-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        line-height: 24px;
        color: $Dark;
        width: 24px;
        cursor: pointer;
        text-align: center
    }
}
.select-category-box {
    cursor: pointer;
    span {
        user-select: none
    }
    i {
        vertical-align: text-top;
        margin-right: 10px
    }
}
.price-filter-category {
    height: 150px;
    overflow: auto
}
.subscription-dashbord {
    display: flex;
    justify-content: center;
    .subs-heading {
        margin-right: 10px
    }
    span {
        text-transform: capitalize;
        color: $Primary
    }
}
.max-width-loginBox {
    max-width: 400px;
    width: 100%
}
.resend-otp {
    text-align: center;
    display: block;
    margin: 10px
}
.switch-box-main {
    justify-content: center;
    margin: 20px 0
}
.dropDown-City {
    position: absolute;
    margin-top: 70px;
    background: $White;
    padding: 10px 20px;
    border-top: none
}
.slider-items-service {
    width: 100%;
    min-height: 600px;
    z-index: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    .wrapper1 {
        margin-top: 40px
    }
    .left-banner .service-details {
        max-width: 500px;
        margin: auto 0 0 0;
        padding: 20px
    }
    .banner-box-home {
        padding-top: 1px;
        @media (max-width: 768px) {
            display: flex !important;
            padding: 80px 0 0;
            min-height: calc(50vh - 40px);
            background-size: cover
        }
        .right-banner {
            .left-banner {
                @media (max-width: 768px) {
                    flex: 1 1;
                    height: 100%;
                    display: flex;
                    min-height: calc(50vh - 40px);
                    background-color: $Dark
                }
            }
            .right-banner-inner {
                @media (max-width: 768px) {
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    min-height: calc(50vh - 40px)
                }
            }
        }
    }
}
.print-btn {
    background-color: $Primary;
    color: $White;
    border: 2px solid $White;
    padding: 2px 10px;
    position: absolute;
    z-index: 9;
    margin: auto;
    top: 39%;
    left: 33%;
    display: none;
    box-shadow: 0 0 1px $White
}
.QR-help {
    position: absolute;
    top: -7px;
    left: -26px;
    z-index: 99;
    height: 22px;
    i {
        color: $Primary;
        font-size: 20px !important;
        cursor: pointer;
        position: absolute;
        &:hover ~ span.tooltip-qr-help {
            display: block
        }
    }
    span.tooltip-qr-help {
        padding: 9px 15px;
        font-size: 12px;
        background: $DarkWhite;
        position: absolute;
        display: none;
        line-height: 20px;
        z-index: 999;
        right: -18px;
        font-weight: 400;
        width: 300px;
        top: 22px;
        color: $Black;
        box-shadow: 0 0 5px $ShadowColor
    }
}
.service-box-new {
    padding: 20px;
    box-shadow: 0 0 5px $ShadowColor;
    border-radius: 12px;
    .service-content {
        position: relative;
        overflow: unset;
        br {
            display: none
        }
        @media (max-width: 768px) {
            flex-direction: column;
            text-align: left
        }
        h1 {
            @media (max-width: 768px) {
                font-size: 24px;
                line-height: 1.5
            }
        }
        .about p {
            font-size: 16px;
            font-weight: 500;
        }
        .image {
            max-width: 300px;
            max-height: 300px;
            min-width: 300px;
            min-height: 300px;
            background-position: center;
            background-size: cover;
            position: relative;
            border-radius: 8px;
            margin-right: 20px;
            @media (max-width: 768px) {
                min-width: 100%;
                min-height: 200px
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0;
                height: 0;
                background-color: rgba(24, 24, 24, 0.3);
                transition: all 0.3s ease-in
            }
            &:hover {
                &::before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%
                }
            }
            &::after {
                position: absolute;
                content: '';
                border-bottom: 2px solid $White;
                border-left: 2px solid $White;
                width: 50px;
                height: 50px;
                left: 20px;
                bottom: 20px
            }
            img { 
                max-width: 196px;
                max-height: 196px;
                min-width: 196px;
                min-height: 196px;
                object-fit: cover;
                @media (max-width: 768px) {
                    min-width: 100%
                }
            }
        }
        
        .title-box {
            width: 100%;
            padding: 0 20px;
            @media (max-width: 768px) {
                padding: 0
            }
            .title {
                width: 100%;
                justify-content: space-between;
                overflow: visible;
                @media (max-width: 768px) {
                    display: block !important
                }
            }
            .hrsTaken {
                color: $Primary;
                display: flex;
                text-align: center;
                flex-direction: column;
                font-size: 10px;
                i {
                    font-size: 32px;
                }
            }
            .addToCartbtn-service-page {
                display: flex;
                width: 100%;
                .get-price {
                    cursor: pointer;
                    font-size: 12px;
                    line-height: 40px;
                    white-space: nowrap;
                    color: $AshGray;
                    @media (min-width: 769px) {
                        margin-right: 40px;
                    }
                }
                .get-off{
                    font-size: 12px;
                    color: $Dark;
                    margin: 0;
                    text-align: right;
                    border-bottom: 1px dashed $BorderColor;
                    cursor: pointer;
                    display: inline;
                    i {
                        font-size: 18px !important;
                        color: $Primary
                    }
                }
                i.hi-info {
                    vertical-align: sub !important;
                    padding-right: 5px !important
                }
                .btn-add-to-cart {
                    background-color: $Primary;
                    color: $White;
                    border: 1px solid $Primary;
                    height: 40px;
                    padding: 0 10px
                }
            }
        }
    }
            
}
.banner-design {
    margin-top: 80px;
    height: calc(100vh - 80px);
    width: 100%;
    background-image: url('../images/h4-slider-01.png');
    background-size: cover;
    background-repeat: no-repeat;
    .banner-background {
        align-items: center;
        justify-content: space-between;
        display: flex;
        align-content: center;
        height: 100%;
        @media (max-width: 768px) {
            display: block;
            width: 70%
        }
        ul {
            margin: auto;
            li {
                background: $Dark;
                position: relative;
                padding: 20px;
                margin-bottom: 20px;
                list-style: none;
                box-shadow: 2px 2px 8px $White;
                a {
                    color: $White;
                    font-size: 18px
                }
                p {
                    color: $White;
                    margin-bottom: 0
                }
            }
        }
        .left-design {
            li {
                &::after {
                    content: '';
                    position: absolute;
                    border: 52px solid transparent;
                    right: -111px;
                    top: 0;
                    border-left: 59px solid $Dark
                }
            }
        }
        .right-design {
            li {
                &::after {
                    content: '';
                    position: absolute;
                    border: 52px solid transparent;
                    left: -111px;
                    top: 0;
                    border-right: 59px solid $Dark;
                    @media (max-width: 768px) {
                        right: -103px;top: 0;
                        border-left: 60px solid $Dark;
                        border-right: 0;
                        left: 0
                    }
                }
            }
        }
    }
}
.header-item {
    margin-top: -300px;
    @media (max-width: 768px) {
        margin-top: 0
    }
    ul {
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
            display: block;
            text-align: center
        }
        li {
            max-width: 300px;
            display: inline-block;
            margin-bottom: 24px;
            cursor: pointer;
            padding: 0;   
            padding-left: 0;
            margin: 0;
            position: relative;
            line-height: 2;
            color: $DarkGray;
            text-align: center;
            &:hover {
                .tab-link-content {
                    &::after {
                        background-color: $Primary
                    }
                    .inner-content {
                        i {
                            color: $White
                        }
                        .tab-title-content {
                            background-color: $White;
                            color: $Dark
                        }
                    }
                }
            }
            .tab-link-content {
                background-size: cover;
                display: block;
                width: 100%;
                position: relative;
                overflow: hidden;
                background-image: url('../images/bacteria.png');
                &.hovered {
                    &::after {
                        background-color: $Primary
                    }
                    .inner-content {
                        i {
                            color: $White
                        }
                        .tab-title-content {
                            background-color: $White;
                            color: $Dark
                        }
                    }
                }
                .inner-content {
                    text-align: center;
                    i {
                        z-index: 2;
                        font-size: 90px;
                        position: relative;
                        line-height: 175px;
                        color: $Dark;
                        transition: all .3s;
                        top: 8px
                    }
                    .tab-title-content {
                        display: block;
                        position: relative;
                        z-index: 2;
                        padding: 90px 15px 0;
                        color: $White;
                        font-size: 16px;
                        line-height: 42px;
                        min-height: 265px;
                        width: 100.5%;
                        transition: all .3s;
                        clip-path: polygon(0% 10%,0 100%,100% 100%,100% 0,28% 24%);
                        background-color: $Primary;
                        p {
                            font-size: 13px;
                            line-height: 20px
                        }
                    }
                }
            }
        }
    }
    &.blog-item {
        width: 100%;
        margin-top: 0;
        .whitebox {
            &:hover {
                .tab-link-content {
                    .inner-content {
                        .tab-title-content {
                            background-color: $Primary;
                            color: $White;
                            a {
                                color: $White
                            }
                            .name-date {
                                .author-name, .publish-date, i {
                                    color: $White
                                }
                            }
                        }
                    }
                }
            }
            .tab-link-content {
                box-shadow: 0 1px 3px 0px rgba(0,0,0,.12);
                .inner-content {
                    .tab-title-content {
                        background-color: $White;
                        color: $Dark;
                        a {
                            color: $Dark
                        }
                        .name-date {
                            .author-name, .publish-date, i {
                                color: $Dark
                            }
                        }
                    }
                }
            }
        }
        ul {
            li {
                max-width: 100%;
                padding: 10px;
                &:hover {
                    .tab-link-content {
                        &::after {
                            background-color: transparent
                        }
                        .inner-content {
                            .tab-title-content {
                                a {
                                    color: $Dark;
                                    transition: all .3s
                                }
                                .name-date {
                                    text-align: left;
                                    .author-name, i, .publish-date {
                                        color: $Dark;
                                        transition: all .3s
                                    }
                                }
                            }
                        }
                    }
                }
                .tab-link-content {
                    box-shadow: 0 1px 3px 0px $ShadowColor;
                    &::after {
                        background-color: #363738;
                        opacity: 0.4
                    }
                    .inner-content {
                        img {
                            max-height: 260px;
                            width: 100%
                        }
                        i {
                            font-size: 20px;
                            color: $White;
                            line-height: 25px;
                            vertical-align: super
                        }
                        .tab-title-content {
                            margin-top: -72px;
                            a {
                                color: $White;
                                line-height: 24px;
                                font-size: 20px
                            }
                            .name-date {
                                text-align: left;
                                display: flex;
                                flex-wrap: nowrap;
                                transition: all .3s;
                                .author-name {
                                    color: $White;
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-weight: 500;
                                    width: 50%
                                }
                                .publish-date {
                                    width: 50%;
                                    color: $White;
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-weight: 400
                                }
                                @media (max-width: 991px) {
                                    flex-direction: column;
                                    .author-name, .publish-date {
                                        width: 100%
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.price-filter {
    position: relative;
    .filterPrice {
        display: flex;
        justify-content: space-between;
        margin: 10px 0
    }
    .priceShow {
        position: absolute;
        top: -28px;
        border: 2px solid $Primary;
        border-radius: 8px;
        height: 24px;
        padding: 0 2px;
        line-height: 20px;
        text-align: center;
        color: $Dark;
        left: -24px;
        background: $White;
        z-index: 99;
        box-shadow: 0 1px 4px $ShadowColor;
        font-size: 12px
    }
}
.address-slider-checkout {
    button.slick-arrow.slick-next {
        right: 20px;
        transform: rotate(45deg);
        width: 15px;
        height: 15px;
        background-color: transparent;
        color: transparent !important;
        border-top: 2px solid $Black;
        border-right: 2px solid $Black
    }
    button.slick-arrow.slick-prev {
        left: 20px;
        z-index: 10;
        transform: rotate(45deg);
        width: 15px;
        height: 15px;
        background-color: transparent;
        color: transparent !important;
        border-bottom: 2px solid $Black;
        border-left: 2px solid $Black
    }
}
.showCarList {
    padding: 10px 20px;
    background: $Primary;
    color: $White;
    width: 100%;
    max-width: 165px;
    margin-right: 20px;
    max-height: 40px;
    border: 1px solid $Primary
}
.appImgDiv {
    ul li {
        cursor: pointer
    }
}
.feedback-page {
    .heading-box {
        .star-icon {
            border: 1px solid $Dark;
            width: 30px;
            height: 30px;
            text-align: center;
            margin: 10px;
            i {
                vertical-align: text-top
            }
        }
    }
    .body-feedback {
        .rating-grid-box {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
            max-width: 400px;
            height: 40px;
            text-align: center;
            color: $White;
            line-height: 40px;
            span {
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &.rate1 {
                    background-color: #ea494e
                }
                &.rate2{
                    background-color: #ef874c
                }
                &.rate3{
                    background-color: #e2c73c
                }
                &.rate4{
                    background-color: #9fcd35
                }
                &.rate5{
                    background-color: #59ae2a
                }
                &.active {
                    transform: scale(1.2)
                }
            }
        }
        .textarea-feedback {
            min-height: 250px;
            border: 1px solid $AshGray;
            border-radius: 8px;
            padding: 16px;
            width: 100%;
            margin: 0 0 20px 
        }
    }
    .title {
        font-size: 20px
    }
}
.event-logo {
    padding: 40px;
    background: $White;
    box-shadow: 0 1px 4px $ShadowColor;
    border-radius: 6px;
    min-height: 320px;
    @media (max-width: 768px) {
        flex-direction: column-reverse
    }
    .logo-box-image {
        flex: 1;
        align-self: center;
        margin: 20px 0;
        img {
            max-width: 200px !important;
            width: 100%;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto
        }
    }
    .logo-box-text {
        font-size: 24px;
        flex: 1;
        align-self: center;
        p.description {
            font-size: 16px;
            line-height: 1.6em
        }
    }
}
.btn-menu-filter, .btn-menu-filterBack, .btn-filter-box-shop {
    display: none
}
#btnContainer {
    .filter-tags {
        @media (max-width: 768px) {
            display: none
        }
        li {
            display: inline-block;
            padding: 8px;
            font-size: 12px;
            background: #d2d2d24f;
            text-align: center;
            border-radius: 8px;
            max-width: 200px;
            margin-right: 10px;
            padding-left: 12px;
            &:hover {
                text-decoration: line-through
            }
            i {
                vertical-align: middle;
                color: $Primary;
                font-size: 20px
            }
        }
    }
}
span.datePrevintivr-checklist {
    font-size: 12px
}
ul.daily-service-data {
    padding-left: 20px;
    li {
        list-style: disc;
        i {
            color: $White;
            padding-left: 10px;
            vertical-align: middle
        }
    }
}
span.datePreventive-checklist {
    font-size: 12px
}
.blog-product-search {
    &-img {
        width: 50px;
        height: 50px;
        margin: auto 0
    }
    &-box {
        border-bottom: 1px solid $BackGray
    }
}
.key-collect {
    font-size: 20px !important;
    color: $Yellow;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999
}
.rating-order-detail {
    i {
        font-size: 22px !important
    }
}
.show-additional-car-detail {
    p {
        font-weight: 500;
        color: $Gray;
        cursor: pointer;
        margin: 30px 0;
        i {
            font-size: 26px !important;
            vertical-align: middle;
            margin-left: 10px;
            transition: 0.3s all ease-in-out
        }
        .additional-detail-active {
            transform: rotate(225deg)
        }
        &:hover {
            color: $Primary
        }
    }
}
.rating-product {
    direction: rtl;
    text-align: left;
    > span {
        width: 28px;
        font-size: 26px;
        cursor: pointer;
        line-height: 28px;
        display: inline-block;
        position: relative;
        color: $AshGray;
        &:hover, &.active {
            color: transparent;
            ~ span {
                color: transparent;
                &::before {
                    content: "\2605";
                    position: absolute;
                    left: 0;
                    color: $Primary
                }
            }
            &::before {
                content: "\2605";
                position: absolute;
                left: 0;
                color: $Primary
            }
        }
    }
}
.review-rating-box-background {
    .rating-product {
        margin-bottom: 10px
    }
}
.product-review-box {
    background-color: $BackGray
}
.reviews-box {
    background-color: $BackGray;
    .review {
        .review-user-box {
            display: flex;
            margin-bottom: 10px;
            p {
                align-self: center;
                margin: 0
            }
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 5px solid $PrimaryLight;
                margin-right: 10px
            }
        }
        .reviwed-date {
            font-size: 12px
        }
    }
}
.checked-service {
    color: $Success !important;
    vertical-align: middle;
    padding-left: 10px
}
.query-list-icon-Box {
    i {
        vertical-align: sub
    }
}
.complaint-type-check {
    i {
        vertical-align: middle;
        padding-right: 10px
    }
    span {
        margin-right: 20px
    }
}
.status-btn, .view-service-status {
    background: $Primary;
    color: $White;
    padding: 5px 10px;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    bottom: 0px;
    height: 30px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer
}
li.car-key-list {
    img {
        width: 50px;
        height: 40px;
        display: inline-block;
        object-fit: contain
    }
    span {
        vertical-align: top;
        display: inline-block;
        padding: 10px
    }
}
.active-time-slot {
    font-size: 12px;
    padding-top: 8px
}
.role-box {
    justify-content: center;
    .subs-heading {
        margin-right: 10px;
        font-weight: 600
    }
}
.monthly-heading {
    width: 535px;
    margin-left: auto;
    @media (max-width: 991px) {
        width: 100%
    }
}
.temporary-login {
    position: fixed;
    bottom: 0;
    background: $FbColor;
    width: 100%;
    margin: auto;
    text-align: center;
    z-index: 9;
    span {
        color: $White;
        text-align: center;
        padding: 10px;
        margin: 0;
        font-size: 20px;
        cursor: pointer;
        display: inline-block
    }
}
.top-to-bottom-icon {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 60px;
    max-width: 60px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-radius: 50%;
    padding: 5px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    overflow: hidden;
    z-index: 9;
    background-color: $White;
    i {
        color: $Black;
        font-size: 24px
    }
    &.bottom-to-top {
        background-color: $Primary;
        color: $White;
        i {
            color: $White;
            transform: rotate(180deg)
        }
    }
}
.column {
    flex-direction: column !important
}
.company-title {
    font-size: 12px;
    margin-top: -16px;
    margin-bottom: 10px
}
.jumbo-container {
    max-width: 1200px
}
.pricing-page {
    .pricing-header-background {
        width: 100%
    }
}
.columns {
    display: flex;
    .left {
        flex: 3;
        padding-right: 20px
    }
    @media (max-width: 600px) {
        flex-direction: column;
        .left {
            padding-right: 0
        }
    }
    .right {
        flex: 1;
        margin-bottom: auto
    }
}
.banner {
    .box{
        .body{
            overflow: unset;
            max-height: 100%
        }
    }
}
.box {
    background-color: $White;
    position: relative;
    max-height: 590px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 1px 2px $ShadowColor;
    transition: all 0.3s ease-in-out;
    &.closed {
        overflow: hidden;
        max-height: 48px
    }
    h4.head {
        line-height: 28px !important;
        padding-top: 5px !important;
        margin: 0;
        cursor: pointer;
        font-size: 18px;
        padding: 0 40px 0 20px;
        line-height: 40px;
        font-weight: 400;
        border-bottom: 1px solid #d0d0ce;
        user-select: none;
        &::after {
            content: '';
            position: absolute;
            border: 6px solid transparent;
            border-top: 7px solid #d0d0ce;
            right: 15px;
            top: 14px
        }
    }
    .body {
        padding: 5px 15px 15px;
        transition: all .3s ease-in-out;
        max-height: 540px;
        overflow: auto;
        &.overflown {
            overflow: visible
        }
    }
    &.blank {
        margin-bottom: auto;
        overflow: hidden;
        h4 {
            &::after {
                display: none
            }
        }
    }
}
.switch {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    &.on {
        background-color: $Success
    }
    &.off {
        background-color: $Error
    }
}
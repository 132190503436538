.blog-page {
    .wrapper {
        margin: 0
    }
    .blog-single-item {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 30px;
        background-color: $Cream;
        padding: 25px 30px 30px 30px;
        .thumb-area {
            position: relative;
            overflow: hidden;
            .thumb {
                position: relative;
                transition: all 1s;
                &.eventthumb {
                    img {
                        max-height: 300px;
                        object-fit: contain
                    }
                }
                img {
                    width: 100%;
                    height: auto
                }
                &::after {
                    position: absolute;
                    z-index: 1;
                    content: '';
                    display: block;
                    top: 50%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    background-color: rgba(251, 9, 55, 0.1);
                    transition: all 0.5s
                }
            }
            .date {
                position: absolute;
                top: 0;
                right: 0;
                color: $White;
                background-color: $Dark;
                width: 80px;
                height: 90px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .day {
                    display: block;
                    color: $White;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 600
                }
                .month {
                    display: block;
                    color: $White;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400
                }
            }
        }
        .content {
            height: 100%;
            display: grid;
            padding: 25px 0 0;
            .title {
                color: $Dark;
                font-size: 30px;
                line-height: 45px;
                margin: 0 0 8px 0;
                font-weight: 600
            }
            .post-meta {
                display: flex;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                border-bottom: 1px solid rgba(24, 24, 24, 0.3);
                .list-wrap {
                    width: 50%;
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    .author {
                        position: relative;
                        padding-left: 20px;
                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            content: '';
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: $Primary
                        }
                    }
                }
                .list-wrap.two {
                    -ms-flex-pack: distribute;
                    justify-content: space-around;
                    color: $Dark;
                    text-align: center;
                    li {
                        padding-left: 5px
                    }
                }
            }
            .event-meta {
                .list-wrap {
                    width: 100%;
                    @media (max-width: 768px) {
                        flex-direction: column
                    }
                    li {
                        margin-right: 20px;
                        i {
                            vertical-align: middle
                        }
                        .author {
                            padding-left: 0;
                            &::before {
                                display: none
                            }
                        }
                    }
                }
            }
            p {
                color: $Dark;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 0
            }
            .read-more-area {
                display: flex;
                .read-more {
                    width: 60%;
                    -ms-flex-item-align: center;
                    align-self: center;
                    a {
                        color: $Primary;
                        font-size: 14px;
                        line-height: 24px;
                        border: 1px solid $Primary;
                        border-radius: 34px;
                        padding: 10px 20px;
                        position: relative;
                        transition: all 0.3s ease-in;
                        &:hover {
                            background-color: $Primary;
                            color: $White
                        }
                        &::before {
                            content: '';
                            display: block;
                            width: 50px;
                            height: 1px;
                            background-color: $Dark;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.5s
                        }
                    }
                }
                .read-more-event {
                    a {
                        width: 60%;
                        text-align: center
                    }
                }
            }
        }
    }
    .share-icon li:hover {
        color: $Primary
    }
    i {
        margin-right: 10px
    }
    li{
        list-style: unset
    }
}
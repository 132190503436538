@import '../../../../assets/scss/vars';
.share {
    display: flex;
    flex-direction: column;
    .share-text {
        color: $Dark;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400
    }
    .line-shape {
        padding: 8px 0;
        position: relative;
        width: 140px;
        display: flex;
        justify-content: space-between;
        &::before, &::after {
            content: '';
            display: block;
            background-color: $Mischka;
            width: 64px;
            height: 1px
        }
        .caret {
            position: relative;
            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 1px;
                background-color: $Mischka;
                transform: rotate(-45deg);
                position: absolute;
                bottom: -3px;
                right: -7px
            }
            &::after {
                content: '';
                display: block;
                width: 9px;
                height: 1px;
                background-color: $Mischka;
                transform: rotate(45deg);
                position: absolute;
                bottom: -3px;
                right: -1px
            }
        }
    }
    .icon-wrap {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        li {
            color: $Dark;
            font-size: 14px;
            margin: 0 5px;
            padding: 0 2px;
            transition: all 0.3s ease-in;
            &:hover {
                color: $Primary
            }
        }
    }
}
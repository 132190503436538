@import '../../../../assets/scss/vars';
.about-page {
    section.our-usp ul {
        padding-left: 30px;
        li {
            list-style: disc;
            padding-left: 10px
        }
    }
    .show-services {
        grid-gap: 60px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        max-width: 920px;
        margin: auto;
        li {
            background-size: cover;
            background-position: center;
            min-height: 275px;
            overflow: hidden;
            border: 1px solid $Primary;
            position: relative;
            border-radius: 12px;
            div {
                line-height: 1.5;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $Primary;
                color: $White;
                text-align: center;
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                right: 0;
                font-size: 20px;
                font-weight: 500
            }
            &:nth-child(2), &:nth-child(5) {
                transform: translate(0, 100px)
            }
        }
    }
    .steps-provide {
        margin-bottom: 60px;
        grid-gap: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        justify-content: space-between;
        .step {
            width: 200px;
            height: 160px;
            position: relative;
            border-radius: 12px;
            border: 1px solid $Primary;
            p {
                padding: 10px 20px 0 12px;
                line-height: 1.4;
                text-align: left;
                font-size: 18px;
                font-weight: bold
            }
            span {
                color: $PrimaryLight;
                font-size: 100px;
                position: absolute;
                line-height: 1;
                font-weight: 600;
                right: 0;
                bottom: -10px
            }
            i {
                position: absolute;
                width: 27px;
                background-color: $Primary;
                color: $White;
                line-height: 25px;
                text-align: center;
                border-top-right-radius: 14px;
                border-bottom-right-radius: 14px;
                font-size: 18px;
                right: -28px;
                top: calc(50% - 12.3px)
            }
        }
    }
    .join-us {
        margin-top: 300px;
        .jumbo-container {
            padding: 40px !important;
            border-radius: 20px;
            border: 1px solid $Primary;
            text-align: center;
            box-shadow: 0 2px 3px $ShadowColor;
            img {
                margin: -180px auto -30px;
                transform: translate(100px, 0)
            }
            h1 {
                font-size: 40px;
                color: $Primary
            }
            p {
                font-size: 16px
            }
        }
    }
}
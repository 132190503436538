@import '../../../../assets/scss/vars';
.event-box {
    padding: 0 10px;
    .event-service {
        min-height: 500px;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        transition: all 0.3s ease-in-out;
        button {
            display: none;
        }
        &:hover {
            transform: scale(1.1);
            button {
                display: block
            }
        }
        h3 {
            color: $White;
            text-align: left;
            
        }
    }
}
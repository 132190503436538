@import '../../../../assets/scss/vars';
.error-page {
    text-align: center;
    a {
        text-decoration: none;
        background-color: $Dark;
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0 1px 2px $ShadowColor
    }
    h1 {
        font-weight: normal;
        text-shadow: 0 2px 5px $DisabledColor;
        font-size: 25vw;
        color: $White;
        line-height: 1.2
    }
    .oops-text {
        font-weight: 600;
        font-size: 40px
    }
}
@import '../../../../assets/scss/vars';
.dash-box {
    position: relative;
    .action-bar {
        position: absolute;
        right: 24px;
        z-index: 1;
        top: 4px;
        display: none
    }
    &:hover {
        .action-bar {
            display: flex
        }
    }
    .box {
        i.widget-icon {
            font-size: 40px !important;
            vertical-align: middle;
            margin-right: 15px;
        }
        form {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(80px, 1fr))
        }
    }
}
.dashboard-widgets {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    li {
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        p {
            text-transform: capitalize;
            margin: 0;
            text-align: center;
            font-size: 13px
        }
        img {
            border: 1px solid $BorderColor
        }
    }
}
@import '../../../../assets/scss/vars';
.secure-sec {
    .main-secure {
        .item {
            .index-box {
                font-size: 28px;
                background-color: $Primary;
                color: $White;
                height: 60px;
                min-width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                margin: 10px 20px 0 0
            }
            .main-box {
                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize
                }
                p {
                    font-size: 16px
                }
            }
        }
    }
    img {
        max-width: 560px !important;
        margin-bottom: auto;
        width: 100%;
        @media (min-width: 992px) {
            margin-left: auto
        }
    }
}
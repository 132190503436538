.pre-approved-services-box {
    margin: 0px -20px -20px;
    padding: 10px 25px 10px;
    background-color: #eee;
    .pre-approved-services li {
        width: 100%;
        height: 40px;
        max-width: 360px;
        padding: 3px 3px 3px 0px;
        button {
            float: right;
        }
        &:hover {
            background-color: #ccc;
        }
    }
}
.event-page {
    .gallery-items {
        .img-item {
            float: left;
            max-height: 200px;
            max-width: 100%;
            margin: 0 20px 20px 0;
            img {
                max-height: 120px;
                object-fit: cover;
                width: 100%
            }
        }
    }
}
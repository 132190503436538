@import '../../../../assets/scss/vars';

.sidebar {
    width: 240px;
    transition: all 0.3s ease-in-out;
    background-color: $SidebarColor;
    .logo-link {
        background-color: $SidebarColor;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        height: 80px;
        padding: 5px;
        display: flex;
        img {
            max-height: 70px;
            width: auto;
            display: block;
            margin: auto
        }
        ~ .subtitle {
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
            margin-top: -28px;
            position: absolute;
            left: 38px;
            color: $White
        }
    }
    nav {
        &.sidebar-nav {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: calc(100vh - 81px);
            ul {
                &.nav {
                    padding: 0 0 10px 0;
                    li {
                        margin: 5px 0;
                        position: relative;
                        > span {
                            width: 10px;
                            height: 10px;
                            background-color:$Cream;
                            display: block;
                            position: absolute;
                            &::after {
                                content: '';
                                width: 10px;
                                height: 10px;
                                background-color:$Dark;
                                display: block;
                                position: absolute
                            }
                            &.upper-link {
                                right: 0;
                                top: -10px;
                                &::after {
                                    border-bottom-right-radius: 10px
                                }
                            }
                            &.bottom-link {
                                right: 0;
                                top: 40px;
                                &::after {
                                    border-top-right-radius: 10px
                                }
                            }
                        }
                        a, button {
                            display: block;
                            margin: 0 15px;
                            transition: all 0.3s ease-in-out;
                            padding: 6px 15px;
                            border-radius: 8px;
                            background: transparent;
                            border: none;
                            width: 100%;
                            text-align: left;
                            height: 40px;
                            i {
                                font-size: 16px;
                                margin-right: 15px;
                                color: $DarkGray;
                                vertical-align: text-top;
                                &:last-child {
                                    font-size: 10px;
                                    float: right;
                                    width: 10px;
                                    height: 10px;
                                    margin-top: 13px
                                }
                            }
                            span{
                                color: $DarkGray
                            }
                        }
                        &.active {
                            i {
                                color: $Primary
                            }
                            > button, > a {
                                background: $Cream;
                                color: $Dark
                            }
                            span{
                                color: $Primary
                            }
                        }
                        ul {
                            &.sub-nav {
                                max-height: 0;
                                display: block;
                                overflow: hidden;
                                margin-left: 15px;
                                transition: all 0.3s ease-in-out
                            }
                        }
                        &.active {
                            > ul {
                                &.sub-nav {
                                    max-height: 500px
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
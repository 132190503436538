@import '../../../../assets/scss/vars';
.payment-success {
    padding: 50px 0;
    &.form-box-setup {
        padding: 48px 0;   
        background: $PrimaryLight;
        margin: 60px auto !important;
        border-radius: 24px;
        .btn-box-payment {
            display: flex;
            max-width: 500px;
            margin: 50px auto
        }
        .order-id-text {
            font-size: 16px;
            font-weight: 600
        }
        .order-text-payment {
            font-size: 16px;
            opacity: 0.6;
            font-weight: 500
        }
        .mail-text-payment {
            opacity: 0.4
        }
        .success-Text-payment {
            color: $Success;
            margin: 20px 0
        }
    }
}
@import '../../../../assets/scss/vars';
@import '../../../../assets/scss/define';
.chat-panel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .left {
        width: 100%;
        background-color: $White;
        z-index: 0;
        @media (min-width: 769px) {
            position: relative;
            border-right: 1px solid $BorderColor;
            max-width: 360px
        }
        @extend %absoluteMobileFill;
        .contacts {
            list-style: none;
            padding: 4px 0;
            .contact-container {
                position: relative;
                padding: 8px 12px;
                h4 {
                    color: $Gray
                }
                span {
                    color: $AshGray;
                    font-size: 12px;
                    margin-top: -3px;
                    display: block
                }
                &:not(:last-child) {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        height: 1px;
                        left: 72px;
                        right: 0;
                        background-color: $BorderColor
                    }
                }
            }
        }
    }
    .right {
        width: 100%;
        background-color: $White;
        z-index: 1;
        @media (min-width: 769px) {
            max-width: calc(100vw - 360px)
        }
        @extend %absoluteMobileFill, %slowTransition;
        @media (max-width: 768px) {
            left: 100%;
            &.active {
                left: 0
            }
        }
        .footer {
            padding: 12px 12px;
            background: $Gray
        }
        .content {
            padding: 20px 20px 0;
            .chat-container {
                margin-bottom: 20px;
                display: flex;
                .msg {
                    position: relative;
                    padding: 12px 16px 28px;
                    max-width: 75%;
                    min-width: 100px;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border: 10px solid transparent;
                        top: 10px
                    }
                    .time {
                        position: absolute;
                        bottom: 4px;
                        opacity: 0.35
                    }
                    &.mine {
                        color: $Dark;
                        background-color: $BorderColor;
                        margin-left: auto;
                        margin-right: 20px;
                        .time {
                            left: 12px
                        }
                        &::before {
                            right: -23px;
                            border-left: 13px solid $BorderColor
                        }
                    }
                    &.other {
                        color: $White;
                        background-color: $Gray;
                        margin-right: auto;
                        margin-left: 20px;
                        .time {
                            right: 12px
                        }
                        &::before {
                            left: -23px;
                            border-right: 13px solid $Gray
                        }
                    }
                }
            }
            .icon-box {
                position: fixed;
                right: 12px;
                bottom: 75px
            }
        }
    }
    .content {
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        @extend %slowTransition
    }
    input {
        width: 100%;
        height: 40px;
        border: 0;
        border-radius: 20px;
        line-height: 24px;
        padding: 4px 12px
    }
    .footer, .header {
        display: flex;
        align-items: center;
        height: 65px;
        position: relative;
        z-index: 1
    }
    .header {
        vertical-align: middle;
        border-bottom: 1px solid $BorderColor;
        justify-content: space-between;
        padding: 0 12px
    }
    .footer {
        border-top: 1px solid $BorderColor;
        .msg-form {
            flex: 1;
            margin: 0 8px
        }
    }
    .tabs {
        list-style: none;
        display: flex;
        height: 100%;
        flex: 1;
        .tab {
            flex: 1;
            justify-content: center;
            display: flex;
            flex-direction: column;
            background-color: $White;
            color: $Gray;
            transition: all 0.2s ease-in-out;
            &:not(:first-child) {
                border-left: 1px solid $BorderColor
            }
            i {
                width: 20px;
                height: 20px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 8px;
                transition: all 0.2s ease-in-out
            }
            span {
                text-align: center;
                font-size: 10px;
                margin-top: 4px
            }
            &.active {
                i {
                    width: 26px;
                    height: 26px
                }
                span {
                    margin-top: 0
                }
            }
            &.active {
                background-color: $Gray;
                color: $White
            }
        }
    }
    .icon-box {
        display: flex;
        height: 32px;
        min-width: 32px;
        max-width: 32px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
        &:hover {
            background-color: $BorderColor
        }
        i {
            height: 20px;
            width: 20px
        }
    }
    .img-box {
        width: 48px;
        height: 48px;
        display: flex;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
        position: relative;
        i {
            background-color: $BorderColor;
            width: 32px;
            height: 32px;
            color: $White
        }
        img {
            border-radius: 24px
        }
        .liveStatus {
            width: 8px;
            height: 8px;
            background-color: $Warning;
            border-radius: 4px;
            bottom: 2px;
            position: absolute;
            right: 2px;
            z-index: 1
        }
    }
    .search-form {
        padding: 8px 12px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $Gray
    }
    .messages {
        .search-form {
            top: -20px;
            margin: -20px -20px 20px -20px
        }
    }
}
@import '../../../../assets/scss/vars';
.time-slots {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    .time-slot-single {
        min-width: 110px;
        background-color: $Cream;
        color: $Dark;
        height: 50px;
        text-align: center;
        position: relative;
        cursor: pointer;
        display: flex;
        font-size: 10px;
        user-select: none;
        justify-content: center;
        flex-direction: column;
        padding: 0 10px;
        margin: 10px 0;
        margin-right: 10px !important;
        i {
            position: absolute;
            top: -5px;
            left: 105px;
            font-size: 14px
        }
    }
}
@import '../../../..//assets/scss/vars';
.notification {
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column
    }
    .notification-image-box {
        max-width: 200px;
        margin-right: 20px;
        @media (max-width: 768px) {
            margin-bottom: 20px
        }
        img {
            width: 100%;
            height: 100%
        }
    }
    .notification-content {
        h1 {
            line-height: 1.5;
            font-size: 20px
        }
    }
}
@import '../../../../assets/scss/vars';
.getApp-box {
    background-image: url('https://cdn.programmerindia.org/upload/download-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: end;
    border-radius: 12px;
    color: $White;
    @media (max-width: 600px) {
        > .flex {
            flex-direction: column
        }
        .mobile-front {
            display: none
        }
    }
    .right-side-app-detail {
        margin-right: 20px;
        max-width: 400px;
        align-self: center;
        margin-left: 10px;
        .appImgDiv{
            span {
                color: $Dark;
                opacity: 0.6;
                margin-bottom: 10px;
                display: block
            }
        }
    }
    .mail-number-section {
        i {
            vertical-align: baseline;
            margin-right: 5px
        }
    }
    .emailDownload {
        margin-right: 10px
    }
    .inputLink {
        margin: 20px 0;
        .input {
            background-color: transparent !important;
            border: 1px solid $BorderColor !important;
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
            border: 1px solid $ShadowColor;
            color: $White !important
        }
        button {
            margin: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            max-width: 150px;
            margin: 0;
            cursor: pointer;
            display: block;
            font-family: $generic_font;
            font-size: 14px !important;
            font-weight: 400;
            height: 40px;
            width: 100%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background: $Primary;
            color: $White;
            border: 2px solid $Primary
        }
    }
    .mobile-front {
        max-width: 250px;
        margin-top: auto
    }
}
@import '../../../../assets/scss/vars';
.chatboy {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    .chatbubble {
        background-color: $White;
        position: absolute;
        right: -90px;
        top: -25px;
        width: 100px;
        border-radius: 50%;
        padding: 8px 0;
        line-height: 1.5;
        text-align: center;
        box-shadow: 0 1px 2px $ShadowColor;
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-right: 17px solid $White;
            left: -10px;
            transform: rotate(84deg);
            bottom: 11px
        }
        &::before {   
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 11px solid transparent;
            border-right: 18px solid $BorderColor;
            left: -11px;
            transform: rotate(84deg);
            bottom: 9px
        }
    }
}
.chatbot {
    max-width: 300px;
    overflow: hidden;
    display: none;
    width: 100%;
    border: 2px solid $PrimaryLight;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    left: 20px;
    bottom: 0;
    background-color: $White;
    z-index: 9;
    &.is-active {
        display: block;
        ~.chatboy {
            display: none
        }
        .chatbot-wrapper {
            padding: 10px;
            max-height: 300px;
            overflow: auto
        }
        .msg-input {
            max-height: 60px;
            height: 100%;
            padding: 10px
        }
        .chat-header {
            .close {
                display: block;
            }
            .msg-icon {
                display: none;
            }
        }
    }
    .msg-input {
        display: flex;
        max-height: 0;
        transition: all 0.3s ease-in-out;
        height: 60px;
        background-color: $BorderColor;
        padding: 0 10px;
        width: 100%;
        position: relative;
        margin: 0 40px 0 0;
        input {
            line-height: 20px;
            height: 40px;
            border: 0;
            width: calc(100% - 40px);
            padding: 10px 15px;
            font-size: 13px;
            background-color: $White;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px
        }
        button {
            width: 40px;
            height: 40px;
            text-align: center;
            border: 0;
            background-color: $White;
            padding: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            i {
                font-size: 20px !important;
                color: $AshGray;
            }
        }
    }
    .chat-container {
        display: flex
    }
    .chatbot-wrapper {
        max-height: 0;
        padding: 0 20px;
        height: 100vh;
        transition: all 0.3s ease-in-out;
        overflow: auto;
        .bot-chat {
            max-width: 200px
        }
    }
    .msg {
        max-width: 220px;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 8px 13px;
        line-height: 1.5;
        &.bot {
            background: $BorderColor;
            margin-right: auto;
            border-top-left-radius: 0px
        }
        &.mine {
            border-bottom-right-radius: 0px;
            color: $White;
            flex-direction: row-reverse;
            background: $PrimaryLight;
            margin-left: auto
        }
    }
    .chat-header {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        background-color: $Primary;
        padding: 10px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .msg-icon {
            display: block;
            color: $White;
            font-size: 20px !important
        }
        .title {
            color: $White
        }
        .close {
            display: none;
            color: $PrimaryLight;
            background-color: $White;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            left: auto;
            right: 0
        }
    }
}
.typing {
    color: $White;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: load7 1.8s infinite ease-in-out
    }
    &::before {
        left: -3.5em;
        animation-delay: -0.32s
    }
    &::after {
        left: 3.5em
    }
}
@-webkit-keyframes load7 {
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }
    40% {
        box-shadow: 0 2.5em 0 0
    }
}
@keyframes load7 {
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }
    40% {
        box-shadow: 0 2.5em 0 0
    }
}
@import '../../../../assets/scss/vars';
.product-page {
    .banner {
        img {
            width: 100%
        }
    }
    .custom-select-box {
        width: 180px
    }
    .product-page-body {
        display: flex;
        @media (max-width: 768px) {
            .recent-product {
                display: none
            }
        }
        .product-page-wrapper {
            width: 100%;
            padding: 0 15px;
            @media (min-width: 992px) {
                max-width: calc(100% - 300px)
            }
            .grid-list-wrapper {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                grid-gap: 20px
            }
            .product-filter-wrapper {
                .widget {
                    margin-bottom: 30px;
                    .widget-title {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 31px;
                        margin-bottom: 20px;
                        position: relative;
                        border-bottom: 0 !important;
                        color: $Dark;
                        &::after {
                            content: '';
                            display: block;
                            border-bottom: 1px solid $Dark;
                            width: 100%;
                            margin-top: 10px
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li {
                            margin: 15px 0;
                            a {
                                position: relative;
                                padding-left: 20px;
                                font-size: 14px;
                                line-height: 24px;
                                color: $Dark;
                                font-weight: 400;
                                border: none
                            }
                        }
                    }
                }
            }
        }
        .widget-scroll {
            max-height: 850px;
            overflow: scroll
        }
        .product-filter-wrapper {
            max-width: 300px;
            padding: 40px 15px 0;
            .widget {
                margin-bottom: 30px;
                @media (max-width: 768px) {
                    color: $White
                    }
                .widget-title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 31px;
                    margin-bottom: 20px;
                    position: relative;
                    border-bottom: 0 !important;
                    color: $Dark;
                    @media (max-width: 768px) {
                        color: $White
                        }
                    &::after {
                        content: '';
                        display: block;
                        border-bottom: 1px solid $Dark;
                        width: 100%;
                        margin-top: 10px;
                        @media (max-width: 768px) {
                            border-bottom: 1px solid $White
                            }
                    }
                    .price-filter {
                        .price-slider-amount {
                            input[type="submit"] {
                                border: none;
                                color: $White;
                                font-size: 14px;
                                line-height: 24px;
                                font-weight: 500;
                                background-color: $Dark;
                                padding: 5px 10px;
                                border-radius: 20px;
                                display: block;
                                float: left;
                                margin-right: 10px;
                                text-transform: capitalize;
                                transition: all 400ms ease-out 0s
                            }
                            input[type="text"], input[type="file"] {
                                border: none;
                                float: left;
                                font-weight: bold;
                                height: 30px;
                                letter-spacing: 3px;
                                margin-left: 5px;
                                text-align: left;
                                width: auto;
                                background-color: transparent;
                                font-size: 12px;
                                line-height: 22px;
                                font-weight: 500;
                                color: $Dark
                            }
                        }
                    }
                }
                .recent-post-item {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    .single-recent-post-item {
                        padding-bottom: 20px;
                        word-break: break-word;
                        margin: 0 0 20px;
                        background-color: $White;
                        padding: 0 20px 0;
                        text-align: center;
                        .thumb {
                            position: relative;
                            width: 80px;
                            height: 80px;
                            margin: auto;
                            padding: 10px 0;
                            img {
                                width: 100%;
                                object-fit: contain;
                                height: 100%
                            }
                            img.assuredImg {
                                width: 50px !important;
                                position: absolute;
                                right: 5px;
                                z-index: 9;
                                top: 5px;
                                height: 40px !important
                            }
                        }
                        .content {
                            display: grid;
                            align-self: center;
                            flex: 1;
                            height: 100%;
                            .title {
                                color: $Dark;
                                font-size: 18px;
                                line-height: 30px;
                                font-weight: 600;
                                margin: 0 0 8px 0;
                                a {
                                    color: inherit;
                                    transition: all 0.3s ease-in;
                                    text-decoration: none
                                }
                            }
                            .common-price-style {
                                color: $Dark;
                                font-size: 16px;
                                line-height: 26px;
                                font-weight: 400;
                                padding-top: 10px
                            }
                        }
                    }
                }
            }
        }
    }
}
.note-content {
    background: #d7cad2;
    padding: 10px;
    position: relative;
    margin-bottom: 10px;
    label {
        font-weight: 600
    }
    p {
        margin: 0;
        line-height: 1.2;
        font-size: 12px
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 20px;
        width: 0;
        height: 0;
        border-width: 10px 10px 0 0;
        border-style: solid;
        border-color: #d8cad2 transparent
    }
}
ul.notes li {
    margin: 0 0 20px;
    p.note-meta abbr {
        border-bottom: 1px dotted $DarkGray;
        font-size: 11px;
        color: $DarkGray
    }
    p.note-status {
        float: right;
        margin-top: -36px;
        font-size: 12px
    }
}
@import '../../../../assets/scss/vars';
.home-slider.slider-items {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;
    z-index: 0;
    background-image: url('../../../../assets/images/bg-01.png');
    width: 100%;
    display: inline-block;
    position: relative;
    .box-banner {
        margin-top: 80px;
        width: 100%;
        height: calc(100vh - 80px);
        @media (max-width: 1400px) {
            height: 50vw;
            margin-top: 80px
        }
        @media (max-width: 991px) {
            margin-top: 80px
        }
    }
    .banner-box {
        margin-top: 80px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-height: calc(100vh - 80px);
        @media (max-width: 1400px) {
            height: 50vw;
            margin-top: 80px
        }
        @media (max-width: 991px) {
            margin-top: 80px
        }
    }
    @media (max-width: 1400px) {
        height: calc(50vw + 80px);
        .slick-slide {
            height: calc(50vw + 80px)
        }
        .slick-arrow {
            top: calc(50% + 40px)
        }
    }
    @media (max-width: 991px) {
        height: calc(50vw + 80px);
        .slick-slide {
            height: calc(50vw + 80px)
        }
        .slick-arrow {
            top: calc(50% + 60px)
        }
    }
    .slider-items-wrap {
        padding-top: 130px;
        height: 100%
    }
    .slider-inner {
        height: calc(100vh - 80px);
        width: 100%;
        display: flex;
        p {
            color: $White;
            font-size: 18px;   
            margin: 20px 0 30px 0;
            max-height: 85px;
            overflow: hidden
        }
        .slider-img {
            width: 30%;
            display: flex;
            height: 100%;
            align-items: center;
            @media (max-width: 768px) {
                display: none
            }
        }
        .slider-inner-box {
            clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
            background: $Dark;
            display: flex;
            position: relative;
            width: 70%;
            @media (max-width: 768px) {
                width: 100%;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)
            }
        }
    } 
    .slider-content {
        width: 70%;
        color: $White;
        align-items: center;
        display: flex;
        padding-left: 70px;
        @media (max-width: 768px) {
            padding: 20px;
            width: 100%
        }
        .auto-typing-header {
            margin: 0 auto 15px;
            .typewrite {
                font-size: 16px;
                line-height: 28px;
                font-weight: 700;
                letter-spacing: 10px;
                margin: 0;
                padding: 0;
                display: inline;
                color: transparent;
                position: relative;
                &::before {
                    content: attr(data-text);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    white-space: nowrap;
                    color: $White;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    animation: type 10s infinite
                }
            }
        }
        .slider-content-inner {
            width: 100%;
            .slider-paragraph {
                font-size: 14px;
                line-height: 30px;
                font-weight: 400;
                width: 75%;
                @media (max-width: 768px) {
                    display: none
                }
            }
            .title {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    -webkit-transform: translateX(-150%);
                    -ms-transform: translateX(-150%);
                    transform: translateX(-150%);
                    z-index: 1;
                    width: 20px;
                    height: 1px;
                    background-color: $White
                }
            }
            .slider-title {
                .heading-01 {
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 3vw;
                    font-weight: 400;
                    line-height: 3.75vw;
                    margin-bottom: 0;
                    color: $White;
                    @media (max-width: 768px)
                    {
                        line-height: normal;
                        font-size: 22px;
                        white-space: normal;
                    }
                    .bold{
                        display: block;
                        font-weight: 500;
                        }
                    }
                    .slider-title-bold .heading-01 {
                        font-size: 65px;
                        line-height: 70px;
                    }
                }
            .main-btn-wrap {
                @media (max-width: 768px) {
                    text-align: center;
                    margin: auto;
                    display: flex;
                    justify-content: center
                }
                .main-btn {
                    text-transform: uppercase
                }
            } 
        
        }
    }
    .jumbo-container {
        height: 100%
    }
    .title {
        color: $White !important
    }
    .right-cut-box {
        position: absolute;
        background: $Primary;
        width: 500px;
        height: 500px;
        bottom: calc(50% - 250px);
        right: 300px;
        transform: rotate(-38deg);
        z-index: -1
    }
    .image-cover-box {
        width: 400px;
        height: 400px;
        background: $AshGray;
        padding: 20px;
        img {
            width: 100%;
            height: 100%
        }
    }
    .slider-title {
        margin: 30px 0;
        @media (max-width: 768px) {
            text-align: center
        }
    }
    .mobile-image-home-banner-box {
        display: none !important;
        @media (max-width: 768px) {
            display: block !important;
            width: 100%;
            max-width: 400px !important;
            max-height: 400px;
            margin: auto
        }
    }
    .mobile-image-home-banner {
        @media (max-width: 768px) {
            width: 100%;
            max-width: 400px !important;
            max-height: 400px
        }
    }
    .view-top-counterup {
        width: 100%;
        margin: auto;
        margin-top: 160px;
        box-shadow: 0 8px 12px 0 rgba(24, 24, 24, 0.2);
        transform: translateY(-80px);
        background-color: $White;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;
            left: -2.5%;
            right: 0;
            top: -2%;
            bottom: -35%;
            margin: auto;
            width: 1000px;
            height: 1000px;
            line-height: 1000px;
            border: 3px solid rgba(24, 24, 24, 0.1);
            border-radius: 50%;
            display: inline-block;
            z-index: -1;
            animation: pauseanimate 2s 2s linear infinite
        }
        .counterup-wrap {
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            min-height: calc(100vh - 80px);
            display: flex;
            align-items: center;
            background-image: url('../../../../assets/images/banner-1.jpg');
            background-color: $Dark;
            @media (max-width: 768px) {
                min-width: 50vw;
                min-height: 50vw;
                display: flex;
                justify-content: space-between;
                padding: 0 20px;
                p {
                    color: $White;
                    font-size: 12px;
                    margin-bottom: 5px
                }
            }
            .spinlines-first {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 450px;
                height: 450px;
                line-height: 450px;
                border: 3px solid rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                display: inline-block;
                z-index: 1;
                animation: pauseanimate 2s linear infinite;
                @media (max-width: 768px) {
                    width: 50vw;
                    height: 50vw;
                    display: none
                }
                p {
                    position: absolute;
                    color: $White
                }
                .name1 {
                    left: 0;
                    top: -20%
                }
                .name2 {
                    right: 0;
                    top: -20%
                }
                .name3 {
                    left: 45%;
                    top: -50%
                }
                .name4 {
                    right: -10%;
                    top: 5%
                }
                .name5 {
                    left: -10%;
                    top: 5%
                }
                .name6 {
                    right: 0;
                    top: 30%
                }
                .name7 {
                    left: 0%;
                    top: 30%
                }
                .name8 {
                    left: 45%;
                    top: 50%
                }
            }
            .spinlines-second {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 650px;
                height: 650px;
                line-height: 650px;
                border: 3px solid rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                display: inline-block;
                z-index: 1;
                animation: pauseanimate 2s linear infinite;
                @media (max-width: 768px) {
                    width: 50vw;
                    height: 50vw;
                    display: none
                }
                p {
                    color: $White;
                    position: absolute
                }
                .name9 {
                    right: 0;
                    top: -25%
                }
                .name10 {
                    left: 0;
                    top: -25%
                }
                .name11 {
                    right: -10%;
                    top: 3%
                }
                .name12 {
                    left: -10%;
                    top: 3%
                }
                .name13 {
                    right: 0;
                    top: 30%
                }
                .name14 {
                    left: 0;
                    top: 30%
                }
                .name15 {
                    right: 0;
                    top: 30%
                }
                .name16 {
                    left: 20%;
                    top: 45%
                }
                .name17 {
                    right: 20%;
                    top: 45%
                }
            }
            .wrapper {
                flex-wrap: wrap
            }
            .wave-box-first-mobile {
                display: none;
                @media (max-width: 768px) {
                    display: block
                }
            }
        }
       
        .one {
            display: flex;
            padding-left: 10%
        }
        .two {
            display: flex;
            -ms-flex-pack: end;
            padding-right: 5%
        }
        .three {
            padding-top: 80px;
            display: flex;
            padding-left: 20%
        }
        .four {
            padding-top: 80px;
            display: flex;
            -ms-flex-pack: end;
            padding-right: 10%
        }
        .single-couterup {
            text-align: center;
            .content {
                .count-wrap {
                    color: $Dark;
                    display: flex;
                    align-items: center;
                    font-size: 55px;
                    line-height: 65px;
                    font-weight: 700;
                    padding: 15px 0;
                    justify-content: center
                }
                .title {
                    color: $Primary;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 500
                }
            }
        }
    }
}
.home-banner-box {
    display: flex;
    max-height: 500px;
    padding: 20px 20px 0;
    box-shadow: 0 0.25rem 0.3125rem -0.15rem #0000001f;
    margin-top: -40px;
    @media (max-width: 768px){
        max-height: 100%;
        flex-direction: column;
    }
    .product-slider {
        flex: 2;
        margin-right: 20px;
        border-radius: 4px;
        max-width: calc(100% -  400px);
        box-shadow: 0 0 3px $ShadowColor;
        @media (max-width: 768px){
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
        img {
            width: 100%;
            max-height: 300px;
            margin: auto;
        }
        .slick-dots {
            bottom: 20px;
            right: 25%;
            left: 0;
            width: 75%;
            @media (max-width: 768px){
                bottom: 0px;
                right: 0px;
            }
            li {
                &.slick-active {
                    button {
                        &::before {
                            font-size: 6px;
                            width: 30px;
                            content: '';
                            height: 5px;
                            align-self: center;
                            background-color: $Dark;
                            margin-top: 7px;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .banner-box {
            max-height: 380px;
            display: flex !important;
            .left-banner-box {
                background-image: url('../../../../assets/images/BG-designe-01.png');
                background-color: $Primary;
                background-position: center;
                background-size: contain;
                height: 380px;
                width: 75%;
                clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                .product-content {
                    text-align: center;
                    padding: 0 20px;
                    h4 {
                        color: $White;
                        font-size: 26px;
                        line-height: 1.5;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 300;
                        text-transform: capitalize;
                    }
                    p {
                        border: 2px solid $White;
                        padding: 5px 30px;
                        margin-bottom: 0;
                        line-height: 40px;
                        font-family: 'Montserrat', sans-serif;
                        display: inline-block;
                        margin: auto;
                        color: $White;
                        font-size: 36px;
                        margin: 20px 0
                    }
                }
            }
            .right-banner-box {
                background-image: url('../../../../assets/images/weg-01.png');
                background-color: $White;
                background-position: center;
                background-size: contain;
                height: 380px;
                max-width: 380px;
                width: auto;
                justify-content: center;
                display: flex;
            }
        }
    }
    .coupon-slider {
        flex: 1;
        margin: auto;
        max-width: 380px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }
    }
}
.login-page {
    display: flex;
    height: 100%;
    p {
        color: $AshGray
    }
    .logo-right-banner {
        width: 150px;
        margin: auto
    }
    .logo-left-banner {
        width: 200px;
        margin: auto;
        display: block
    }
    .left-box-login {
        flex: 2;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 100vh;
        background-position: center;
        position: relative;
        &.left-box-login-login {
            background-image: url('../images/screen-3.png');
            margin: 0 40px
        }
        &.left-box-login-otp {
            background-image: url('../images/server-1-min.jpg');
            background-color: $BorderColor
        }
        &.left-box-login-register {
            background-image: url('../images/server-2-min.jpg');
            background-color: $BorderColor
        }
        @media (max-width: 768px) {
            display: none
        }
    }
    .body-background {
        flex: 1;
        min-width: 500px;   
        background-color: $BackGray;
        @media (max-width: 768px) {
            min-width: 100%
        }
    }
    .welcomText {
        font-size: 32px;
        line-height: 32px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 30px;
        color: $Dark;
        text-align: center
    }
    .logo-login {
        width: 200px;
        display: block;
        height: 100px;
        margin: auto
    }
    .social-media-box {
        text-align: center;
        ul {
            li {
                display: inline-block;
                width: 180px;
                cursor: pointer;
                margin: 8px;
                img {
                    font-size: 24px
                }
                &.fb-logo, &.google-logo {
                    button {
                        border: none;
                        color:$White
                    }
                }
            }
        }
    }
}
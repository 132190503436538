@import '../../../../assets/scss/vars';
.storeLocator-page {
    .storeLocator-single-item {
        margin-bottom: 30px;
        background-color: $Cream;
        padding: 25px;
        border-radius: 12px;
        box-shadow: 0 0 5px $ShadowColor;
        @media (min-width: 769px) {
            display: flex
        }
        .thumb {
            position: relative;
            transition: all 1s;
            height: 240px;
            border-radius: 8px;
            width: 100%;
            background-size: cover;
            background-position: center;
            @media (min-width: 769px) {
                max-width: 240px
            }
        }
        .store-content {
            flex: 1;
            .store-info {
                @media (min-width: 992px) {
                    display: flex
                }
                .content {
                    height: 100%;
                    display: grid;
                    flex: 1;
                    .title {
                        font-size: 30px;
                        line-height: 45px;
                        margin: 0 0 8px 0;
                        font-weight: 600
                    }
                    p {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 0
                    }
                }
                .map {
                    width: 100%;
                    @media (max-width: 991px) {
                        padding-left: 20px;
                    }
                    @media (min-width: 992px) {
                        max-width: 300px
                    }
                }
            }
        }
        .info-box {
            @media (min-width: 769px) {
                display: flex;
                .mobile {
                    margin-left: 40px
                }
            }
            .address {
                max-width: 300px;
            }
            i {
                margin-bottom: auto;
                margin-top: 4px;
                margin-right: 5px
            }
        }
    }
}
.alert-box {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0
}
.alert {
    color: $White;
    max-width: 320px;
    box-shadow: 0 1px 3px $DarkGray;
    width: calc(100vw - 40px);
    margin: 20px 20px 0;
    display: flex;
    min-height: 40px;
    &.alert-custom{
        display: block;
        background-color: $White;
        .service-box {
            display: flex;
            width: 100%;
            color: $Dark;
            margin: 0;
            img.service-image {
                max-width: 80px;
                height: auto;
                margin: 10px 0 10px 20px
            }
            .service-content {
                width: calc(100% - 100px);
                padding-left: 20px;
                p {
                    width: 100%;
                    padding-left: 0
                }
            }
        }
    }
    p {
        font-size: 12px;
        line-height: 24px;
        margin: 0;
        padding: 8px 20px 8px 10px;
        width: calc(100% - 40px);
        margin-left: auto
    }
    &.alert-success {
        background-color: $Success
    }
    &.alert-error {
        background-color: $Error
    }
    &.alert-info {
        background-color: $Info
    }
    i {
        color: $White;
        width: 24px;
        height: 24px;
        padding: 8px
    }
    .product-box {
        background: $White;
        width: 100%;
        display: flex;
        padding: 10px;
        img {
            width: 80px;
            height: 80px;
            margin: auto 0
        }
        .product-content {
            color: $Dark;
            margin-left: 20px;
            .product-title {
                font-size: 20px;
                width: 100%
            }
            .product-price {
                width: 100%
            }
        }
    }
    .alert-msg {
        background: $Success;
        display: flex;
        padding: 0px 10px
    }
}
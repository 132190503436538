.complaint-page {
    @media (max-width: 768px) {
        width: 100%
    }
    .right-form-section {
        margin: auto;
        width: 100% !important;
        input {
            width: 400px;
            display: block;
            @media (max-width: 768px) {
                width: 100%
            }
        }
        textarea {
            width: 100%
        }
    }
}
@import '../../../../assets/scss/vars';
.game-row {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(10, 1fr);
    .game-column {
        background-color: $White;
        position: relative;
        .box-number {
            position: absolute;
            right: 2px;
            bottom: 2px
        }
    }
}
@media (orientation: landscape) {
    .game-column {
        width: 9vh;
        height: 9vh;
        span {
            font-size: 1.5vh;
            line-height: 1.6vh
        }
    }
}
  
@media (orientation: portrait) {
    .game-column {
        width: 9vw;
        height: 9vw;
        span {
            font-size: 1.5vw;
            line-height: 1.6vw
        }
    }
}
@import '../../../../assets/scss/vars';
.slider-items {
    min-height: 400px;
    background-size: cover;
    background-position: center;
    background-image: url('https://cdn.programmerindia.org/upload/top-banner.jpg');
    display: flex;
    box-shadow: inset 0 100px 500px rgba(0,0,0.5);
    .form-slider-box {
        max-width: 300px;
        margin-bottom: 40px
    }
    .slider-box {
        color: $White;
        margin-left: 40px;
        max-width: 400px;
        margin-right: auto;
        h1 {
            font-size: 42px;
            line-height: 48px;
            margin-top: 80px;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 20px
        }
        p {
            font-size: 18px;
            font-weight: 500
        }
        ul.slick-dots {
            bottom: -60px;
            text-align: left;
            li {
                height: 4px;
                background-color: $White;
                opacity: 0.25;
                &.slick-active {
                    opacity: 1;
                }
            }
        }
        
    }
}
#menu-cart {
    .cart-title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 20px;
        .title {
            width: 50%;
            padding: 0;
            text-align: left;
            .heading-03 {
                margin-bottom: 0;
                color: $White;
                font-size: 36px;
                line-height: 46px;
                font-weight: 600
            }
        }
        .closebtn {
            color: $Primary;
            font-size: 40px;
            width: 50px;
            text-align: center;
            height: 50px;
            cursor: pointer
        }
    }
    .calculate-checkout {
        background-color: $White;
        height: 100%;
        .cart-single-item {
            display: flex;
            position: relative;
            padding: 20px;
            border-bottom: 1px solid $BorderColor;
            &.subtotal {
                background-color: $OffWhite;
                padding: 10px 20px;
                .item-left {
                    width: 50%;
                    padding: 0;
                    .heading-05 {
                        margin-bottom: 0;
                        text-align: left;
                        color: $Dark;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }
                .item-right {
                    width: 50%;
                    -webkit-box-pack: end;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    &.amount {
                        color: $Primary;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }
            }
          
        }
    }
    .cart-content {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        background-color: $White;
        height: 100%;
        .cart-single-item {
            display: flex;
            position: relative;
            padding: 20px;
            border-bottom: 1px solid $BorderColor;
            &.subtotal {
                background-color: $OffWhite;
                padding: 10px 20px;
                .item-left {
                    width: 50%;
                    padding: 0;
                    .heading-05 {
                        margin-bottom: 0;
                        text-align: left;
                        color: $Dark;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }
                .item-right {
                    width: 50%;
                    justify-content: flex-end;
                    &.amount {
                        color: $Primary;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }
            }
            .item-left {
                width: 25%;
                background-color: $OffWhite;
                padding: 5px;
                margin: 0 auto auto
            }
            .item-right {
                width: 70%;
                display: flex;
                .item-text {
                    width: 100%;
                    text-align: left;
                    padding-left: 10px;
                    .heading-05 {
                        color: $Dark;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                    .price {
                        color: $Dark;
                        font-size: 14px;
                        line-height: 26px;
                        span {
                            min-width: 72px;
                            display: inline-block
                        }
                    }
                }
            }
            .cart-item-close-btn {
                width: 5%;
                text-align: right;
                cursor: pointer;
                font-size: 30px;
                text-align: center
            }
        }
    }
}
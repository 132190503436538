@import '../../../../assets/scss/vars';
.footer {
    .widget_nav_menu{
        ul {
            @media (max-width: 768px) {
                display: none
            }
        }
        &.active {
            ul {
                @media (max-width: 768px) {
                    display: block
                }
            }
            .widget-title {
                &::after {
                    transform: rotate(270deg)
                }
            }
        }
        .widget-title {
            @media (max-width: 768px) {
                cursor: pointer;
                &::after {
                    position: absolute;
                    right: 10px;
                    content: "\e91e";
                    font-family: 'haticons';
                }
                &:last-child() {
                    &::after {
                        display: none
                    }
                }
            }
        }
    }
    .cityModal {
        background-color: $NavTextColor;
        padding: 10px;
        max-height: 50px;
        position: relative;
        border-radius: 8px;
        cursor: pointer;
        width: 100px;
        align-self: center;
        margin-left: 20px;
        li {
            display: flex;
            position: relative;
            justify-content: space-around;
            img {
                border-radius: 50%;
                width: 30px;
                height: 30px
            }
            span {
                padding-left: 20px;
                color: $Dark
            }
        }
        .changeCity-box {
            background: $White;
            padding: 3px;
            max-height: 40px;
            border-radius: 4px;
            line-height: 22px;
            cursor: pointer;
            width: 80px;
            align-self: center;
            top: 12px;
            color: $Black;
            text-align: center;
            left: 80px;
            position: absolute;
            z-index: 999;
            overflow: visible;
            &::after {
                top: 4px;
                content: '';
                position: absolute;
                left: -16px;
                z-index: 9;
                border: 10px solid transparent;
                border-right: 10px solid $White
            }
        }
    }
    .cityModalOptionBox {
        background-color: $White;
        width: 150px;
        overflow: visible;
        z-index: 99;
        position: absolute;
        left: 185px;
        top: 60px;
        li {
            display: flex;
            position: relative;
            padding: 10px !important;
            border-bottom: 1px solid $Black;
            align-items: center;
            margin: 0 !important;
            img {
                border-radius: 50%;
                width: 30px;
                height: 30px
            }
            span {
                padding-left: 20px;
                color: $Dark !important
            }
        }
    }
    #back-to-top {
        width: 60px;
        display: none;
        position: fixed;
        bottom: 20px;
        right: 10px;
        z-index: 99;
        border: 0;
        outline: none;
        cursor: pointer;
        background-color: transparent
    }
    .footer-top {
        border-bottom: 1px solid $BorderColor
    }
    .jumbo-container {
        .footer-box-wrapper {
            display: flex;
            .col-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                position: relative
            }
            .col-2 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%
            }
            .col-3 {
                flex: 0 0 25%;
                max-width: 25%
            }
            .footer-widget {
                .footer-logo img {
                    max-width: 150px !important;
                    margin-bottom: 10px
                }
                p {
                    font-size: 12px;
                    color: $NavTextColor
                }
                .banner-header-follow-us {
                    display: flex;
                    align-items: center;
                    .banner-header-icon {
                        ul {
                            display: flex;
                            li {
                                margin: 0;
                                a {
                                    padding: 5px 0 5px 20px
                                }
                            }
                        }
                    }
                }
                .widget-title {
                    font-weight: 600;
                    color: $NavTextColor;
                    line-height: 0;
                    margin: 0 0 20px 0;
                    position: relative
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        font-size: 12px;
                        font-weight: 400;
                        display: flex;
                        color: $NavTextColor;
                        margin: 10px 0;
                        i.icon {
                            padding-right: 10px;
                            vertical-align: top
                        }
                        a {
                            color: $NavTextColor;
                            text-decoration: none;
                            i {
                                color: $White;
                                font-size: 14px;
                                vertical-align: sub;
                                background-color: $NavTextColor;
                                width: 28px;
                                height: 28px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 16px;
                                &:hover {
                                    background-color: $Primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
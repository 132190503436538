@import '../../../assets/scss/vars';
.badge {
    display: inline-flex;
    user-select: none;
    align-items: center;
    padding: 2px 7px 2px 2px;
    border-radius: 8px;
    font-size: 12px;
    color: $White !important;
    height: 22px;
    box-shadow: 0 1px 2px $ShadowColor;
    i {
        background-color: $White;
        margin-right: 7px;
        border-radius: 6px;
        padding: 3px
    }
    span {
        display: inline-block;
        white-space: nowrap
    }
    &.resolved, &.approved, &.success, &.publish, &.paid, &.delivered, &.collected, &.active {
        background-color: $Success;
        i {
            color: $Success !important
        }
    }
    &.info, &.assigned, &.private, &.initialised {
        background-color: $Info;
        i {
            color: $Info !important
        }
    }
    &.given, &.draft {
        background-color: $DisabledColor;
        i {
            color: $DisabledColor !important
        }
    }
    &.completed, &.settled {
        background-color: $Primary;
        i {
            color: $Primary !important
        }
    }
    &.shipped, &.all {
        background-color: $PrimaryLight;
        i {
            color: $PrimaryLight !important
        }
    }
    &.error, &.expired, &.denied, &.fail, &.payment-fail, &.cancelled, &.rejected {
        background-color: $Error;
        i {
            color: $Error !important
        }
    }
    &.raised, &.refund, &.deactivate, &.inactive {
        background-color: $Warning;
        i {
            color: $Warning !important
        }
    }
    &.processing, &.paidAtFranchise {
        background-color: $Progress;
        i {
            color: $Progress !important
        }
    }
}
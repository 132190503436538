body {
    &.closed {
        #root.admin {
            .sidebar {
                width: 60px;
                nav.sidebar-nav {
                    ul.nav {
                        li {
                            a, button {
                                margin-left: 15px;
                                i {
                                    margin-right: 20px;
                                    &:last-child {
                                        display: none
                                    }
                                }
                            }
                            a {
                                span {
                                    display: none
                                }
                            }
                        }
                    }
                }
            }
            .container {
                width: calc(100vw - 60px)
            }
        }
    }
    @media (max-width: 768px) {
        #root.admin {
            .sidebar {
                text-align: left;
                margin-left: -240px
            }
            .container {
                width: 100%
            }
        }
        &.closed {
            #root.admin {
                .sidebar {
                    text-align: left;
                    margin-left: 0px;
                    width: 240px;
                    nav.sidebar-nav {
                        ul.nav {
                            li {
                                a {
                                    span {
                                        display: inline-block
                                    }
                                }
                            }
                        }
                    }
                }
                .container {
                    width: calc(100vw - 240px)
                }
            }
        }
    }
}
#root {
    &.admin {
        height: 100%;
        width: 100%;
        display: flex;
        div#preventive-checklist-modal {
            table {
                border-collapse: collapse;
                color: $Gray;
                margin: 0 -1px 24px 0;
                text-align: left;
                width: 100%;
                border: 0;
                border-radius: 0px;
                box-shadow: none;
                tbody tr td {
                    height: 40px
                }
                th, td {
                    border: 1px solid $BorderColor !important;
               }
               tr.query-list-status th {
                   background-color: $OffWhite;
                   padding: 7px 15px !important;
                   font-weight: bold
               }
               td.query-list-icon-Box {
                   background: transparent !important
               }
            }
            h3 {
                font-weight: 600
            }
        }
        .order-type-box {
            position: relative !important;
            padding-left: 30px !important;
            .order-type {
                position: relative;
                background-color: $Primary;
                position: absolute;
                left: 0;
                top: 1px;
                bottom: 1px;
                span {
                    display: block;
                    i {
                        vertical-align: middle;
                        color: $White;
                        font-size: 14px !important;
                        cursor: pointer;
                        margin: 2px;
                    }
                }
                .hammer-outline {
                    &:hover {
                        >.hover-action {
                            display: block
                        }
                    }
                }
                .cube-outline {
                    &:hover {
                        >.hover-action {
                            display: block
                        }
                    }
                }
                .ribbon-outline {
                    &:hover {
                        >.hover-action {
                            display: block
                        }
                    }
                }
                .list-circle-outline {
                    &:hover {
                        >.hover-action {
                            display: block
                        }
                    }
                }
                .hover-action {
                    display: none;
                    position: absolute;
                    background: $Black;
                    color: $White;
                    width: 100%;
                    border-radius: 20px;
                    padding: 2px 10px;
                    z-index: 9;
                    top: 10px;
                    width: 150px;
                    text-align: center;
                    left: 112px
                }
            }
        }
        .form-group {
            position: relative;
            margin-bottom: 10px;
            label {
                color: $Gray;
                font-weight: 500
            }
        }
        .service-package-icon {
            font-size: 40px;
            color: $BorderColor;
            padding: 10px;
            display: inline-block;
            margin: 10px;
            background-color: $OffWhite;
            padding: 10px;
            border: 5px solid $BorderColor
        }
        sup {
            color: $Primary;
            font-size: 22px;
            line-height: 0;
            vertical-align: middle;
            margin-left: 0
        }
        .container {
            transition: all 0.3s ease-in-out;
            width: calc(100vw - 240px);
            max-width: 100vw;
            display: flex;
            flex-direction: column;
            height: 100%;
            margin: auto;
            padding: 0;
            .header {
                height: 80px;
                background-color: $HeaderColor;
                display: flex;
                z-index: 9999;
                .franchise-title {
                    display: block;
                    margin: auto auto auto 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    background-color: $White;
                    color: $Primary;
                    padding: 0 7px;
                    border-radius: 8px;
                    border: 4px double $Primary
                }
                .cache-btn, .theme-btn, .nav-btn {
                    background-color: transparent;
                    border: 0;
                    padding: 9px;
                    min-width: 50px;
                    text-align: center;
                    height: 50px;
                    line-height: 0;
                    margin: 15px 5px;
                    transition: all 0.3s ease-in-out;
                    i {
                        color: $AshGray;
                        font-size: 24px;
                        margin-left: 0
                    }
                    &:hover {
                        background-color: $AshGray;
                        border-radius: 50%;
                        i {
                            color: $White
                        }
                    }
                }
                nav {
                    position: relative;
                    margin-left: auto;
                    @media (min-width: 1025px) {
                        width: 160px;
                    }
                    .profile-box {
                        user-select: none;
                        cursor: pointer;
                        align-items: center;
                        background-color: rgba(255, 255, 255, 0.25);
                        display: flex;
                        height: 80px;
                        padding: 0 20px 0 0;
                        img {
                            width: 40px;
                            height: 40px
                        }
                        i {
                            font-size: 24px;
                            color: $Dark;
                            height: 50px;
                            margin: 15px 10px 15px 0;
                            line-height: 50px
                        }
                        span {
                            font-size: 16px;
                            color: $Dark;
                            height: 50px;
                            margin: 15px 0;
                            line-height: 50px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis
                        }
                        &:hover {
                            span {
                                color: $Primary
                            }
                        }
                    }
                    ul {
                        background-color: $White;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        overflow: hidden;
                        position: absolute;
                        box-shadow: 0 0 5px $ShadowColor;
                        li {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            > a, > i {
                                color: $Primary;
                                font-style: normal;
                                cursor: pointer;
                                display: flex;
                                line-height: 40px;
                                padding: 0 15px;
                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.25)
                                }
                                span {
                                    display: block;
                                    font-size: 14px
                                }
                                i {
                                    font-size: 14px;
                                    margin: 12px 10px 0 0;
                                    display: block
                                }
                            }
                        }
                    }
                }
            }
            .content {
                height: calc(100vh - 130px);
                overflow: auto;
                background-color: $Cream;
                padding: 10px 15px 0;
                h1, h2, h3, h4, h5, h6 {
                    font-weight: 400;
                    display: block;
                    position: relative
                }
                h1 {
                    &.title {
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 32px;
                        overflow: visible;
                        text-transform: capitalize;
                        > a, > button {
                            margin-left: 20px
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 50px;
                            bottom: -8px;
                            height: 2px;
                            background-color: $Primary
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 40px;
                            bottom: -15px;
                            height: 2px;
                            background-color: $AshGray
                        }
                    }
                }
                ul.media-box {
                    grid-gap: 20px;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    &.banner-box{
                        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))
                    }
                    li {
                        background-color: $LightGray;
                        display: inline-flex;
                        padding: 10px;
                        position: relative;
                        max-width: 300px;
                        flex-direction: column;
                        p {
                            text-align: center;
                            padding: 0;
                            margin: auto -10px -10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            background-color: $White
                        }
                        img {
                            margin: auto;
                            display: block;
                            box-shadow: 0 1px 2px $Dark;
                            max-height: 240px
                        }
                        &.selected {
                            border: 3px solid $Success;
                            padding: 7px
                        }
                        i {
                            position: absolute;
                            width: 24px;
                            line-height: 24px;
                            opacity: 0;
                            transition: all 0.3s ease-in-out;
                            cursor: pointer;
                            font-size: 24px;
                            &.trash {
                                color: $Error;
                                right: 10px
                            }
                            &.setting {
                                color: $AshGray;
                                left: 10px
                            }
                            &.select {
                                color: $Success;
                                left: 10px
                            }
                            &.preview {
                                color: $White;
                                left: 10px
                            }
                        }
                        &:hover {
                            i {
                                opacity: 1
                            }
                        }
                        picture.picture {
                            padding: 5px
                        }
                        img {
                            display: block;
                            width: auto
                        }
                    }
                    &.list {
                        img, video {
                            &:not(.zoomed) {
                                max-width: 80px !important;
                                max-height: 80px !important
                            }
                        }
                    }
                }
                .grid-area {
                    grid-gap: 20px;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
                    .section {
                        position: relative;
                        padding: 20px;
                        background-color: $White;
                        border-radius: 8px;
                        height: max-content;
                        h4.head {
                            margin: -20px -20px 20px;
                            border-bottom: 1px solid $LightGray;
                            padding: 0 40px 0 20px;
                            position: relative;
                            font-size: 16px;
                            line-height: 44px;
                            font-weight: 300;
                            cursor: pointer;
                            i {
                                position: absolute;
                                right: 0;
                                width: 24px;
                                height: 44px;
                                font-size: 10px;
                                color: $DarkGray;
                                margin: 0 9px
                            }
                        }
                        .body {
                            overflow: hidden;
                            margin: -20px;
                            padding: 20px;
                            transition: all 0.3s ease-in-out
                        }
                        &.closed {
                            .body {
                                max-height: 0;
                                padding: 0 20px
                            }
                        }
                    }
                }
                .order-frame {
                    .inside-box {
                        width: calc(50% - 10px);
                        background-color: $White;
                        border-radius: 8px;
                        height: max-content;
                        margin: 20px 0;
                        @media (max-width: 1024px) {
                            width: 100%
                        }
                        .inside {
                            li {
                                padding: 20px;
                                border-right: 1px solid $BorderColor;
                                border-bottom: 1px solid $BorderColor;
                                color: $Dark;
                                cursor: pointer;
                                @media (max-width: 500px) {
                                    width: 100%;
                                    border-bottom: 1px solid $BorderColor;
                                    border-right: none;
                                    &:nth-child(3) {
                                        border-bottom: 1px solid $BorderColor !important
                                    }
                                }
                                &:last-child {
                                    border-bottom: none;
                                    border-right: none
                                }
                                &:nth-child(3) {
                                    border-bottom: none
                                }
                                &:nth-child(2) {
                                    border-right: none
                                }
                                &:hover {
                                    a {
                                        color: $Primary
                                    }
                                    .name-title {
                                        strong{
                                            color: $Primary
                                        }
                                    }
                                }
                                .icon-box{
                                    flex: 1
                                }
                                i {
                                    align-self: center;
                                    font-size: 40px
                                }
                                .name-title {
                                    flex: 3;
                                    strong {
                                        font-size: 20px;
                                        line-height: 1.2em;
                                        font-weight: 400;
                                        display: block;
                                        color: $Dark
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .footer {
                height: 50px;
                white-space: nowrap;
                overflow: hidden;
                line-height: 50px;
                padding: 0 15px
            }
        }
        .flex-box {
            display: flex;
            flex: 1
        }
        .form-box {
            flex: 1;
            margin: auto;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 20px;
            padding: 30px;
            max-width: 300px;
            box-shadow: 0 1px 4px $AshGray
        }
        ::placeholder {
            color: $AshGray;
            font-family: $generic_font;
            font-weight: 400
        }
        img {
            max-width: 100%
        }
        a {
            text-decoration: none
        }
        table {
            width: 100%;
            border: 0;
            border-collapse: collapse;
            background-color: $White;
            margin-bottom: 20px;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0 1px 2px $ShadowColor;
            thead {
                text-align: left;
                tr th {
                    background-color: #d0d0ce;
                    padding: 7px 10px;
                    font-weight: 700
                }
            }
            tbody {
                tr td {
                    padding: 7px 10px;
                    font-weight: 400;
                    &.small-col {
                        height: 40px
                    }
                }
                tr:nth-child(odd) td,
                tr:nth-child(odd) th {
                    background-color: $White
                }
                tr:nth-child(even) td,
                tr:nth-child(even) th {
                    background-color: #d0d0ce
                }
            }
            tbody tr th,
            thead tr th {
                white-space: nowrap
            }
            tbody tr td,
            tbody tr th,
            thead tr td,
            thead tr th {
                padding: 10px 15px;
                border: 0;
                vertical-align: top;
                text-align: left;
                min-width: 121px;
                position: relative
            }
        }
        input[type=email],
        input[type=number],
        input[type=password],
        input[type=phone],
        input[type=text],
        select,
        textarea {
            @extend %input
        }
        textarea {
            resize: none;
            min-height: 200px;
            line-height: 24px;
            padding: 10px 20px
        }
        .chatbotqueries {
            p {
                font-weight: 300
            }
        }
        h1, h2, h3, h4, h5, h6 {
            font-weight: 400;
            text-transform: capitalize;
            display: block;
            position: relative;
            line-height: 48px;
            margin-bottom: 10px
        }
        .body-background {
            background-image: url('../../images/back-web.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh;
            width: 100%;
            overflow: auto
        }
        span.tox-statusbar__branding {
            display: none
        }
        .react-datepicker {
            width: 100%;
            height: 40px;
            border: 1px solid $BorderColor;
            .react-datepicker__wrapper {
                border: none;
                input {
                    display: inline-block;
                    width: auto !important;
                    padding: 0 10px !important;
                    text-align: center;
                    height: 30px;
                    margin-top: 5px;
                    border-radius: 0;
                    background-color: transparent
                }
            }
        }
        .sidebar {
            max-width: 240px;
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out;
            margin-left: 0;
            li.active > a {
                background: $Black;
                color: $White;
                position: relative
            }
            ul.sub-nav {
                > li.active > a {
                    &::after {
                        content: "\e920";
                        font-family: 'haticons' !important;
                        position: absolute;
                        right: 25px;
                        width: 20px;
                        font-size: 10px;
                        text-align: center;
                        line-height: 21px;
                        top: 10px
                    }
                }
            }
        }
        .shop-table_responsive {
            overflow: visible
        }
        .cart-form {
            .shop-table_responsive {
                border-collapse: collapse;
                color: $Gray;
                border: 1px solid $BorderColor;
                margin: 0 -1px 24px 0;
                text-align: left;
                width: 100%;
                border-radius: 5px;
              
                tr:last-child {
                    background-color: transparent
                }
                tr:nth-child(2n+1) {
                    background: $White
                }
                tr:nth-child(odd) {
                    background: $OffWhite
                }
                th,
                td {
                    padding: 12px;
                    border: 1px solid $BorderColor;
                    font-size: 16px;
                    font-weight: normal;
                    background-color: $OffWhite;
                    vertical-align: middle;
                    line-height: 1.5em;
                    text-align: right
                }
                .product-thumbnail {
                    min-width: 32px;
                    a {
                        img {
                            width: 100px;
                            box-shadow: none;
                            height: auto;
                            max-width: 100%
                        }
                    }
                }
                .product-quantity {
                    .quantity {
                        .screen-reader-text {
                            clip: rect(1px, 1px, 1px, 1px);
                            height: 1px;
                            overflow: hidden;
                            position: absolute !important;
                            width: 1px;
                            word-wrap: normal !important;
                            color: $Dark
                        }
                        .minus, .plus {
                            width: 40px;
                            border: 1px solid $BorderColor;
                            background: transparent;
                            cursor: pointer;
                            margin: 0;
                            vertical-align: middle;
                            line-height: 1;
                            padding: 14px
                        }
                        .qty {
                            width: 30px;
                            height: 18px;
                            appearance: textfield;
                            background: transparent;
                            border-top: 1px solid $BorderColor !important;
                            border-bottom: 1px solid $BorderColor !important;
                            box-sizing: content-box;
                            border: none;
                            padding: 13px 13px 10px;
                            line-height: normal;
                            text-align: center;
                            vertical-align: middle;
                            border-radius: 0;
                            display: inline-block
                        }
                    }
                }
                td.actions {
                    .coupon {
                        float: left;
                        label {
                            display: inline-block
                        }
                    }
                    .updatebtn {
                        cursor: pointer;
                        opacity: 1;
                        border-color: #F0F0F0;
                        background-color: #F0F0F0;
                        color: $BorderColor;
                        line-height: 1;
                        position: relative;
                        font-size: 14px;
                        font-weight: 600;
                        text-align: center;
                        cursor: pointer;
                        line-height: normal;
                        letter-spacing: 1px;
                        background: transparent;
                        color: $Primary;
                        border: 1px solid $BorderColor;
                        padding: 18px 24px 19px;
                        transition: all 0.3s ease 0s;
                        border-radius: 0
                    }
                }
            }
        }
        .may-be-interested {
            h2 {
                margin: 0 0 8px 0
            }
        }
        .calculate-cart-feild {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-wrap: nowrap;
            .box-header {
                font-size: 22px;
                line-height: 34px;
                font-weight: 400;
                text-transform: uppercase;
                &::after {
                    display: block;
                    content: '';
                    background: $Primary;
                    width: 40px;
                    height: 2px;
                    margin-top: 13px
                }
            }
            .cart-total {
                margin-left: 20px;
                table {
                    td,
                    th {
                        padding: 12px;
                        vertical-align: middle;
                        font-size: 16px;
                        font-weight: normal;
                        background-color: $OffWhite;
                        border-left: 0;
                        border-right: 0;
                        border: 1px solid $BorderColor;
                        line-height: 1.5em;
                        width: 35%;
                        height: 50px
                    }
                }
                .page-margin-top {
                    margin-top: 50px
                }
                a.checkout-button {
                    float: right;
                    text-transform: uppercase;
                    margin: 0;
                    position: relative;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                    cursor: pointer;
                    line-height: normal;
                    letter-spacing: 1px;
                    background: transparent;
                    color: $Primary;
                    border: 1px solid $BorderColor;
                    padding: 18px 24px 19px;
                    transition: all 0.3s ease 0s;
                    border-radius: 0
                }
            }
            .shop-table {
                border-collapse: collapse;
                color: $Gray;
                border: 1px solid $BorderColor;
                margin: 0 -1px 24px 0;
                text-align: left;
                width: 100%;
                border-radius: 5px;
                .shipping-totals {
                    p {
                        line-height: 26px;
                        margin-top: 24px;
                        padding: 0;
                        text-align: right
                    }
                    .shipping-methods {
                        text-align: left;
                        list-style: none outside;
                        margin: 0;
                        padding: 0;
                        li {
                            margin: 0 0 .5em;
                            line-height: 1.5em;
                            list-style: none outside;
                            input {
                                margin: 3px .4375em 0 0;
                                vertical-align: top
                            }
                            label {
                                display: inline;
                                color: $Dark;
                                .amount {
                                    font-weight: 700
                                }
                            }
                        }
                    }
                }
                td {
                    padding: 12px;
                    border: 1px solid $BorderColor;
                    position: relative
                }
            }
            .cart-total {
                width: 50%
            }
        }
        tr:nth-child(2n+1) {
            background: $White
        }
    }
}
#chat-modal, #category-modal, #brand-modal, #faq-modal, #manufacturer-modal, #pinCode-modal, #city-modal, #state-modal {
    small.msg.text-danger {
        display: block;
        display: none
    }
    label {
        &:not(.switch) {
            position: absolute;
            top: -10px;
            background-color: $Cream;
            font-size: 11px;
            line-height: 17px;
            padding: 0 7px;
            border: 1px solid $AshGray;
            border-radius: 5px;
            left: 0
        }
    }
    .form-group {
        position: relative;
        margin-bottom: 40px
    }
    .manufacturer-modal-header, .chat-modal-header, .category-modal-header, .brand-modal-header {
        padding: 20px
    }
    .heading {
        margin-bottom: 20px
    }
    .modal-header {
        border-bottom: none
    }
}
.icon-check{
    font-size: 22px !important;
    vertical-align: sub;
    margin-right: 10px
}
.isFeatured {
    user-select: none
}
.complaint-modal-header {
    max-height: 600px;
    overflow-y: scroll;
    .heading{
        font-size: 20px
    }
}
.form-group.box {
    padding: 20px;
    margin-bottom: 20px !important;
    label {
        margin-bottom: 10px
    }
}
.check-all{
    min-width: 60px !important;
    i {
        vertical-align: middle;
        font-size: 22px !important;
        cursor: pointer
    }
}
.datePicker-filter {
    margin-left: 10px
}
.date-filter {
    span{
        display: inline-block
    }
}
input.datePicker-filter.react-datepicker-ignore-onclickoutside {
    background-color: $White
}
#product-status-modal{
    .track {
        position: relative;
        background-color: $BorderColor;
        height: 7px;
        display: flex;
        margin-bottom: 60px;
        margin-top: 50px;
        .step {
            flex-grow: 1;
            width: 25%;
            margin-top: -18px;
            text-align: center;
            position: relative;
            &.active {
                &::before {
                    background: $Primary
                }
                .icon {
                    background: $Primary;
                    color: $White
                }
                .text {
                    font-weight: 400;
                    color: $Dark
                }
            }
            &::before {
                height: 7px;
                position: absolute;
                content: '';
                width: 100%;
                left: 0;
                top: 18px
            }
        }
        .text {
            display: block;
            margin-top: 7px
        }
        .icon {
            display: inline-block;
            width: 40px;
            position: relative;
            border-radius: 100%;
            background: $BorderColor;
            i {
                line-height: 40px;
                font-size: 16px !important
            }
        }
    }
    .track-form {
        position: relative;
        margin-top: 50px;
        padding: 20px;
        text-align: center;
        input {
            max-width: 300px;
            width: 100%;
            display: block;
            margin: auto
        }
        .inputBoxTrackingOrder {
            margin-bottom: 20px
        }
    }
}
.column {
    flex-direction: column !important
}
label.filter-label-add {
    position: absolute;
    top: -22px;
    left: 2px;
    z-index: 9;
}
input {
    &[name="searchKey"] {
        min-width: 200px
    }
}
h4.head {
    small {
        font-size: 12px;
        color: $LightGray
    }
}
#df-btn-cont {
    display: none
}
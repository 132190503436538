@import '../../../../assets/scss/vars';
.franchise-cta-section {
    position: relative;
    padding: 60px 0;
    .jumbo-container {
        .franchise-cta-content {
            @media (min-width: 992px) {
                max-width: 520px;
                margin-left: 40px;
            }
            @media (max-width: 991px) {
                margin-top: 20px;
            }
        }
        p b {
            font-size: 16px;
        }
        .logo-block {
            max-width: 180px;
            display: flex;
            span {
                display: block;
                margin: 15px 0 0 10px;
                text-transform: uppercase;
                font-size: 20px;
                font-family: "Montserrat", sans-serif;
                font-style: italic;
                font-weight: 600
            }
        }
    }
    .franchise-cta-slider {
        @media (min-width: 992px) {
            display: flex;
        }
        .franchise-cta-tiles {
            img {
                cursor: pointer
            }
        }
        ul {
            @media (max-width: 991px) {
                display: flex;
            }
            li {
                margin-right: 20px;
                overflow: hidden;
                border-radius: 8px;
                margin-bottom: 20px;
                width: 100px;
                position: relative;
                span {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,0.75);
                    color: $White;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    font-size: 16px
                }
            }
        }
        .franchise-cta-frame {
            max-width: 460px;
            border-radius: 12px;
            overflow: hidden;
            position: relative;
            margin-bottom: auto;
            span {
                position: absolute;
                right: 0;
                top: 0;
                background-color: $Dark;
                color: $White;
                padding: 10px 20px;
            }
        }
    }
}
$Yellow: #ec9e28;
$LightGray: #d4d4d4;
$AshGray: #aeaeae;
$Black: #000;
$Gray: #333;
$DarkGray: #777;
$Dark: #181818;
$OffWhite: #f9f9f9;
$Cream: #f9f9f9;
$BackGray: #f4f4f4;
$White: #fff;
$Cyan: #2cb5af;
$GreenSea: #16a085;
$Turcoise: #1abc9c;
$Mischka: #CCD3DC;
$DarkWhite: #e4e3e3;
$LightPink: #fff2e2;
$Orange: #f15b29;

$WhatsappColor: #25d366;
$FbColor: #4867aa;
$Progress: #a55eea;
$GoogleColor: #d34d41;
$LinkedinColor: #0a66c2;

$OverlayColor: #0000008c;
$BorderColor: #CAD1DC;
$ShadowColor: #cccccc;
$DisabledColor: #aaa;

$Success: #007600;
$Error: #ea4335;
$Info: #00a6ff;
$Warning: $Yellow;

$Primary: #f15b29;
$PrimaryLight: rgba(241, 91, 41, 0.5);

$ImageBase: "https://cdn.programmerindia.org/upload/";

$SidebarColor: $Dark;
$HeaderColor: $White;
$FooterColor: $White;
$TextColor: $Dark;
$NavTextColor: $Black;

$generic_font: "Montserrat", sans-serif;
$heading_font: "Montserrat", sans-serif;

$gameBoxSize: 750px
%regular_font {
    font-family: $generic_font;
    font-weight: 400;
    color: $TextColor;
    font-size: 14px
}
%absoluteMobileFill {
    @media (max-width: 768px) {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
    }
}
%absoluteFill {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
}
%input {
    width: 100%;
    height: 40px;
    border: 0;
    line-height: 20px;
    background-color: $Cream;
    font-family: $generic_font;
    font-weight: 400;
    color: $Dark;
    border-radius: 8px;
    font-size: 14px;
    padding: 0 20px;
    box-shadow: 1px 2px $BorderColor;
    &:focus {
        outline: none
    }
    &[disabled], &[readonly] {
        background-color: $BorderColor;
        color: $DarkGray !important
    }
    &[type="range"] {
        width: 100%;
        height: 7px;
        background: $BorderColor;
        outline: none;
        padding: 0;
        transition: opacity .2s;
        &::-webkit-slider-thumb {
            appearance: none;
            width: 20px;
            height: 40px;
            border-radius: 8px;
            background: $Primary;
            cursor: pointer
        }
    }
}
%slowTransition {
    transition: all 0.3s ease-in-out;
}
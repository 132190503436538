.switch-box {
    justify-content: center;
    .switch {
        margin-left: 10px !important;
        margin-top: 3px !important
    }
    span {
        position: relative;
        .info-pop {
            position: absolute;
            background-color: $White;
            left: 100%;
            white-space: nowrap;
            padding: 1px 10px;
            border-radius: 4px;
            display: none;
            z-index: 999;
            box-shadow: 0 1px 2px $ShadowColor
        }
        i {
            cursor: pointer;
            color: $AshGray;
            &:hover {
                ~.info-pop {
                    display: block
                }
            }
        }
    }
}
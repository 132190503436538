table {
    border-collapse: collapse;
    color: $Gray;
    border: 1px solid $BorderColor;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    
    tr {
        &:last-child {
            background-color: transparent
        }
        &:nth-child(2n+1) {
            background: $White
        }
        &:nth-child(odd) {
            background: $OffWhite
        }
    }
    th, td {
        border: 1px solid $BorderColor;
        font-weight: normal;
        background-color: $White;
        vertical-align: middle;
        line-height: 1.5em;
        padding: 0 15px
    }
    td {
        &.actions {
            padding: 15px
        }
        &.product-remove span {
            display: block;
            background-color: $Error;
            color: $White;
            text-align: center;
            width: 20px;
            height: 20px;
            border-radius: 8px;
            font-size: 16px;
            cursor: pointer;
            margin-right: -16px
        }
    }
    th {
        background-color: $OffWhite;
        padding: 7px 15px !important;
        font-weight: bold;
        white-space: nowrap
    }
    .product-thumbnail {
        min-width: 32px;
        img {
            width: 80px;
            margin: 10px 0;
            box-shadow: none;
            height: auto;
            max-width: 100%
        }
    }
    .product-quantity {
        .quantity {
            .screen-reader-text {
                clip: rect(1px, 1px, 1px, 1px);
                height: 1px;
                overflow: hidden;
                position: absolute !important;
                width: 1px;
                word-wrap: normal !important;
                color: $Dark
            }
            .minus, .plus {
                width: 40px;
                border: 1px solid $BorderColor;
                background: transparent;
                cursor: pointer;
                margin: 0;
                vertical-align: middle;
                line-height: 1;
                padding: 14px
            }
            .qty {
                width: 30px;
                height: 18px;
                appearance: textfield;
                -moz-appearance: textfield;
                -webkit-appearance: textfield;
                background: transparent;
                border-top: 1px solid $BorderColor !important;
                border-bottom: 1px solid $BorderColor !important;
                box-sizing: content-box;
                -moz-box-sizing: content-box;
                -webkit-box-sizing: content-box;
                border: none;
                padding: 13px 13px 10px;
                line-height: normal;
                text-align: center;
                vertical-align: middle
            }
        }
    }
    td.actions {
        .coupon {
            float: left;
            label {
                display: none
            }
            .input-text {
                background: #F0F0F0;
                margin-right: 10px;
                line-height: 40px;
                padding: 0 20px;
                width: 80px;
                box-sizing: content-box;
                -moz-box-sizing: content-box;
                -webkit-box-sizing: content-box;
                border: none;
                float: left
            }
            input.button {
                position: relative;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
                line-height: normal;
                letter-spacing: 1px;
                background: transparent;
                color: $Primary;
                border: 1px solid $BorderColor;
                padding: 18px 24px 19px;
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                border-radius: 0;
                -moz-border-radius: 0;
                -webkit-border-radius: 0
            }
        }
        .updatebtn {
            cursor: pointer;
            opacity: 1;
            border-color: #F0F0F0;
            background-color: #F0F0F0;
            color: $BorderColor;
            line-height: 1;
            position: relative;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            line-height: normal;
            letter-spacing: 1px;
            background: transparent;
            color: $Primary;
            border: 1px solid $BorderColor;
            padding: 18px 24px 19px;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            border-radius: 0;
            -moz-border-radius: 0;
            -webkit-border-radius: 0
        }
    }
    &#list-wrapper {
        img {
            max-width: 60px !important
        }
    }
}
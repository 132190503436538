@import '../../../../assets/scss/vars';
.sub-header-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    .sub-banner {
        padding: 250px 0 150px;
        &.small {
            padding: 200px 0 100px;
        }
        @media (max-width: 768px) {
            padding: 140px 0 40px
        }
        .sub-header-content {
            display: flex;
            flex-direction: column;
            .banner-content {
                display: flex;
                flex-direction: column
            }
            h1 {
                word-break: break-word;
                font-size: 64px;
                text-transform: uppercase;
                line-height: normal;
                position: relative;
                @media (max-width: 768px) {
                    font-size: 24px
                }
                &.text-custom-white {
                    color: $White;
                    &::before {
                        background-color: $White
                    }
                }
            }
        }
    }
    &.right .sub-header-content.jumbo-container {
        flex-direction: row-reverse;
        align-items: center;
    }
    .widget {
        margin-right: auto;
        width: 320px;
        .widget-title {
            font-size: 24px;
            color: $White;
        }
    }
}
@import '../../../../assets/scss/vars';
.premium-services-section {
    .premium-box {
        padding: 0 10px;
        .premium-service {
            box-shadow: inset 0 -200px 50px rgba(0,0,0,0.75);
            min-height: 500px;
            background-size: cover;
            background-position: center;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            transition: all 0.3s ease-in-out;
            button {
                display: none;
            }
            h3 {
                position: absolute;
                color: $White;
                font-size: 28px;
                line-height: 1.2;
                left: 20px;
                right: 20px;
                bottom: 20px;
                padding-left: 20px;
                border-left: 2px solid $Primary;
            }
            &:hover {
                transform: scale(1.1);
                button {
                    display: block
                }
            }
        }
    }
}
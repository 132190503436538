@import '../../../../assets/scss/vars';
.order-tracking {
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: $White;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.10rem;
        .card-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            background-color: $White;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:first-child {
                border-radius: calc(0.37rem - 1px) calc(0.37rem - 1px) 0 0
            }
        }
        .track {
            position: relative;
            background-color: $BorderColor;
            height: 7px;
            display: flex;
            margin-bottom: 60px;
            margin-top: 50px;
            .step {
                -ms-flex-positive: 1;
                flex-grow: 1;
                width: 25%;
                margin-top: -18px;
                text-align: center;
                position: relative;
                &.active {
                    &::before {
                        background: $Primary
                    }
                    .icon {
                        background: $Primary;
                        color: $White
                    }
                    .text {
                        font-weight: 400;
                        color: $Black
                    }
                }
                &::before {
                    height: 7px;
                    position: absolute;
                    content: '';
                    width: 100%;
                    left: 0;
                    top: 18px
                }
            }
            .text {
                display: block;
                margin-top: 7px
            }
            .icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                position: relative;
                border-radius: 100%;
                background: $BorderColor;
                i {
                    font-size: 26px !important;
                    padding-top: 7px
                }
            }
        }
        .track-form {
            position: relative;
            margin-top: 50px;
            padding: 20px;
            text-align: center;
            input {
                max-width: 300px;
                width: 100%;
                display: block;
                margin: auto
            }
            .inputBoxTrackingOrder {
                margin-bottom: 20px
            }
        }
    }
    h6 {
        font-size: 1rem;
        font-weight: 400;   
        padding: 0.75rem 1.25rem
    }
}
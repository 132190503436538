@import '../../../../assets/scss/vars';
.for-mobile-search {
    width: 100%;
    max-width: 400px;
    display: block;
    position: relative;
    margin: auto;
    @media (max-width: 991px) {
        max-width: calc(100% - 165px);
        margin: 0 45px 0 0;
    }
    form.search-bar {
        text-align: center;
        background: $HeaderColor;
        z-index: 3;
        position: relative;
        display: flex;
        border: 1px solid $BorderColor;
        border-radius: 8px;
        input[type="text"] {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            box-shadow: none !important;
        }
        button {
            border-left: 0;
            min-width: 40px;
            text-align: center;
            padding: 0 0 0 5px !important;
            height: 40px;
            border-top-Left-radius: 0;
            border-bottom-Left-radius: 0
        }
    }
    .for-mobile-search-result {
        background: $White;
        margin: auto;
        max-height: 400px;
        overflow: auto;
        z-index: 9;
        position: absolute;
        text-align: left;
        li {
            padding: 10px
        }
        @media (max-width: 768px) {
            position: fixed;
            left: 0;
            right: 0;
            box-shadow: 0 2px 2px $ShadowColor;
            top: 60px
        }
    }
}
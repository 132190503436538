@import '../../../../assets/scss/vars';
.sub-footer {
    background-image: url(https://cdn.programmerindia.org/upload/detailing-banner.jpg);
    padding: 40px 0;
    background-size: cover;
    position: relative;
    &::before {
        z-index: 0;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .jumbo-container {
        position: relative;
        z-index: 1;
    }
    .sub-head {
        font-weight: 500;
        margin-right: 10px
    }
    a, .link {
        margin-right: 5px;
        font-size: 12px;
        color: $White !important;
        &:not(:last-child) {
            &::after {
                content: ','
            }
        }
    }
    a {
        &:focus, &:hover {
            color: $Primary !important
        }
    }
}
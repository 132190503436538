@import 'switch';
@import 'section_grid';
@import 'btn';
@import 'grid_box';
@import 'widget_area';

.slick-track {
    display: flex
}
.tox.tox-tinymce {
    border-radius: 20px
}
.react-datepicker__day--selected {
    background-color: $Primary !important
}
.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    text-align: center;
    grid-gap: 20px;
    width: auto
}
iframe.preview-video {
    width: 100%;
    min-height: 440px;
}
ul.opening-hours {
    list-style: none;
    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px
    }
}

.right-side-profile-content {
    .table-wrapper {
        max-height: calc(100vh - 300px);
        #list-wrapper {
            margin-bottom: 0;
            &.has-total {
                tbody tr:last-child td {
                    font-weight: bold !important;
                    vertical-align: middle;
                    border-top: 2px solid $OverlayColor;
                    border-bottom: 2px solid $OverlayColor
                }
            }
        }
    }
}
.select-filter {
    [class$="control"] {
        >div:first-child, [class$="ValueContainer"] {
            min-width: 140px
        }
    }
    ul {
        display: flex;
        li {
            margin-top: 10px;
            margin-left: 20px;
            max-width: 360px;
            input {
                font-size: 12px !important
            }
        }
    }
}
.header-title-box {
    position: relative;
    z-index: 10
}
.order-label {
    font-weight: 600;
    color: $Primary;
    font-size: 11px
}
ul.car-images {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    li {
        width: 100%;
        border: 1px solid $ShadowColor;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center
    }
}
.blocks {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    .block {
        width: 100%;
        border: 1px solid $ShadowColor;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 10px 20px;
        p {
            margin: 0;
            font-size: 32px;
            font-weight: 300;
            line-height: 1.5;
            text-align: center
        }
        h3 {
            margin: 0;
            font-weight: 400;
            font-size: 18px;
            text-align: center
        }
    }
}
.filter-box {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px;
    @media (max-width: 768px) {
        display: block
    }
    input {
        max-width: 110px
    }
    &.flex {
        input {
            min-width: 200px;
            height: 40px !important;
            padding: 0 20px;
            background-color: $Cream !important
        }
    }
    .filters {
        align-self: center;
        width: 200px;
        span, a {
            padding: 0 15px 0 0;
            margin-right: 15px;
            color: $Dark;
            line-height: 1;
            border-right: 1px solid $BorderColor;
            &.active {
                color: $Primary
            }
            &:last-child {
                border: none
            }
        }
        ul.view-tabs {
            display: flex;
            border-radius: 6px;
            overflow: hidden;
            width: 100%;
            li {
                i {
                    cursor: pointer;
                    padding: 9px;
                    display: block;
                    font-size: 20px;
                    background-color: $White
                }
                &.active i {
                    color: $White;
                    background-color: $Primary
                }
            }
        }
        
    }
    input[type="text"] {
        border-radius: 4px !important;
        border: 1px solid $BorderColor !important;
        height: 36px !important;
        background-color: $White !important
    }
    .search-box{
        margin-left: auto;
        display: flex;
        i {
            padding: 8px;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 4px;
            font-weight: 600;
            background: $White
        }
        button {
            height: 34px;
            border: 0
        }
    }
    [class$="control"] {
        background-color: $White !important;
        border-radius: 4px !important;
        border: 1px solid $BorderColor !important;
        >div:first-child, [class$="ValueContainer"] {
            height: 36px;
            padding: 0 10px;    
            min-width: 80px;
            [id$="input"] {
                height: 20px !important;
                background-color: transparent !important
            }
            [class$="singleValue"], [class$="Input"] {
                margin: auto 2px
            }
        }
        input[aria-autocomplete="list"] {
            border: 0 !important
        }
    }
}
.fixed-service {
    padding: 6px 20px;
    border: 1px solid $ShadowColor;
    margin-bottom: -1px;
    width: calc(100% - 40px)
}
.clearfix {
    clear: both;
    min-height: 1px;
    width: 100%;
    &::before, &::after {
        content: '';
        clear: both;
        min-height: 1px;
        width: 100%
    }
}
.shoot-icon {
    background-color: $Primary;
    cursor: pointer;
    width: 40px;
    height: 23px;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 0 3px $ShadowColor
}
.flex-box {
    display: flex;
    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        min-width: 45px;
        height: 23px;
        margin-right: 10px;
        margin-left: 2px;
        input { 
            opacity: 0;
            width: 0;
            height: 0
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $ShadowColor;
        transition: .4s;
        &::before {
            position: absolute;
            content: '';
            height: 15px;
            width: 15px;
            left: 5px;
            bottom: 4px;
            background-color: $White;
            transition: .4s
        }
        &.round {
            border-radius: 13px;
            &::before {
                border-radius: 50%
            }
        }
    }
    input:checked + .slider {
        background-color: $Primary
    }
    input:focus + .slider {
        box-shadow: 0 0 1px $Primary
    }
    input:checked + .slider:before {
        transform: translateX(21px)
    }
}
#get-offer {
    .pricing-table-background {
        padding: 0;
        margin: 0;
        background-color: transparent;
        .pricing-table-grid-box {
            box-shadow: none
        }
    }
}

.anywhere-nav {
    max-height: 50vh;
    .sub-nav {
        padding-left: 20px
    }
    li {
        i {
            margin-bottom: -3px
        }
        a, span {
            padding: 5px
        }
    }
}
[class$="option"] {
    padding: 3px 10px !important
}
[class$="control"] {
    border-radius: 8px !important;
    background-color: $Cream !important;
    border: 1px solid $BorderColor !important;
    max-height: 40px;
    > div {
        max-height: 40px
    }
    [class$="singleValue"] {
        height: 28px
    }
    >div:first-child, [class$="ValueContainer"] {
        max-height: 40px;
        padding: 0 10px;    
        overflow: auto !important;
        [id$="input"] {
            height: 20px !important
        }
        [class$="singleValue"], [class$="Input"] {
            margin: auto 2px
        }
    }
}
[class$="indicatorSeparator"] {
    display: none
}
.featured-product-slider .slick-track {
    display: flex
}
.back-icon i {
    float: left;
    font-size: 28px;
    color: $Primary;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px
}
.control-label {
    min-width: 140px;
    font-size: 12px;
    margin-right: 20px;
    position: relative;
    &::after {
        content: ':';
        position: absolute;
        right: 0
    }
}
.offer-grid {
    grid-gap: 40px;
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
}
.form-box.form-select-box {
    padding: 10px 20px 2px !important;
    max-width: 100% !important;
    .service-single-item .service-content {
        width: 100%;
        display: flex;
        i {
            font-size: 28px;
            margin-top: 10px;
            cursor: pointer
        }
        .item-number {
            font-size: 40px;
            line-height: 48px;
            margin-left: auto
        }
    }
}
.row {
    display: flex;
    margin: 0 -20px;
    .col {
        flex: 1;
        padding: 0 20px
    }
}
.blog-details-inner, .content-detail {
    ul {
        list-style: disc;
        padding-left: 20px;
        li {
            font-size: 14px;
            padding-left: 10px;
            margin: 0 0 10px
        }
    }
    ol {
        padding-left: 20px;
        li {
            font-size: 14px;
            padding-left: 10px;
            margin: 0 0 5px
        }
    }
    p {
        font-size: 14px;
        margin: 0 0 20px
    }
    table {
        border: 1px solid $BorderColor;
        border-collapse: collapse;
        td, th {
            padding: 5px 15px;
            border: 1px solid $BorderColor;
            min-width: 240px
        }
        th {
            font-weight: 500
        }
    }
}
.btn-block {
    width: 100%
}
li.template-bullet {
    list-style: decimal;
    padding-left: 20px
}
.empty-cart {
    text-align: center;
    img {
        margin: auto
    }
}
h2.heading-02 {
    font-size: 35px;
    position: relative;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 24px
    }
}
.slick-slide > div, .slick-slide {
    min-width: 240px !important
}
.slick-track {
    display: flex !important
}
.work-times-inner {
    display: flex;
    flex-direction: column;
    img {
        max-width: 200px;
        padding: 10px;
        margin: 30px auto 0;
        border: 2px dashed $White;
        transition: all 0.3s ease-in-out
    }
    &:hover {
        .image {
            background: $Dark
        }
    }
}
p {
    &.input {
        padding-top: 10px !important;
        cursor: not-allowed;
        user-select: none;
        background-color: $BorderColor !important
    }
    &.price {
        font-size: 16px;
        color: #65606d
    }
}
table.a-lineitem {
    font-size: 16px;
    border: 0;
    tr {
        td {
            border: 0;
            padding: 0;
            background-color: transparent;
            margin-bottom: 10px;
            display: inline-block;
            min-width: 100px
        }
    }
}
button.slick-arrow.slick-next {
    right: 20px;
    transform: rotate(45deg);
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: transparent !important;
    border-top: 2px solid $Primary;
    border-right: 2px solid $Primary;
    @media (max-width: 768px) {
        width: 20px;
        height: 20px
    }
}
button.slick-arrow.slick-next:before {
    display: none
}
button.slick-arrow.slick-prev {
    left: 20px;
    z-index: 10;
    transform: rotate(45deg);
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: transparent !important;
    border-bottom: 2px solid $Primary;
    border-left: 2px solid $Primary;
    @media (max-width: 768px) {
        width: 20px;
        height: 20px
    }
}
button.slick-arrow.slick-prev:before {
    display: none
}
.banner {
    .slick-dots {
        top: calc(50% - 150px) !important;
        left: auto !important;
        height: 300px !important;
        right: 0;
        width: 48px !important;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column;
        align-items: center;
        text-align: center !important;
        z-index: 999;
        list-style: none !important;
        li {
            margin: 20px 0 !important;
            button {
                background-color: $Primary !important;
                border-radius: 20px;
                border: 0 !important;
                width: 40px !important;
                height: 40px !important;
                color: $White !important;
                font-size: 16px !important;
                &::before {
                    display: none !important
                }
            }
        }
    }
}
.common-rating-style {
    i {
        color: $AshGray;
        cursor: pointer
    }
}
.slick-list {
    max-width: 100vw
}
.slick-slider {
    max-width: 100%;
    width: 100%
}
.custom-select-box {
    &>div {
        width: 100%
    }
}
.addlocationbtn {
    max-width: 160px;
    margin-left: auto
}
.upload-box {
    border: 3px dashed $BorderColor;
    padding: 15px;
    background-color: $Cream;
    margin-bottom: 20px;
    border-radius: 8px
}
.modal {
    .upload-box {
        .media-box {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-gap: 20px
        }
    }
}
.form-inline {
    display: flex;
    .input {
        max-width: calc(35% - 40px);
        border: 1px solid $BorderColor !important;
        height: 40px !important;
        border-radius: 0 !important;
        margin-right: 20px
    }
    .btn-icon {
        background-color: $Primary;
        color: $White;
        border: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
        i {
            font-size: 27px;
            padding: 7px;
            line-height: 1.5
        }
    }
}
.selected-car-type {
    height: 40px;
    cursor: pointer;
    display: flex;
    width: calc(32% - 40px);
    margin-right: 20px;
    border: 1px solid $BorderColor;
    img {
        height: 32px;
        width: 58px;
        display: block;
        margin: auto 0;
        cursor: pointer
    }
    p {
        padding-left: 10px;
        font-size: 14px;
        margin: auto 0
    }
}
.adjusted-check {
    margin-top: 6px;
    float: left;
    margin-right: 10px;
    cursor: pointer
}
.selected-society-type {
    height: 40px;
    cursor: pointer;
    display: flex;
    margin-right: 20px;
    border: 1px solid $BorderColor;
    img {
        height: 32px;
        width: 58px;
        display: block;
        margin: auto 0;
        cursor: pointer
    }
    p {
        padding-left: 10px;
        font-size: 14px;
        margin: auto 0
    }
}
.card {
    padding: 20px;
    box-shadow: 0 0 5px $ShadowColor;
    margin-bottom: 40px;
    border-radius: 12px;
}
.scroll-box {
    width: 100%;
    max-width: 200px;
    margin-right: 20px;
    border: 1px solid $BorderColor;
    position: absolute;
    top: 50px;
    z-index: 99;
    max-height: 200px;
    overflow: auto;
    background: $White;
    li {
        min-height: 40px;
        cursor: pointer;
        display: flex;
        border-bottom: 1px solid $BorderColor;
        width: 100%;
        img {
            height: 32px;
            width: 58px;
            display: block;
            margin: auto 0;
            cursor: pointer
        }
        p {
            padding-left: 10px;
            font-size: 14px;
            margin: auto 0
        }
        &.active {
            background-color: $Primary;
            p {
                color: $White
            }
        }
    }
    &.full {
        width: auto;
        top: 50px;
        li p {
            padding: 0 15px
        }
    }
}
.email-subscribe-modal-body {
    display: flex
}
.prices-list {
    background-color: $BorderColor;
    margin: 20px 0;
    padding: 4px 0 1px;
    li {
        display: flex;
        background-color: $White;
        margin: 1px 5px 4px 5px;
        p {
            margin: auto 0;
            padding: 0 0 0 10px;
            &.service-price, &.car-name {
                width: calc(50% - 40px - 29px)
            }
        }
        img {
            height: 32px;
            width: 58px;
            display: block;
            margin: 4px 0
        }
        i {
            cursor: pointer;
            color: $Error;
            font-size: 20px;
            padding: 10px;
            margin-left: auto
        }
    }
}
.back-text {
    position: absolute;
    font-size: 12vw;
    font-weight: bold;
    color: $DarkWhite;
    bottom: -20vw;
    z-index: -1;
    right: -12vw
}
p.active-options {
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
        color: $AshGray;
        margin-right: 8px;
        &[className="hi-check_circle"] {
            color: $Success
        }
    }
}
.react-datepicker__month-container, .react-datepicker__year--container, .react-datepicker__input-container {
    background-color: $White;
    box-shadow: 0 1px 2px $ShadowColor
}
.react-datepicker__header.react-datepicker-year-header {
    padding: 0
}

li.my-packages-assign-car {
    display: flex;
    img {
        width: 80px;
        height: 50px;
        object-fit: contain
    }
    span {
        align-self: center;
        margin-left: 10px
    }
}
.autocomplete-dropdown-container {
    position: absolute;
    max-height: 150px;
    z-index: 999;
    left: 20px;
    right: 20px;
    overflow: auto;
    box-shadow: 0 0 5px $ShadowColor;
    .suggestion-item {
        line-height: 24px;
        background-color: $White;
        padding: 10px 20px !important;
        border-bottom: 1px solid $BorderColor;
        &:hover, &--active {
            padding: 10px 20px !important;
            background-color: $Cream !important
        }
    }
}
#recommended-service {
    background-color: transparent !important;
    box-shadow: none !important
}
.mobile-order {
    display: none
}
#get-subscription-modal {
    h1.plan-heading {
        text-align: center
    }
}
.import-table {
    tr th, tr td {
        max-width: 300px;
        width: 100%
    }
}
.btn-filter-done {
    position: absolute;
    right: 15px;
    margin-top: -3px;
    z-index: 999;
    @media (min-width: 769px) {
        display: none !important
    }
}
.back-light {
    background-color: $BackGray
}
a.choose-package {
    display: block;
    width: 40%;
    background-image: url('../images/package-banner.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    @media (max-width: 991px) {
        height: 70vw;
        width: 100%
    }
}
.export-btn {
    overflow: hidden;
    color: $White !important;
    line-height: 32px !important;
    &:focus, &:hover {
        color: $White;
        line-height: 32px !important
    }
    button {
        background: $Primary;
        border: 0;
        color: $White;
        line-height: 34px;
        display: block;
        margin: -2px -22px;
        padding: 0 20px;
        cursor: pointer
    }
}
.mail-design {
    max-width: 1440px;
    margin: auto
}
.select-subcategory-box {
    padding-left: 20px;
    font-size: 12px
}
.pick-and-drop {
    cursor: pointer;
    display: inline-block;
    user-select: none;
    i {
        font-size: 20px;
        vertical-align: sub;
        margin-right: 10px
    }
}
.tagger {
    text-transform: capitalize
}
.select-date-mypackages {
    .react-datepicker-wrapper {
        margin: 0 0 10px 0;
        input {
            width: 100%;
            height: 40px;
            border: 0;
            line-height: 20px;
            background-color: $Cream;
            font-family: $generic_font;
            font-weight: 400;
            color: $Dark;
            border-radius: 8px;
            font-size: 14px;
            padding: 0 20px;
            box-shadow: 1px 2px $BorderColor;
            margin-bottom: 10px
        }
    } 
}
.product-trust {
    padding: 20px 0; 
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1.25rem;
    @media (max-width: 768px) {
        grid-template-columns: 1fr
    }
    .product-trust-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        i {
            background-color: $Primary;
            border-radius: 26px;
            padding: 10px;
            font-size: 24px;
            color: $White
        }
        span {
            font-size: 16px;
            text-align: left;
            color: $Dark;
            margin-left: 16px
        }
    }
}
.about_us_widget {
    > .flex {
        overflow: visible
    }
}
.addtocart-btn-box {
    width: 100%;
    padding-left: 132px;
    margin: 20px auto;
    justify-content: space-between;
    button {
        flex: 1 1;
        width: 100%;
        max-width: calc(50% - 20px) !important
    }
    @media (max-width: 991px) {
        padding-left: 0
    }
}
.subService-name {
    font-size: 10px
}
.filters-heading {
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;
    font-weight: 500;
    padding-bottom: 20px;
    position: relative;
    border-bottom: 0 !important;
    color: $Dark;
    border-bottom: 1px solid $ShadowColor !important;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        padding-bottom: 10px;
        color: $White
    }
} 
.mobile-cart-header-top {
    display: none;
    @media (max-width: 768px) {
        display: block
    }
}
li.navbarMobile-cart {
    font-size: 32px;
    display: block;
    position: absolute;
    right: 60px;
    cursor: pointer;
    color: $White;
    margin: 0 20px;
    display: block;
    span {
        padding: 7px 0;
        font-size: 16px;
        margin: 0 25px;
        text-decoration: none;
        color: $White;
        margin: 0 10px
    }
    i {
        font-size: 25px;
        margin-top: 8px;
        cursor: pointer
    }
    p.cartCount {
        position: absolute;
        height: 26px;
        top: 7px;
        right: -12px
    }
}
.shop-table {
    td {
        &:last-child {
            p.status-box {
                position: absolute;
                right: 10px;
                bottom: 0;
                background-color: $Primary;
                color: $White;
                font-size: 12px;
                border-radius: 8px;
                padding: 5px 10px
            }
        }
    }
}
a.whats-app {
    i {
        background-color: $WhatsappColor;
        color: $White;
        display: block;
        font-size: 32px;
        border-radius: 50%;
        padding: 9px
    }
}
@media ( max-width: 600px ) {
    .form-inline {
        >button, >.input, >span {
            flex: 1;
            width: 100%;
            max-width: 100%;
            margin: auto 0 10px auto
        }
    }
}
.main-product-detail {
    .content-detail {
        max-width: 100vw;
        overflow-x: auto;
        padding: 0 20px 40px;
        @media (max-width:768px) {
            table tr {
                display: flex;
                flex-direction: column
            }
        }
    }
}
.mrp ~ .sale-price {
    text-decoration: line-through;
}
.service-box-new .black small {
    font-size: 10px;
    color: $DarkGray
}
#youtube-video-home-modal .modal-body, #youtube-video-modal .modal-body {
    height: 70vh !important
}
.close-top-ads-box {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999
}
.key-features {
    box-shadow: 0 0.25rem 0.3125rem -0.15rem #0000001f;
    padding: 17px !important;
    border-radius: 4px;
    border: 1px solid #00000029;
    overflow: hidden;
    margin-bottom: 60px !important;
    .items-box {
        display: flex;
        justify-content: space-around;
        @media (max-width: 768px) {
            flex-direction: column
        }
        .items {
            display: flex;
            border-right: 1px solid $Primary;
            flex: 1;
            align-items: center;
            justify-content: center;
            @media (max-width: 768px) {
                justify-content: left;
                border: none;
                margin-bottom: 10px;
                border-bottom: 1px solid $Primary
            }
            &:last-child {
                border-right: none
            }
            img {
                height: 40px;
                align-items: center;
                justify-content: center;
                width: 40px;
                margin-right: 10px
            }
            .text-part {
                p {
                    margin: 0
                }
            }
        }
    }
}
.input-img {
    max-height: 240px;
    box-shadow: 0 0 5px $ShadowColor;
    padding: 10px;
    border-radius: 2px
}
.package-price {
    display: block;
    text-align: center;
    font-size: 24px;
    color: $Primary
}
.top-nav-bar-banner {
    background-color: $White;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    @media (max-width: 768px) {
        background-image: none
    }
    .main-box {
        display: flex;
        align-self: center;
        height: 100%;
        background-position: calc(100% - 40px) 0px;
        background-size: 300px;
        background-repeat: no-repeat;
        .text-banner {
            display: flex;
            align-content: center;
            margin: auto 0;
            .right-content {
                margin-left: 20px;
                p {
                    margin-bottom: 0;
                    font-size: 26px;
                    line-height: 1.5;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
                label {
                    color: $AshGray;
                    font-size: 12px;
                    line-height: 12px;
                    display: block
                }
            }
            h1{
                color: $Error;
                font-size: 46px;
                line-height: 46px;
                @media (max-width: 768px) {
                    font-size: 26px
                }
            }
           
        }
        .shop-now-btn {
            margin: auto 0px auto 40px;
            @media (max-width: 768px) {
                margin: auto 0px auto 10px
            }
        }
    }
}
.form-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .form-group {
        flex: 1;
        max-width: calc(50% - 15px)
    }
}

i.close-top-ads {
    position: absolute;
    right: 8px;
    color: $Error;
    font-size: 23px !important;
    top: 5px;
    cursor: pointer;
    z-index: 99
}
.filter-box [class$=-container] {
    min-width: 160px
}
.featured-group a {
    display: block;
    margin: 30px 10px 0;
    border: 1px solid $Primary;
    img {
        display: block
    }
}
.featured-group-sidebar {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    .service-item {
        overflow: hidden;
        box-shadow: 0 0 5px $ShadowColor;
        border-radius: 12px;
        a {
            position: relative;
            display: block;
            img {
                min-height: 220px;
                max-height: 220px;
                width: 100%;
            }
        }
        .clipped {
            max-height: 64px;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }
}
.action-bar {
    display: flex;
    .action {
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        color: $Dark;
        &:focus, &:hover {
            color: $Primary
        }
        i {
            font-size: 14px;
            background-color: $Primary;
            color: $White;
            display: block;
            padding: 5px;
            border-radius: 4px
        }
    }
}
.search-result-storeLocator ul {
    list-style: unset;
    position: absolute;
    background-color: $White;
    z-index: 1;
    width: 100%;
    box-shadow: 0 0 3px $ShadowColor;
    .storeLocator-product-search-box:hover {
        background-color: $Cream
    }
}
.thumb-area-wrap {
    width: 100%
}
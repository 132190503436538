.widget-area {
    .widget {
        background-color: $Cream;
        margin-bottom: 30px;
        padding: 25px 30px 30px 30px;
        .search-form {
            position: relative;
            .form-group {
                margin-bottom: 0 !important
            }
            .form-control {
                width: 100%;
                height: 50px;
                padding: 0 70px 0 15px;
                display: block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: $White !important;
                background-clip: padding-box;
                border: 1px solid #ced4da !important;
                border-radius: .25rem !important;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
            }
            .submit-btn {
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                height: 40px;
                line-height: 40px;
                padding: 0;
                font-size: 18px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: $Primary;
                color: $White;
                transition: .3s ease-in;
                border: none;
                cursor: pointer
            }
        }
        .widget-title {
            font-size: 22px;
            font-weight: 700;
            line-height: 31px;
            margin-bottom: 20px;
            position: relative;
            color: $Dark;
            &::after {
                content: '';
                display: block;
                border-bottom: 1px solid $Dark;
                width: 60%;
                margin-top: 10px
            }
        }
        .recent-post-item {
            margin: 0;
            padding: 0;
            list-style: none;
            max-height: 500px;
            overflow-y: auto;
            .single-recent-post-item {
                &:first-child {
                    margin-top: 0;
                    padding-top: 0;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid $BorderColor;
                    padding-bottom: 20px
                }
                .thumb {
                    align-items: center;
                    margin-right: 15px;
                    display: flex
                }
                .content {
                    align-self: center;
                    flex: 1;
                    height: 100%;
                    display: grid;
                    .title {
                        color: $Dark;
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 500;
                        text-align: left;
                        margin: 0 0 8px 0
                    }
                    .time {
                        font-size: 14px;
                        font-weight: 500;
                        color: $Dark
                    }
                }
            }
        }
    }
    .widget_nav_menu {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 15px 0;
                &:first-child {
                    margin-top: 0
                }
                a {
                    border: none;
                    position: relative;
                    padding-left: 20px;
                    font-size: 14px;
                    line-height: 24px;
                    color: $Dark;
                    font-weight: 400;
                    &::before {
                        width: 8px;
                        height: 8px;
                        background-color: $Dark;
                        transform: translateY(-50%);
                        left: 0;
                        content: '';
                        display: inline-block;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transition: all 0.3s ease-in
                    }
                    &::after {
                        width: 12px;
                        height: 12px;
                        border: 1px solid $Dark;
                        top: 50%;
                        left: 4px;
                        transform: translate(-50%, -50%);
                        content: '';
                        display: inline-block;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transition: all 0.3s ease-in
                    }
                }
            }
        }
    }
}
@import '../../../../assets/scss/vars';
.testimonial {
    position: relative;
    margin: auto;
    .testimonial {
        display: flex !important;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        img {
            width: 100%;
            height: 100%;
            max-width: 240px !important;
            border-radius: 50%;
            @media (min-width: 992px) {
                margin: 0 60px;
            }
        }
        h3 {
            font-size: 24px;
            margin: 20px 0 10px 0;
        }
        p {
            font-size: 14px;
            color: $AshGray;
            &::before, &::after {
                content: '"';
            }
        }
        h5 {
            color: $Primary;
            font-size: 16px;
        }
    }
}
